import { put, call, takeLatest, takeEvery, debounce, fork, take, cancel } from 'redux-saga/effects';
import { loginSuccess, loginFailure } from '../store/authSlice';
import axios from 'axios';
import { turnOffLoading, turnOnLoading } from '../store/graphSlice';
import socketSaga from './socketSaga';

function* loginSaga(action) {
  try {
    yield put(turnOnLoading());
    const response = yield call(axios.post, `${process.env.REACT_APP_API_URL}login`, action.payload);
    const token = response.data.access_token;

    // Store the token in localStorage
    localStorage.setItem('token', token);

    yield put(loginSuccess(token));
  } catch (error) {
    yield put(turnOffLoading());
    yield put(loginFailure(error.response?.data?.message || 'Login failed'));
  }
}

function* logoutSaga() {
  yield put(turnOnLoading());
  localStorage.removeItem('token');
  yield put(turnOffLoading());
}

function* watchLoginFlow() {
  // Start the loginSaga on every 'REQUEST_LOGIN' action
  yield takeLatest('REQUEST_LOGIN', loginSaga);

  // Listen for 'LOGIN_SUCCESS' to trigger additional actions and stop further listening
  while (true) {
    const loginTask = yield takeLatest('REQUEST_LOGIN', loginSaga);

    // Wait for either LOGIN_SUCCESS or LOGIN_FAILURE
    const action = yield take(['LOGIN_SUCCESS', 'LOGIN_FAILURE']);

    // If login was successful, start socketSaga
    if (action.type === 'LOGIN_SUCCESS') {
      const socketTask = yield fork(socketSaga);

      // Optional: Cancel socketTask if needed on a specific action or at some condition
      // yield takeLatest('LOGOUT', logoutSaga);
      yield take('LOGOUT'); 
      yield cancel(socketTask);
    }

    // Cancel the login task to turn off further listening after success or failure
    yield cancel(loginTask);
  }
}

export default function* authSaga() {
  yield watchLoginFlow();
}
