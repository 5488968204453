import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requestLogin } from '../store/authSlice';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Отримуємо помилку та статус авторизації з Redux
  const authError = useSelector((state) => state.auth?.error); // Перевіряємо, чи є 'auth'
  const isAuthenticated = useSelector((state) => state.auth?.isAuthenticated);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(requestLogin({ email, password }))
  };

  // Якщо користувач автентифікований, перенаправляємо його на головну сторінку
  if (isAuthenticated) {
    navigate('/');
  }


  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={onSubmit}>
          <div className="input-group">
            <label>Email</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required
            />
          </div>
          <p className={`error-text  ${authError ? '' : 'hiding-element'}`}>{authError ? authError : ''}</p>
          <button type="submit" className="login-button">Login</button>
        </form>
        <p className="register-text">
          <span onClick={() => navigate('/registration')}>Registration</span>
        </p>
      </div>
    </div>
  );
};

export default Login;
