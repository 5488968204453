
import * as THREE from 'three';
import { gsap } from 'gsap';
import { Text } from 'troika-three-text';
import { v4 as uuidv4 } from 'uuid';
import { 
    GLTF_LOADER,
    TEXTURE_LOADER,
    STATUS_OPTIONS,
    STATUS_COLORS,
    LINK_STATUS_COLORS,
    CARD_PROPERTIES,
    RC_CARD_SETTINGS,
    RC_CARD_SETTINGS_TRASH,
    RC_LINK_SETTINGS,
    RC_LINK_SETTINGS_SCISSORS,
    RC_CARD_VOLUME,
    RC_CARD_STATUS,
    RC_CARD_SUBTASK_BUTTON,
    RC_CARD_CORE,
    RC_CARD_CONNECTIONS_SUPER,
    RC_CARD_CONNECTIONS_SUB,
    RC_LINK_SETTINGS_CHANGE_TYPE_BUTTON,
    RC_LINK_SETTINGS_REVERSE_BUTTON,
    RC_LINK_SETTINGS_NEW_NODE_BUTTON,
    RC_CARD_SETTINGS_SHARE,
    RC_CARD_SETTINGS_COLLAPSE,
    RC_CARD_PIN,
} from 'utils/Constants'
import {
    turnOffLoading,
    turnOnLoading,
    addNodeRequest,
    updateLinkRequest,
    deleteRequest,
    updateNodesRequest,
    addLinkRequest
} from 'store/graphSlice';

const [sNoiseVert, sNoiseFrag] = await Promise.all([
    fetch('/shaders/sNoise.vert').then(res => res.text()),
    fetch('/shaders/sNoise.frag').then(res => res.text())
]);
export const SNOISE_MATERIAL = new THREE.ShaderMaterial({ vertexShader: sNoiseVert, fragmentShader: sNoiseFrag, vertexColors: true, transparent: true, wireframe: true, side: THREE.DoubleSide,});

 const cardShape = new THREE.Shape()
    .moveTo(CARD_PROPERTIES.x, CARD_PROPERTIES.y + CARD_PROPERTIES.borderRadius)
    .lineTo(CARD_PROPERTIES.x, CARD_PROPERTIES.y + CARD_PROPERTIES.height - CARD_PROPERTIES.borderRadius)
    .quadraticCurveTo(CARD_PROPERTIES.x, CARD_PROPERTIES.y + CARD_PROPERTIES.height, CARD_PROPERTIES.x + CARD_PROPERTIES.borderRadius, CARD_PROPERTIES.y + CARD_PROPERTIES.height)
    .lineTo(CARD_PROPERTIES.x + CARD_PROPERTIES.width - CARD_PROPERTIES.borderRadius, CARD_PROPERTIES.y + CARD_PROPERTIES.height)
    .quadraticCurveTo(CARD_PROPERTIES.x + CARD_PROPERTIES.width, CARD_PROPERTIES.y + CARD_PROPERTIES.height, CARD_PROPERTIES.x + CARD_PROPERTIES.width, CARD_PROPERTIES.y + CARD_PROPERTIES.height - CARD_PROPERTIES.borderRadius)
    .lineTo(CARD_PROPERTIES.x + CARD_PROPERTIES.width, CARD_PROPERTIES.y + CARD_PROPERTIES.borderRadius)
    .quadraticCurveTo(CARD_PROPERTIES.x + CARD_PROPERTIES.width, CARD_PROPERTIES.y, CARD_PROPERTIES.x + CARD_PROPERTIES.width - CARD_PROPERTIES.borderRadius, CARD_PROPERTIES.y)
    .lineTo(CARD_PROPERTIES.x + CARD_PROPERTIES.borderRadius, CARD_PROPERTIES.y)
    .quadraticCurveTo(CARD_PROPERTIES.x, CARD_PROPERTIES.y, CARD_PROPERTIES.x, CARD_PROPERTIES.y + CARD_PROPERTIES.borderRadius);
    cardShape.holes.push(new THREE.Path().absarc(0, 0, CARD_PROPERTIES.holeRadius, 0, Math.PI * 2, false));
export const CARD_GEOMETRY = new THREE.ExtrudeGeometry(cardShape, { depth: CARD_PROPERTIES.depth, bevelEnabled: false,}).translate(0, 0, -CARD_PROPERTIES.depth / 2);
const [cardVertexShader, cardFragmentShader] = await Promise.all([
    fetch('/shaders/card.vert').then(res => res.text()),
    fetch('/shaders/card.frag').then(res => res.text())
]);
export const CARD_MATERIAL = new THREE.ShaderMaterial({
    uniforms: {
        baseColor: { value: new THREE.Color(0x909090) },
        embossColor: { value: new THREE.Color(0xffffff) },
        embossStrength: { value: 5.0 },
        lightPosition: { value: new THREE.Vector3(10, 10, 10) },
        ambientColor: { value: new THREE.Color(0.3, 0.3, 0.3) },
        diffuseColor: { value: new THREE.Color(0.1, 0.1, 0.1) },
        specularColor: { value: new THREE.Color(0.2, 0.2, 0.2) },
        shininess: { value: 32.0 },
    },
    vertexShader: cardVertexShader,
    fragmentShader: cardFragmentShader,
    transparent: true,
});

export const SUBTASK_BUTTON = new THREE.Group();
SUBTASK_BUTTON.TEXT = new Text();
SUBTASK_BUTTON.TEXT.text = "+ SubTask";
SUBTASK_BUTTON.TEXT.color = 0xfcda77;
SUBTASK_BUTTON.TEXT.fontSize = 0.5; // Розмір тексту
SUBTASK_BUTTON.width = SUBTASK_BUTTON.TEXT.text.length * SUBTASK_BUTTON.TEXT.fontSize / 1.8 + CARD_PROPERTIES.padding * 2;
SUBTASK_BUTTON.height = SUBTASK_BUTTON.TEXT.fontSize + CARD_PROPERTIES.padding * 2;
SUBTASK_BUTTON.xOffset = CARD_PROPERTIES.width / 2 - SUBTASK_BUTTON.width;
SUBTASK_BUTTON.yOffset = -CARD_PROPERTIES.height / 2 + CARD_PROPERTIES.padding;
SUBTASK_BUTTON.zOffset = CARD_PROPERTIES.depth / 2 + 0.1;
SUBTASK_BUTTON.depth = CARD_PROPERTIES.padding / 2;
SUBTASK_BUTTON.TEXT.font = 'fonts/Roboto-Bold.ttf'; // URL до шрифту
SUBTASK_BUTTON.TEXT.anchorX = SUBTASK_BUTTON.width / 2;
SUBTASK_BUTTON.TEXT.anchorY = SUBTASK_BUTTON.height / 2;
SUBTASK_BUTTON.TEXT.position.set(SUBTASK_BUTTON.xOffset + SUBTASK_BUTTON.width / 2, SUBTASK_BUTTON.yOffset + SUBTASK_BUTTON.height / 2, SUBTASK_BUTTON.zOffset + SUBTASK_BUTTON.depth);
SUBTASK_BUTTON.TEXT.depthOffset = -0.3;
SUBTASK_BUTTON.add(SUBTASK_BUTTON.TEXT);
SUBTASK_BUTTON.BACKGROUND = new THREE.Mesh(
    new THREE.ExtrudeGeometry(
        new THREE.Shape()
            .moveTo(SUBTASK_BUTTON.height / 2, SUBTASK_BUTTON.height)
            .lineTo(SUBTASK_BUTTON.width - SUBTASK_BUTTON.height / 2, SUBTASK_BUTTON.height)
            .quadraticCurveTo(SUBTASK_BUTTON.width, SUBTASK_BUTTON.height, SUBTASK_BUTTON.width, SUBTASK_BUTTON.height / 2)
            .quadraticCurveTo(SUBTASK_BUTTON.width, 0, SUBTASK_BUTTON.width - SUBTASK_BUTTON.height / 2, 0)
            .lineTo(SUBTASK_BUTTON.height / 2, 0)
            .quadraticCurveTo(0, 0, 0, SUBTASK_BUTTON.height / 2)
            .quadraticCurveTo(0, SUBTASK_BUTTON.height, SUBTASK_BUTTON.height / 2, SUBTASK_BUTTON.height),
        { depth: SUBTASK_BUTTON.depth, bevelEnabled: false,}
    ).center(SUBTASK_BUTTON.xOffset - SUBTASK_BUTTON.width / 2, SUBTASK_BUTTON.yOffset - SUBTASK_BUTTON.height / 2, SUBTASK_BUTTON.depth / 2),
    new THREE.MeshStandardMaterial({ color: '#404040', transparent: false, })
);
SUBTASK_BUTTON.BACKGROUND.position.set(
    SUBTASK_BUTTON.xOffset + SUBTASK_BUTTON.width / 2 - CARD_PROPERTIES.padding,
    SUBTASK_BUTTON.yOffset - SUBTASK_BUTTON.height / 2 + CARD_PROPERTIES.padding,
    SUBTASK_BUTTON.zOffset
);
SUBTASK_BUTTON.BACKGROUND.rcID = RC_CARD_SUBTASK_BUTTON;    
SUBTASK_BUTTON.add(SUBTASK_BUTTON.BACKGROUND);
SUBTASK_BUTTON.scale.set(0, 0, 0);

SUBTASK_BUTTON.onCardHover = (instanceId, scale) => {
    if (SUBTASK_BUTTON.instanceId === instanceId) return;
    SUBTASK_BUTTON.instanceId = instanceId;
    
    if (instanceId === null) {
        gsap.to(SUBTASK_BUTTON.BACKGROUND.scale, { x: 0, y: 0, z: 0 , duration: 0.2, ease: 'sine.inOut', });
        gsap.to(SUBTASK_BUTTON.TEXT.scale, { x: 0, y: 0, z: 0 , duration: 0.2, ease: 'sine.inOut', });
        return;
    }
    SUBTASK_BUTTON.scale.set(scale, scale, scale);
    gsap.fromTo(SUBTASK_BUTTON.BACKGROUND.scale, { x: 0, y: 0, z: 0}, { x: 1, y: 1, z: 1, duration: 0.2, ease: 'sine.inOut', });
    gsap.fromTo(SUBTASK_BUTTON.TEXT.scale, { x: 0, y: 0, z: 0}, { x: 1, y: 1, z: 1, duration: 0.2, ease: 'sine.inOut', });
}

SUBTASK_BUTTON.onHover = (hovered) => {
  if (SUBTASK_BUTTON.isHovered === hovered) return;
  SUBTASK_BUTTON.isHovered = hovered;
  gsap.to(SUBTASK_BUTTON.BACKGROUND.material.color, { r: hovered ? 0.08 : 0.04, g: hovered ? 0.08 : 0.04, b: hovered ? 0.08 : 0.04, duration: 0.1, ease: 'sine.inOut' });
  document.body.style.cursor = hovered ? 'pointer' : 'default';
}

SUBTASK_BUTTON.animate = (position, quaternion) => {
  if (SUBTASK_BUTTON.instanceId || SUBTASK_BUTTON.instanceId === 0) {
    SUBTASK_BUTTON.position.copy(position);
    SUBTASK_BUTTON.quaternion.copy(quaternion);
  }
}

SUBTASK_BUTTON.onClick = (node, dispatch, blockAllTargets) => {
    const newNode = {id: uuidv4(), x: node.x, y: node.y, z: node.z, };
    if (node.dependent) blockAllTargets(node);
    dispatch(addNodeRequest(newNode));
    dispatch(addLinkRequest({id: uuidv4(), target: node.id, source: newNode.id, type: node.dependent ? 0 : 1 }));
    return newNode.id;
}

export const NEW_LINK = new THREE.Mesh(new THREE.CylinderGeometry(1, 1, 1, 5), new THREE.MeshStandardMaterial({ opacity: 0.2, transparent: true}));
NEW_LINK.scale.set(0, 0, 0);
export const NEW_CONNECTION_BUTTONS = new THREE.Group();
NEW_CONNECTION_BUTTONS.INTERACTIVE_SPRITE = new THREE.Mesh(new THREE.SphereGeometry(1.15, 8, 6), new THREE.MeshBasicMaterial({alphaTest: 1, transparent: true, opacity: 0.1, }));
NEW_CONNECTION_BUTTONS.INTERACTIVE_SPRITE.rcID = RC_CARD_CORE;
NEW_CONNECTION_BUTTONS.add(NEW_CONNECTION_BUTTONS.INTERACTIVE_SPRITE);
NEW_CONNECTION_BUTTONS.width = 0.2;
NEW_CONNECTION_BUTTONS.height = 0.6;
NEW_CONNECTION_BUTTONS.SUPER = new THREE.Mesh(
    new THREE.ExtrudeGeometry(
        new THREE.Shape()
        .lineTo(NEW_CONNECTION_BUTTONS.width, 0)
        .quadraticCurveTo(NEW_CONNECTION_BUTTONS.width, NEW_CONNECTION_BUTTONS.height / 4, NEW_CONNECTION_BUTTONS.width / 2, NEW_CONNECTION_BUTTONS.height / 2)
        .quadraticCurveTo(0, NEW_CONNECTION_BUTTONS.height * 3/4, 0, NEW_CONNECTION_BUTTONS.height)
        .lineTo(NEW_CONNECTION_BUTTONS.width / 2, NEW_CONNECTION_BUTTONS.height)
        .lineTo(-NEW_CONNECTION_BUTTONS.width / 2, NEW_CONNECTION_BUTTONS.height + NEW_CONNECTION_BUTTONS.width)
        .lineTo(-NEW_CONNECTION_BUTTONS.width * 1.5, NEW_CONNECTION_BUTTONS.height)
        .lineTo(-NEW_CONNECTION_BUTTONS.width, NEW_CONNECTION_BUTTONS.height)
        .quadraticCurveTo(-NEW_CONNECTION_BUTTONS.width, NEW_CONNECTION_BUTTONS.height * 3/4, -NEW_CONNECTION_BUTTONS.width / 2, NEW_CONNECTION_BUTTONS.height / 2)
        .quadraticCurveTo(0, NEW_CONNECTION_BUTTONS.height / 4, 0, 0),
        { depth: NEW_CONNECTION_BUTTONS.width / 2, bevelEnabled: false,}),
    new THREE.MeshStandardMaterial({ transparent: true, opacity: 0.5,})
);
NEW_CONNECTION_BUTTONS.SUPER.position.set(-NEW_CONNECTION_BUTTONS.width / 2, CARD_PROPERTIES.holeRadius, CARD_PROPERTIES.depth / 2);
NEW_CONNECTION_BUTTONS.SUPER.scale.set(0, 0, 1);
NEW_CONNECTION_BUTTONS.add(NEW_CONNECTION_BUTTONS.SUPER);
NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE.rcID = RC_CARD_CONNECTIONS_SUPER;
NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE.scale.set(NEW_CONNECTION_BUTTONS.width, NEW_CONNECTION_BUTTONS.height + NEW_CONNECTION_BUTTONS.width, 1);
NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE.position.set(
    -NEW_CONNECTION_BUTTONS.width / 2,
    NEW_CONNECTION_BUTTONS.height + NEW_CONNECTION_BUTTONS.width * 2 + NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE.scale.y / 2,
    CARD_PROPERTIES.depth / 1.9
);
NEW_CONNECTION_BUTTONS.add(NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE);
NEW_CONNECTION_BUTTONS.SUPER.onHover = (hovered) => {
    if (NEW_CONNECTION_BUTTONS.SUPER.isHovered === hovered) return;
    NEW_CONNECTION_BUTTONS.SUPER.isHovered = hovered;
    NEW_CONNECTION_BUTTONS.SUPER.material.opacity = hovered ? 1 : 0.5;
        document.body.style.cursor = hovered ? 'pointer' : 'default';
}

NEW_CONNECTION_BUTTONS.SUB = new THREE.Mesh(
    new THREE.ExtrudeGeometry(
        new THREE.Shape()
            .moveTo(-NEW_CONNECTION_BUTTONS.width, 0)
            .lineTo(0, 0)
            .quadraticCurveTo(0, -NEW_CONNECTION_BUTTONS.height / 4, NEW_CONNECTION_BUTTONS.width / 2, -NEW_CONNECTION_BUTTONS.height / 2)
            .quadraticCurveTo(NEW_CONNECTION_BUTTONS.width, -NEW_CONNECTION_BUTTONS.height * 3/4, NEW_CONNECTION_BUTTONS.width, -NEW_CONNECTION_BUTTONS.height)
            .lineTo(NEW_CONNECTION_BUTTONS.width * 1.5, -NEW_CONNECTION_BUTTONS.height)
            .lineTo(NEW_CONNECTION_BUTTONS.width / 2, -NEW_CONNECTION_BUTTONS.height - NEW_CONNECTION_BUTTONS.width)
            .lineTo(-NEW_CONNECTION_BUTTONS.width / 2, -NEW_CONNECTION_BUTTONS.height)
            .lineTo(0, -NEW_CONNECTION_BUTTONS.height)
            .quadraticCurveTo(0, -NEW_CONNECTION_BUTTONS.height * 3/4, -NEW_CONNECTION_BUTTONS.width / 2, -NEW_CONNECTION_BUTTONS.height / 2)
            .quadraticCurveTo(-NEW_CONNECTION_BUTTONS.width, -NEW_CONNECTION_BUTTONS.height / 4, -NEW_CONNECTION_BUTTONS.width, 0),
        { depth: NEW_CONNECTION_BUTTONS.width / 2, bevelEnabled: false,}),
    new THREE.MeshStandardMaterial({color: '#707070', transparent: true, opacity: 0.5,})
);
NEW_CONNECTION_BUTTONS.SUB.position.set(NEW_CONNECTION_BUTTONS.width / 2, -CARD_PROPERTIES.holeRadius, CARD_PROPERTIES.depth / 2);
NEW_CONNECTION_BUTTONS.SUB.scale.set(0, 0, 1);
NEW_CONNECTION_BUTTONS.add(NEW_CONNECTION_BUTTONS.SUB);
NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE.rcID = RC_CARD_CONNECTIONS_SUB;
NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE.scale.set(NEW_CONNECTION_BUTTONS.width, NEW_CONNECTION_BUTTONS.height + NEW_CONNECTION_BUTTONS.width, 1);
NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE.position.set(
    NEW_CONNECTION_BUTTONS.width / 2,
    -NEW_CONNECTION_BUTTONS.height - NEW_CONNECTION_BUTTONS.width * 2 - NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE.scale.y / 2,
    CARD_PROPERTIES.depth / 1.9
);
NEW_CONNECTION_BUTTONS.add(NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE);
    
NEW_CONNECTION_BUTTONS.SUB.onHover = (hovered) => {
    if (NEW_CONNECTION_BUTTONS.SUB.isHovered === hovered) return;
    NEW_CONNECTION_BUTTONS.SUB.isHovered = hovered;

    NEW_CONNECTION_BUTTONS.SUB.material.opacity = hovered ? 1 : 0.5;
    document.body.style.cursor = hovered ? 'pointer' : 'default';
}

NEW_CONNECTION_BUTTONS.onCardHover = (instanceId, status, scale) => {
    if (NEW_CONNECTION_BUTTONS.instanceId === instanceId) return;
    NEW_CONNECTION_BUTTONS.instanceId = instanceId;
    if (!NEW_CONNECTION_BUTTONS.instanceId && NEW_CONNECTION_BUTTONS.instanceId !== 0) return;
    NEW_CONNECTION_BUTTONS.scale.set(scale, scale, scale);
    NEW_CONNECTION_BUTTONS.SUPER.material.color.set(new THREE.Color(STATUS_COLORS[status].firstColor));
};

NEW_CONNECTION_BUTTONS.onHover = (hovered) => {
    if (NEW_CONNECTION_BUTTONS.isHovered === hovered) return;
    NEW_CONNECTION_BUTTONS.isHovered = hovered;

    document.body.style.cursor = hovered ? 'move' : 'default';

    const scale = hovered && !NEW_LINK.node ? 1 : 0;
    NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE.scale.set(scale, scale, scale);
    NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE.scale.set(scale, scale, scale);
    gsap.to(NEW_CONNECTION_BUTTONS.SUB.scale, { x: scale, y: scale, duration: 0.08, ease: 'sine.inOut', });
    gsap.to(NEW_CONNECTION_BUTTONS.SUPER.scale, { x: scale, y: scale, duration: 0.08, ease: 'sine.inOut', });
};

NEW_CONNECTION_BUTTONS.animate = (hoveredInstance, position, quaternion) => {
    if ((hoveredInstance || hoveredInstance === 0) && !NEW_LINK.node) {
        NEW_CONNECTION_BUTTONS.position.copy(position);
        NEW_CONNECTION_BUTTONS.quaternion.copy(quaternion);
    }
}

export const CARD_STATUS = new THREE.Group();
CARD_STATUS.scale.set(1, 0, 1);
CARD_STATUS.xOffset = 1;
CARD_STATUS.yOffset = CARD_PROPERTIES.height / 2;
CARD_STATUS.width = 3.1;
CARD_STATUS.height = 1;
CARD_STATUS.borderRadius = 0.2;
CARD_STATUS.borderWidth = 0.03;
CARD_STATUS.vertexShader = `
    varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`;
CARD_STATUS.fragmentShader = `
    uniform vec3 firstColor;
    uniform vec3 secondColor;
    uniform float opacity;
    varying vec2 vUv;
    void main() {
        vec3 gradient = mix(firstColor, secondColor, 1.- vUv.y +${CARD_STATUS.yOffset});
        gl_FragColor = vec4(gradient, opacity);
    }
`;

CARD_STATUS.TEXT = new Text();
CARD_STATUS.TEXT.fontSize = 0.5;
CARD_STATUS.TEXT.anchorX = -1 - CARD_PROPERTIES.padding;
CARD_STATUS.TEXT.anchorY = -CARD_PROPERTIES.height / 2 - CARD_STATUS.TEXT.fontSize - CARD_PROPERTIES.padding;
CARD_STATUS.TEXT.depthOffset = 0.5;
CARD_STATUS.TEXT.font = 'fonts/Roboto-Bold.ttf';
CARD_STATUS.TEXT.fillOpacity = 0.8;
CARD_STATUS.TEXT.position.set(0, 0, 1.5);
CARD_STATUS.add(CARD_STATUS.TEXT);

CARD_STATUS.BACKGROUND = new THREE.Mesh(
    new THREE.ShapeGeometry(new THREE.Shape()
        .moveTo(CARD_STATUS.xOffset, CARD_STATUS.yOffset)
        .lineTo(CARD_STATUS.xOffset, CARD_STATUS.yOffset + CARD_STATUS.height - CARD_STATUS.borderRadius)
        .quadraticCurveTo(CARD_STATUS.xOffset, CARD_STATUS.yOffset + CARD_STATUS.height, CARD_STATUS.xOffset + CARD_STATUS.borderRadius, CARD_STATUS.yOffset + CARD_STATUS.height)
        .lineTo(CARD_STATUS.xOffset + CARD_STATUS.width - CARD_STATUS.borderRadius, CARD_STATUS.yOffset + CARD_STATUS.height)
        .quadraticCurveTo(CARD_STATUS.xOffset + CARD_STATUS.width, CARD_STATUS.yOffset + CARD_STATUS.height, CARD_STATUS.xOffset + CARD_STATUS.width, CARD_STATUS.yOffset + CARD_STATUS.height - CARD_STATUS.borderRadius)
        .lineTo(CARD_STATUS.xOffset + CARD_STATUS.width, CARD_STATUS.yOffset)
        .lineTo(CARD_STATUS.xOffset, CARD_STATUS.yOffset)
        .lineTo(CARD_STATUS.xOffset, CARD_STATUS.yOffset + CARD_STATUS.height - CARD_STATUS.borderRadius)
    ),
    new THREE.ShaderMaterial({
    uniforms: { firstColor: { value: new THREE.Color() }, secondColor: { value: new THREE.Color() }, opacity: { value: 0.2 },},
    vertexShader: CARD_STATUS.vertexShader,
    fragmentShader: CARD_STATUS.fragmentShader,
    transparent: true
}));
CARD_STATUS.add(CARD_STATUS.BACKGROUND);

CARD_STATUS.BACKGROUND_BORDER_PATH = new THREE.CurvePath();
CARD_STATUS.BACKGROUND_BORDER_PATH.add(new THREE.QuadraticBezierCurve3(
    new THREE.Vector3(CARD_STATUS.xOffset, CARD_STATUS.yOffset  + CARD_STATUS.height - CARD_STATUS.borderRadius, -CARD_STATUS.borderWidth),
    new THREE.Vector3(CARD_STATUS.xOffset, CARD_STATUS.yOffset + CARD_STATUS.height, -CARD_STATUS.borderWidth),
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.borderRadius, CARD_STATUS.yOffset + CARD_STATUS.height, -CARD_STATUS.borderWidth)
));
CARD_STATUS.BACKGROUND_BORDER_PATH.add(new THREE.LineCurve3(
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.borderRadius, CARD_STATUS.yOffset + CARD_STATUS.height, -CARD_STATUS.borderWidth),
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.width - CARD_STATUS.borderRadius, CARD_STATUS.yOffset + CARD_STATUS.height, -CARD_STATUS.borderWidth)
));
CARD_STATUS.BACKGROUND_BORDER_PATH.add(new THREE.QuadraticBezierCurve3(
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.width - CARD_STATUS.borderRadius, CARD_STATUS.yOffset + CARD_STATUS.height, -CARD_STATUS.borderWidth),
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.width, CARD_STATUS.yOffset + CARD_STATUS.height, -CARD_STATUS.borderWidth),
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.width, CARD_STATUS.yOffset + CARD_STATUS.height - CARD_STATUS.borderRadius, -CARD_STATUS.borderWidth)
));
CARD_STATUS.BACKGROUND_BORDER_PATH.add(new THREE.LineCurve3(
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.width, CARD_STATUS.yOffset + CARD_STATUS.height - CARD_STATUS.borderRadius, -CARD_STATUS.borderWidth),
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.width, CARD_STATUS.yOffset, -CARD_STATUS.borderWidth)
));
CARD_STATUS.BACKGROUND_BORDER_PATH.add(new THREE.LineCurve3(
    new THREE.Vector3(CARD_STATUS.xOffset + CARD_STATUS.width, CARD_STATUS.yOffset, -CARD_STATUS.borderWidth),
    new THREE.Vector3(CARD_STATUS.xOffset, CARD_STATUS.yOffset, -CARD_STATUS.borderWidth)
));
CARD_STATUS.BACKGROUND_BORDER_PATH.add(new THREE.LineCurve3(
    new THREE.Vector3(CARD_STATUS.xOffset, CARD_STATUS.yOffset, -CARD_STATUS.borderWidth),
    new THREE.Vector3(CARD_STATUS.xOffset, CARD_STATUS.yOffset + CARD_STATUS.height - CARD_STATUS.borderRadius, -CARD_STATUS.borderWidth)
));
CARD_STATUS.BACKGROUND_BORDER = new THREE.Mesh(
    new THREE.TubeGeometry(
        CARD_STATUS.BACKGROUND_BORDER_PATH,
        128,
        CARD_STATUS.borderWidth,
        3),
    new THREE.LineBasicMaterial({transparent: true, opacity: 0.4})
);
CARD_STATUS.add(CARD_STATUS.BACKGROUND_BORDER);
CARD_STATUS.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
CARD_STATUS.INTERACTIVE_SPRITE.scale.set(CARD_STATUS.width, (CARD_STATUS.height + CARD_PROPERTIES.padding), 1);
CARD_STATUS.INTERACTIVE_SPRITE.position.set(CARD_STATUS.xOffset, CARD_STATUS.yOffset, -CARD_PROPERTIES.depth / 2);
CARD_STATUS.INTERACTIVE_SPRITE.center.set(0, 0, 0);
CARD_STATUS.INTERACTIVE_SPRITE.rcID = RC_CARD_STATUS;
CARD_STATUS.add(CARD_STATUS.INTERACTIVE_SPRITE);
    
CARD_STATUS.onCardHover = (instanceId, status, scale) => {
    if (CARD_STATUS.instanceId === instanceId) return;
    CARD_STATUS.instanceId = instanceId;
    
    if (instanceId === null) {
        // gsap.to(CARD_STATUS.position, { y: CARD_STATUS.position.y-CARD_PROPERTIES.height / 2, duration: 0.2, ease: 'sine.inOut', });
        gsap.to(CARD_STATUS.scale, { y: 0 , duration: 0.2, ease: 'sine.inOut', });
        return;
    }
    CARD_STATUS.TEXT.text = STATUS_OPTIONS[status];

    const mainColor = new THREE.Color(STATUS_COLORS[status].firstColor);

    CARD_STATUS.BACKGROUND.material.uniforms.firstColor.value = mainColor;
    CARD_STATUS.BACKGROUND.material.uniforms.secondColor.value = new THREE.Color(STATUS_COLORS[status].secondColor);

    CARD_STATUS.BACKGROUND_BORDER.material.color = mainColor;
    CARD_STATUS.TEXT.color = mainColor;

    gsap.fromTo(CARD_STATUS.scale, {x: scale, y: 0}, { x: scale, y: scale, duration: 0.2, ease: 'sine.inOut', });
    // gsap.to(CARD_STATUS.position,  { y: 0, duration: 0.2, ease: 'sine.inOut', });
};

CARD_STATUS.BUTTONS = [];
STATUS_OPTIONS.forEach((so, i) => {
    if (i === 2) return;
    const button = new THREE.Group();
    button.status = i;
    CARD_STATUS.BUTTONS.push(button);
    button.text = new Text();
    button.text.fontSize = 0.5; // Розмір тексту
    button.text.anchorX = -CARD_PROPERTIES.padding;
    button.text.anchorY = -button.text.fontSize -CARD_PROPERTIES.padding;
    button.text.depthOffset = 0.5;
    button.text.text = so;
    button.text.font = 'fonts/Roboto-Regular.ttf'; // URL до шрифту
    button.text.color = STATUS_COLORS[i].firstColor;
    button.text.fillOpacity = 0.8;
    button.text.position.set(0, 0, 1.5);
    button.add(button.text);
    button.background = new THREE.Mesh(
        new THREE.ShapeGeometry(
            new THREE.Shape()
                .moveTo(0, CARD_STATUS.height / 2)
                .quadraticCurveTo(0, CARD_STATUS.height, CARD_STATUS.height / 2, CARD_STATUS.height)
                .lineTo(CARD_STATUS.width - CARD_STATUS.height / 2, CARD_STATUS.height)
                .quadraticCurveTo(CARD_STATUS.width, CARD_STATUS.height, CARD_STATUS.width, CARD_STATUS.height / 2)
                .quadraticCurveTo(CARD_STATUS.width, 0, CARD_STATUS.width - CARD_STATUS.height / 2, 0)
                .lineTo(CARD_STATUS.height / 2, 0)
                .quadraticCurveTo(0, 0, 0, CARD_STATUS.height / 2)),
        new THREE.ShaderMaterial({ uniforms: {
            firstColor: { value: new THREE.Color(STATUS_COLORS[i].firstColor) },
            secondColor: { value: new THREE.Color(STATUS_COLORS[i].secondColor) },
            opacity: { value: 0.2 },},
        vertexShader: CARD_STATUS.vertexShader,
        fragmentShader: CARD_STATUS.fragmentShader,
        transparent: true
    }));
    button.add(button.background);
    button.background.rcID = RC_CARD_STATUS + i +1;
    const borderPath = new THREE.CurvePath();
    borderPath.add(new THREE.QuadraticBezierCurve3(
        new THREE.Vector3(0, CARD_STATUS.height / 2, -CARD_STATUS.borderWidth),
        new THREE.Vector3(0, CARD_STATUS.height, -CARD_STATUS.borderWidth),
        new THREE.Vector3(CARD_STATUS.height / 2, CARD_STATUS.height, -CARD_STATUS.borderWidth)
    ));
    borderPath.add(new THREE.LineCurve3(
        new THREE.Vector3(CARD_STATUS.height / 2, CARD_STATUS.height, -CARD_STATUS.borderWidth),
        new THREE.Vector3(CARD_STATUS.width - CARD_STATUS.height / 2, CARD_STATUS.height, -CARD_STATUS.borderWidth)
    ));
    borderPath.add(new THREE.QuadraticBezierCurve3(
        new THREE.Vector3(CARD_STATUS.width - CARD_STATUS.height / 2, CARD_STATUS.height, -CARD_STATUS.borderWidth),
        new THREE.Vector3(CARD_STATUS.width, CARD_STATUS.height, -CARD_STATUS.borderWidth),
        new THREE.Vector3(CARD_STATUS.width, CARD_STATUS.height / 2, -CARD_STATUS.borderWidth)
    ));
    borderPath.add(new THREE.QuadraticBezierCurve3(
        new THREE.Vector3(CARD_STATUS.width, CARD_STATUS.height / 2, -CARD_STATUS.borderWidth),
        new THREE.Vector3(CARD_STATUS.width, 0, -CARD_STATUS.borderWidth),
        new THREE.Vector3(CARD_STATUS.width - CARD_STATUS.height / 2, 0, -CARD_STATUS.borderWidth)
    ));
    borderPath.add(new THREE.LineCurve3(
        new THREE.Vector3(CARD_STATUS.width - CARD_STATUS.height / 2, 0, -CARD_STATUS.borderWidth),
        new THREE.Vector3(CARD_STATUS.height / 2, 0, -CARD_STATUS.borderWidth)
    ));
    borderPath.add(new THREE.QuadraticBezierCurve3(
        new THREE.Vector3(CARD_STATUS.height / 2, 0, -CARD_STATUS.borderWidth),
        new THREE.Vector3(0, 0, -CARD_STATUS.borderWidth),
        new THREE.Vector3(0, CARD_STATUS.height / 2, -CARD_STATUS.borderWidth)
    ));
    button.border = new THREE.Mesh(
        new THREE.TubeGeometry(
            borderPath,
            128,
            CARD_STATUS.borderWidth,
            3),
        new THREE.LineBasicMaterial({color: STATUS_COLORS[i].firstColor, transparent: true, opacity: 0.4})
    );
    button.add(button.border);
    button.position.set(CARD_STATUS.xOffset, CARD_STATUS.yOffset - CARD_STATUS.height - CARD_PROPERTIES.padding, 0);
    button.scale.set(0, 0, 0);
    CARD_STATUS.add(button);

    button.onHover = (hovered) => {
        if (button.isHovered === hovered) return;
        button.isHovered = hovered;
        button.text.font = hovered ? 'fonts/Roboto-Bold.ttf' : 'fonts/Roboto-Regular.ttf';
        button.background.material.uniforms.opacity.value = hovered ? 0.3 : 0.2;
        button.border.material.opacity = hovered ? 1 : 0.4;
        document.body.style.cursor = hovered ? 'pointer' : 'default';
    };

    button.onClick = (node, dispatch, blockAllTargets) => {
        const changes = [{ id: node.id, status: i }];
        node.targets.forEach(target => {
            if (target.node.sources.some(source => source.node === node ? i !== 3 : source.node.status !== 3))
                blockAllTargets(target.node);

            else if (target.node !== 0)
                changes.push({ id: target.node.id, status: 0 });
        });
        dispatch(updateNodesRequest(changes));

        node.status = i;
        return true;
    };
});
    
CARD_STATUS.onHover = (hovered, status) => {
    if (CARD_STATUS.isHovered === hovered) return;
    CARD_STATUS.isHovered = hovered;
    CARD_STATUS.BUTTONS.forEach(button => gsap.killTweensOf([button.position, button.scale]));
    if (hovered) {
        if (status === undefined || status === 2) return;
        CARD_STATUS.INTERACTIVE_SPRITE.scale.set(CARD_STATUS.width, (CARD_STATUS.height + CARD_PROPERTIES.padding) * STATUS_OPTIONS.length - 1, 1);
        let pos = 0;
        CARD_STATUS.BUTTONS.forEach(button => {
            if (button.status !== status) {
                pos++;
                gsap.to(button.position, { y: CARD_STATUS.yOffset + (CARD_STATUS.height + CARD_PROPERTIES.padding) * pos, duration: 0.1, delay: 0.05 * pos, ease: 'sine.inOut', });
                gsap.to(button.scale, { x: 1, y: 1, z: 1, duration: 0.1, delay: 0.05 * pos, ease: 'sine.inOut', });
            };
        });
    } else {
        CARD_STATUS.INTERACTIVE_SPRITE.scale.set(CARD_STATUS.width, (CARD_STATUS.height + CARD_PROPERTIES.padding), 1);
        let pos = CARD_STATUS.BUTTONS.length;
        CARD_STATUS.BUTTONS.forEach(button => {
            pos--;
            gsap.to(button.position, { y: CARD_STATUS.yOffset - CARD_STATUS.height - CARD_PROPERTIES.padding, duration: 0.1, delay: 0.05 * pos, ease: 'sine.inOut', });
            gsap.to(button.scale, { x: 0, y: 0, z: 0, duration: 0.1, delay: 0.05 * pos, ease: 'sine.inOut', });
        });
    }
};
    
CARD_STATUS.animate = (position, quaternion) => {
    if (CARD_STATUS.instanceId || CARD_STATUS.instanceId === 0) {
        CARD_STATUS.position.copy(position);
        CARD_STATUS.quaternion.copy(quaternion);
    }
}

CARD_STATUS.onChangeStatus = (status) => {
    const first = new THREE.Color(STATUS_COLORS[status].firstColor);
    const second = new THREE.Color(STATUS_COLORS[status].firstColor);
    
    let pos = 0;
    CARD_STATUS.BUTTONS.forEach(button => {
        if (button.status !== status) {
            pos++;
            gsap.to(button.position, { y: CARD_STATUS.yOffset + (CARD_STATUS.height + CARD_PROPERTIES.padding) * pos, duration: 0.1, delay: 0.05 * pos, ease: 'sine.inOut', });
            gsap.to(button.scale, { x: 1, y: 1, z: 1, duration: 0.1, delay: 0.05 * pos, ease: 'sine.inOut', });
        } else {
            gsap.to(button.position, { y: CARD_STATUS.yOffset - CARD_STATUS.height - CARD_PROPERTIES.padding, duration: 0.1, delay: 0.05 * pos, ease: 'sine.inOut', });
            gsap.to(button.scale, { x: 0, y: 0, z: 0, duration: 0.1, delay: 0.05 * pos, ease: 'sine.inOut', });
        }
    });

    gsap.to(CARD_STATUS.BACKGROUND.material.uniforms.firstColor.value, {r: first.r, g: first.g, b: first.b, duration: 2,});
    gsap.to(CARD_STATUS.BACKGROUND.material.uniforms.secondColor.value, {r: second.r, g: second.g, b: second.b, duration: 2,});
    CARD_STATUS.TEXT.t = 0;
    const oldText = CARD_STATUS.TEXT.text;
    const newText = STATUS_OPTIONS[status];
    const maxLength = Math.max(oldText.length, newText.length);
    const delta = {t: 0};
    gsap.to(delta, {t: 1, duration: 1, ease: 'sine.in',
        onUpdate: () => {
            CARD_STATUS.TEXT.text = newText.slice(0, Math.floor(delta.t * newText.length)).padEnd(maxLength, " ");
            CARD_STATUS.TEXT.sync();
        },
      });
    CARD_SETTINGS.firstColor = new THREE.Color(STATUS_COLORS[status].firstColor.slice(0, 7)).lerp(CARD_SETTINGS.colorFilter.color, CARD_SETTINGS.colorFilter.alpha);
    gsap.to(CARD_SETTINGS.firstMaterial.color, {
        r: CARD_SETTINGS.firstColor.r,
        g: CARD_SETTINGS.firstColor.g,
        b: CARD_SETTINGS.firstColor.b,
         duration: 2, });
    CARD_SETTINGS.secondColor = new THREE.Color(STATUS_COLORS[status].secondColor.slice(0, 7)).lerp(CARD_SETTINGS.colorFilter.color, CARD_SETTINGS.colorFilter.alpha);
    gsap.to(CARD_SETTINGS.secondMaterial.color, {
        r: CARD_SETTINGS.secondColor.r,
        g: CARD_SETTINGS.secondColor.g,
        b: CARD_SETTINGS.secondColor.b,
         duration: 2, });
}

export const CARD_VOLUME = new THREE.Group();
CARD_VOLUME.scale.set(0, 0, 0);
CARD_VOLUME.position.set(CARD_PROPERTIES.x + (CARD_PROPERTIES.borderRadius/2 + CARD_PROPERTIES.width)*1.01, 0, 0);
CARD_VOLUME.DIVISIONS = 10;
CARD_VOLUME.barHeight = CARD_PROPERTIES.height / CARD_VOLUME.DIVISIONS;
CARD_VOLUME.BARS = [];
for (let i = 0; i < CARD_VOLUME.DIVISIONS; i++) {
    const bar = new THREE.Mesh(
        new THREE.BoxGeometry(CARD_PROPERTIES.width*0.05, CARD_VOLUME.barHeight*0.8, CARD_VOLUME.barHeight*0.9),
        new THREE.MeshStandardMaterial({ color: i < 5 ? 0x00ff99 : i < 8 ? 0xfcf400 : 0xff3300, transparent: true })
    );
    bar.position.set(CARD_PROPERTIES.x + (CARD_PROPERTIES.borderRadius/2 + CARD_PROPERTIES.width), i*CARD_VOLUME.barHeight + CARD_VOLUME.barHeight/2 - CARD_PROPERTIES.height/2, CARD_PROPERTIES.depth / 2 - CARD_VOLUME.barHeight*0.8);
    CARD_VOLUME.add(bar);
    
}
CARD_VOLUME.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
CARD_VOLUME.INTERACTIVE_SPRITE.position.set(CARD_PROPERTIES.x + (CARD_PROPERTIES.borderRadius/2 + CARD_PROPERTIES.width), 0, CARD_PROPERTIES.depth / 2);
CARD_VOLUME.INTERACTIVE_SPRITE.scale.set(CARD_PROPERTIES.width*0.1, CARD_PROPERTIES.height, 1);
CARD_VOLUME.INTERACTIVE_SPRITE.rcID = RC_CARD_VOLUME;
CARD_VOLUME.add(CARD_VOLUME.INTERACTIVE_SPRITE);
CARD_VOLUME.LABEL = new Text();
CARD_VOLUME.LABEL.scale.set(1, 0, 1);
CARD_VOLUME.LABEL.geometry.translate(0, 0, CARD_PROPERTIES.depth / 2 + 0.05);
CARD_VOLUME.LABEL.depthOffset = -1;
CARD_VOLUME.LABEL.anchorX = -(CARD_PROPERTIES.x + (CARD_PROPERTIES.borderRadius/2 + CARD_PROPERTIES.width))*1.1;
CARD_VOLUME.LABEL.font = 'fonts/Roboto-Bold.ttf'; // URL до шрифту
CARD_VOLUME.LABEL.fontSize = 0.5;
CARD_VOLUME.LABEL.outlineWidth = 0.05;
CARD_VOLUME.LABEL.outlineColor = 0xffffff;
CARD_VOLUME.LABEL.outlineOpacity = 0.1;
CARD_VOLUME.LABEL.outlineBlur = 0.1;
CARD_VOLUME.add(CARD_VOLUME.LABEL);

CARD_VOLUME.onHover = (hovered, y) => {
    if (CARD_VOLUME.isHovered === hovered && !hovered) return;
    CARD_VOLUME.isHovered = hovered;
    document.body.style.cursor = hovered ? 'pointer' : 'default';
    if (hovered) {
        gsap.to(CARD_VOLUME.LABEL.scale, { x: 1, y: 1, duration: 0.1, ease: 'sine.inOut', });
        gsap.to(CARD_VOLUME.LABEL, { anchorY: -y*CARD_PROPERTIES.height - CARD_PROPERTIES.y - CARD_VOLUME.LABEL.fontSize / 2, duration: 0.1, ease: 'sine.inOut', });
        const hoveredIndex = Math.floor(y*10);
        CARD_VOLUME.LABEL.text = hoveredIndex+1;
        CARD_VOLUME.LABEL.color = CARD_VOLUME.children[hoveredIndex].material.color;
        CARD_VOLUME.children.forEach((bar, i) => {
            if (i > 9) return;
            bar.material.opacity = i <= hoveredIndex ? 1 : 0.2;
            gsap.to(bar.scale, { x: hoveredIndex === i ? 1.5 : hoveredIndex - i === 1 ? 1.2 : 1, duration: 0.1, ease: 'sine.inOut', });
        });
    } else {
        gsap.to(CARD_VOLUME.LABEL.scale, { x: 0.9, y: 0, duration: 0.1, ease: 'sine.inOut', });
        CARD_VOLUME.children.forEach((bar, i) => {
            if (i > 9) return;
            bar.material.opacity = i <= CARD_VOLUME.currentVolume ? 1 : 0.2;
            gsap.to(bar.scale, { x: 1, duration: 0.1, ease: 'sine.inOut', });
        });
    }
}

CARD_VOLUME.onCardHover = (instanceId, volume, scale) => {
    if (CARD_VOLUME.instanceId === instanceId) return;
    CARD_VOLUME.instanceId = instanceId;
    CARD_VOLUME.currentVolume = volume;
    if (instanceId === null) {
        gsap.to(CARD_VOLUME.scale, { x: 0, y: 0, z: 0, duration: 0.2, ease: 'sine.inOut', });
        CARD_VOLUME.INTERACTIVE_SPRITE.scale.set(0, 0, 0);
        return;
    }
    
    CARD_VOLUME.children.forEach((child, i) => {
        if (i > 9) return;
        child.material.opacity = i <= volume ? 1 : 0.2;
    });

    gsap.fromTo(CARD_VOLUME.scale, { x: 0, y: 0, z: 0 }, {x: scale, y: scale, z: scale, duration: 0.2, ease: 'sine.inOut', });
    CARD_VOLUME.INTERACTIVE_SPRITE.scale.set(CARD_PROPERTIES.width*0.1, CARD_PROPERTIES.height, 1);
}


CARD_VOLUME.animate = (position, quaternion) => {
    if (CARD_VOLUME.instanceId || CARD_VOLUME.instanceId === 0) {
        CARD_VOLUME.position.copy(position);
        CARD_VOLUME.quaternion.copy(quaternion);
    }
}


export const CARD_SETTINGS = new THREE.Group();
CARD_SETTINGS.scale.set(0, 0, 0);
CARD_SETTINGS.xOffset = 7.3;
CARD_SETTINGS.yOffset = 2.4;
CARD_SETTINGS.firstMaterial = new THREE.MeshStandardMaterial({metalness: 0.7, roughness: 0.3,});
CARD_SETTINGS.secondMaterial = CARD_SETTINGS.firstMaterial.clone();
CARD_SETTINGS.colorFilter = {color: new THREE.Color(0x505050), alpha: 0.5};
let gltf = await new Promise((resolve, reject) => GLTF_LOADER.load('models/card_settings/scene.gltf', resolve, undefined, reject));
CARD_SETTINGS.GEARS = gltf.scene;
let index = 0;
CARD_SETTINGS.GEARS.traverse((child) => {if(child.isMesh) {
    child.material.dispose();
    child.material = index === 0 ? CARD_SETTINGS.firstMaterial : CARD_SETTINGS.secondMaterial;
    index++;
}});
CARD_SETTINGS.GEARS.scale.set(0.4, 0.4, 0.4);
CARD_SETTINGS.GEARS.position.set(CARD_SETTINGS.xOffset, 0, -CARD_PROPERTIES.depth/2);
CARD_SETTINGS.GEARS.rotation.x = Math.PI/3;
CARD_SETTINGS.GEARS.rotation.y = Math.PI/3;
CARD_SETTINGS.add(CARD_SETTINGS.GEARS);
CARD_SETTINGS.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
CARD_SETTINGS.INTERACTIVE_SPRITE.scale.set(0, 0, 0);
CARD_SETTINGS.INTERACTIVE_SPRITE.position.set(CARD_SETTINGS.xOffset, CARD_SETTINGS.yOffset * 1.4, -0.5);
CARD_SETTINGS.INTERACTIVE_SPRITE.rcID = RC_CARD_SETTINGS;
CARD_SETTINGS.add(CARD_SETTINGS.INTERACTIVE_SPRITE);
CARD_SETTINGS.GEARS.mixer = new THREE.AnimationMixer(CARD_SETTINGS.GEARS);
gltf.animations.forEach((clip) => {CARD_SETTINGS.GEARS.mixer.clipAction(clip).play()});
CARD_SETTINGS.isHovered = false;

CARD_SETTINGS.animate = (position, quaternion) => {
    CARD_SETTINGS.GEARS.mixer.update(CARD_SETTINGS.isHovered ? -0.02 : 0.005);
    CARD_SETTINGS.position.copy(position);
    CARD_SETTINGS.quaternion.copy(quaternion);
}

CARD_SETTINGS.onClick = () => {
    CARD_SETTINGS.TRASH.onSettingsClick();
    CARD_SETTINGS.SHARE_BUTTON.onSettingsClick();
    CARD_SETTINGS.COLLAPSE.onSettingsClick();
}

CARD_SETTINGS.onHover = (hovered) => {
    if (CARD_SETTINGS.isHovered === hovered) return;
    CARD_SETTINGS.isHovered = hovered;
    gsap.to(CARD_SETTINGS.firstMaterial.color, {
        r: hovered ? CARD_SETTINGS.firstColor.r * 5 : CARD_SETTINGS.firstColor.r,
        g: hovered ? CARD_SETTINGS.firstColor.g * 5 : CARD_SETTINGS.firstColor.g,
        b: hovered ? CARD_SETTINGS.firstColor.b * 5 : CARD_SETTINGS.firstColor.b,
        duration: 0.2,
        ease: 'sine.inOut',
    });
    gsap.to(CARD_SETTINGS.secondMaterial.color, {
        r: hovered ? CARD_SETTINGS.secondColor.r * 5 : CARD_SETTINGS.secondColor.r,
        g: hovered ? CARD_SETTINGS.secondColor.g * 5 : CARD_SETTINGS.secondColor.g,
        b: hovered ? CARD_SETTINGS.secondColor.b * 5 : CARD_SETTINGS.secondColor.b,
        duration: 0.2,
        ease: 'sine.inOut',
    });
    if (!hovered) {
        CARD_SETTINGS.TRASH.shown = false;
        gsap.to(CARD_SETTINGS.TRASH.scale, {x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut', });
        CARD_SETTINGS.SHARE_BUTTON.shown = false;
        gsap.to(CARD_SETTINGS.SHARE_BUTTON.scale, {x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut', });
        CARD_SETTINGS.COLLAPSE.shown = false;
        gsap.to(CARD_SETTINGS.COLLAPSE.scale, {x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut', });
    }
    document.body.style.cursor = hovered ? 'pointer' : 'default';
}

CARD_SETTINGS.onCardHover = (instanceId, status, scale) => {
if (CARD_SETTINGS.instanceId === instanceId) return;
    CARD_SETTINGS.instanceId = instanceId;
    if (instanceId === null) {
        gsap.to(CARD_SETTINGS.GEARS.position, {y: 0, duration: 0.2, ease: 'sine.inOut', });
        gsap.to(CARD_SETTINGS.GEARS.scale, { x: 0, y: 0, z: 0, duration: 0.2, ease: 'sine.inOut', });
        CARD_SETTINGS.INTERACTIVE_SPRITE.scale.set(0, 0, 0);
        return;
    }
    gsap.killTweensOf([CARD_SETTINGS.firstMaterial.color, CARD_SETTINGS.secondMaterial.color]);
    CARD_SETTINGS.firstColor = new THREE.Color(STATUS_COLORS[status].firstColor.slice(0, 7)).lerp(CARD_SETTINGS.colorFilter.color, CARD_SETTINGS.colorFilter.alpha);
    CARD_SETTINGS.firstMaterial.color.set(CARD_SETTINGS.firstColor);
    CARD_SETTINGS.secondColor = new THREE.Color(STATUS_COLORS[status].secondColor.slice(0, 7)).lerp(CARD_SETTINGS.colorFilter.color, CARD_SETTINGS.colorFilter.alpha);
    CARD_SETTINGS.secondMaterial.color.set(CARD_SETTINGS.secondColor);
    CARD_SETTINGS.scale.set(scale, scale, scale);

    gsap.fromTo(
        CARD_SETTINGS.GEARS.position, {y: 0}, {y: CARD_SETTINGS.yOffset, duration: 0.2, ease: 'sine.inOut', });
    gsap.fromTo(CARD_SETTINGS.GEARS.scale, {x: 0, y: 0, z: 0}, { x: 0.4, y: 0.4, z: 0.4, duration: 0.2, ease: 'sine.inOut', });
    CARD_SETTINGS.INTERACTIVE_SPRITE.scale.set(6, 4, 1);
}

gltf = await new Promise((resolve, reject) => GLTF_LOADER.load('models/card_settings/trash_can/scene.gltf', resolve, undefined, reject));
CARD_SETTINGS.TRASH = gltf.scene;
CARD_SETTINGS.TRASH.material = new THREE.MeshStandardMaterial({color: 0xff3300, metalness: 0.7, roughness: 0.5, transparent: true, opacity: 0.8, side: THREE.DoubleSide});
CARD_SETTINGS.TRASH.meshes = [];
CARD_SETTINGS.TRASH.traverse(child => {
    if (child.isMesh) {
      child.material.map.dispose();
      child.material.dispose();
      child.material = CARD_SETTINGS.TRASH.material;
      CARD_SETTINGS.TRASH.meshes.push(child);
    }
});
CARD_SETTINGS.TRASH.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
CARD_SETTINGS.TRASH.INTERACTIVE_SPRITE.scale.set(8, 8, 1);
CARD_SETTINGS.TRASH.INTERACTIVE_SPRITE.position.set(0, 3.5, 0);
CARD_SETTINGS.TRASH.INTERACTIVE_SPRITE.rcID = RC_CARD_SETTINGS_TRASH;
CARD_SETTINGS.TRASH.add(CARD_SETTINGS.TRASH.INTERACTIVE_SPRITE);
CARD_SETTINGS.trashScale = 0.18;
CARD_SETTINGS.TRASH.rotation.x = 0.1;
CARD_SETTINGS.TRASH.rotation.y = -0.7;
CARD_SETTINGS.TRASH.position.set(CARD_SETTINGS.xOffset + 2.35, CARD_SETTINGS.yOffset - CARD_SETTINGS.trashScale, -CARD_PROPERTIES.depth/2);
CARD_SETTINGS.TRASH.scale.set(0, 0, 0);
CARD_SETTINGS.TRASH.material.opacity = 0.2;
CARD_SETTINGS.add(CARD_SETTINGS.TRASH);

CARD_SETTINGS.TRASH.onSettingsClick = () => {
    CARD_SETTINGS.TRASH.shown = !CARD_SETTINGS.TRASH.shown;
    gsap.to(CARD_SETTINGS.TRASH.scale, {
        x: CARD_SETTINGS.TRASH.shown ? CARD_SETTINGS.trashScale : 0,
        y: CARD_SETTINGS.TRASH.shown ? CARD_SETTINGS.trashScale : 0,
        z: CARD_SETTINGS.TRASH.shown ? CARD_SETTINGS.trashScale : 0,
        duration: CARD_SETTINGS.TRASH.shown ? 0.3 : 0.1,
        ease: CARD_SETTINGS.TRASH.shown ? 'bounce.out' : 'sine.out',
    });
};

CARD_SETTINGS.TRASH.onHover = (hovered) => {
    if (CARD_SETTINGS.TRASH.isHovered  === hovered) return;
    CARD_SETTINGS.TRASH.isHovered = hovered;
    gsap.to(CARD_SETTINGS.TRASH.meshes[0].rotation, { x: hovered ? 0.2 : 0, duration: 0.2, ease: 'sine.inOut' });
    gsap.to(CARD_SETTINGS.TRASH.meshes[3].rotation, { x: hovered ? 0.2 : 0, duration: 0.2, ease: 'sine.inOut' });
    gsap.to(CARD_SETTINGS.TRASH.meshes[1].rotation, { x: hovered ? -1 : 0, duration: 0.2, ease: 'sine.inOut' });
    gsap.to(CARD_SETTINGS.TRASH.meshes[2].rotation, { x: hovered ? -1 : 0, duration: 0.2, ease: 'sine.inOut' });
    gsap.to(CARD_SETTINGS.TRASH.meshes[1].position, { y: hovered ? 2 : 0, duration: 0.2, ease: 'sine.inOut' });
    gsap.to(CARD_SETTINGS.TRASH.meshes[2].position, { y: hovered ? 2 : 0, duration: 0.2, ease: 'sine.inOut' });
    gsap.to(CARD_SETTINGS.TRASH.material, { opacity: hovered ? 1 : 0.8, duration: 0.2, ease: 'sine.inOut' });
}

CARD_SETTINGS.TRASH.onClick = (node, dispatch) => {
    dispatch(deleteRequest({
        nodes: [node.id], links: [
            ...node.targets.map(target => target.link?.id).filter(Boolean),
            ...node.sources.map(source => source.link?.id).filter(Boolean)
        ]}));
    const changes = [];
    node.targets.forEach(target => {
        if (target.node.status === 2 
        && !target.node.sources.some(source => source.node !== node && source.node.status !== 3))
            changes.push({ id: target.node.id, status: 0 });
    });   
    dispatch(updateNodesRequest(changes));
}

CARD_SETTINGS.SHARE_BUTTON = new THREE.Group();
CARD_SETTINGS.SHARE_BUTTON.scale.set(0, 0, 0);
CARD_SETTINGS.SHARE_BUTTON.position.set(CARD_SETTINGS.xOffset + 1, CARD_SETTINGS.yOffset + 2, CARD_PROPERTIES.depth/2);
CARD_SETTINGS.SHARE_BUTTON.depth = CARD_PROPERTIES.padding / 2;
CARD_SETTINGS.SHARE_BUTTON.TEXT = new Text();
CARD_SETTINGS.SHARE_BUTTON.TEXT.position.set(0, 0, CARD_SETTINGS.SHARE_BUTTON.depth);
CARD_SETTINGS.SHARE_BUTTON.TEXT.text = "Share";
CARD_SETTINGS.SHARE_BUTTON.TEXT.color = 0x999999;
CARD_SETTINGS.SHARE_BUTTON.TEXT.fontSize = 0.5;
CARD_SETTINGS.SHARE_BUTTON.TEXT.font = 'fonts/Roboto-Regular.ttf';
CARD_SETTINGS.SHARE_BUTTON.TEXT.depthOffset = -0.5;
CARD_SETTINGS.SHARE_BUTTON.add(CARD_SETTINGS.SHARE_BUTTON.TEXT);
CARD_SETTINGS.SHARE_BUTTON.width = CARD_SETTINGS.SHARE_BUTTON.TEXT.text.length * CARD_SETTINGS.SHARE_BUTTON.TEXT.fontSize / 1.9 + CARD_PROPERTIES.padding * 2;
CARD_SETTINGS.SHARE_BUTTON.height = CARD_SETTINGS.SHARE_BUTTON.TEXT.fontSize + CARD_PROPERTIES.padding * 2;
CARD_SETTINGS.SHARE_BUTTON.BACKGROUND = new THREE.Mesh(
    new THREE.ExtrudeGeometry(
        new THREE.Shape()
            .moveTo(CARD_SETTINGS.SHARE_BUTTON.height / 2, CARD_SETTINGS.SHARE_BUTTON.height)
            .lineTo(CARD_SETTINGS.SHARE_BUTTON.width - CARD_SETTINGS.SHARE_BUTTON.height / 2, CARD_SETTINGS.SHARE_BUTTON.height)
            .quadraticCurveTo(CARD_SETTINGS.SHARE_BUTTON.width, CARD_SETTINGS.SHARE_BUTTON.height, CARD_SETTINGS.SHARE_BUTTON.width, CARD_SETTINGS.SHARE_BUTTON.height / 2)
            .quadraticCurveTo(CARD_SETTINGS.SHARE_BUTTON.width, 0, CARD_SETTINGS.SHARE_BUTTON.width - CARD_SETTINGS.SHARE_BUTTON.height / 2, 0)
            .lineTo(CARD_SETTINGS.SHARE_BUTTON.height / 2, 0)
            .quadraticCurveTo(0, 0, 0, CARD_SETTINGS.SHARE_BUTTON.height / 2)
            .quadraticCurveTo(0, CARD_SETTINGS.SHARE_BUTTON.height, CARD_SETTINGS.SHARE_BUTTON.height / 2, CARD_SETTINGS.SHARE_BUTTON.height),
        { depth: CARD_SETTINGS.SHARE_BUTTON.depth, bevelEnabled: false,}),
    new THREE.MeshStandardMaterial({color: '#404040', transparent: false,})
);
CARD_SETTINGS.SHARE_BUTTON.BACKGROUND.position.set(-CARD_PROPERTIES.padding, -CARD_SETTINGS.SHARE_BUTTON.height/2 - CARD_PROPERTIES.padding, 0);
CARD_SETTINGS.SHARE_BUTTON.BACKGROUND.rcID = RC_CARD_SETTINGS_SHARE;
CARD_SETTINGS.SHARE_BUTTON.add(CARD_SETTINGS.SHARE_BUTTON.BACKGROUND);
CARD_SETTINGS.add(CARD_SETTINGS.SHARE_BUTTON);

CARD_SETTINGS.SHARE_BUTTON.onSettingsClick = () => {
    CARD_SETTINGS.SHARE_BUTTON.shown = !CARD_SETTINGS.SHARE_BUTTON.shown;
        gsap.to(CARD_SETTINGS.SHARE_BUTTON.scale, {
        x: CARD_SETTINGS.SHARE_BUTTON.shown ? 1 : 0,
        y: CARD_SETTINGS.SHARE_BUTTON.shown ? 1 : 0,
        z: CARD_SETTINGS.SHARE_BUTTON.shown ? 1 : 0,
        duration: CARD_SETTINGS.SHARE_BUTTON.shown ? 0.2 : 0.1,
        delay: CARD_SETTINGS.SHARE_BUTTON.shown ?  0.1 : 0, 
        ease: CARD_SETTINGS.SHARE_BUTTON.shown ? 'bounce.out' : 'sine.out',
    });
}

CARD_SETTINGS.SHARE_BUTTON.onHover = (hovered) => {
  if (CARD_SETTINGS.SHARE_BUTTON.isHovered === hovered) return;
  CARD_SETTINGS.SHARE_BUTTON.isHovered = hovered;
  CARD_SETTINGS.SHARE_BUTTON.TEXT.font = hovered ? 'fonts/Roboto-Bold.ttf' : 'fonts/Roboto-Regular.ttf';
  gsap.to(CARD_SETTINGS.SHARE_BUTTON.BACKGROUND.material.color, {
        r: hovered ? 0.1 : 0.03,
        g: hovered ? 0.1 : 0.03,
        b: hovered ? 0.1 : 0.03,
        duration: 0.1,
        ease: 'sine.out',
    });
};

CARD_SETTINGS.COLLAPSE = new THREE.Group();
CARD_SETTINGS.COLLAPSE.scale.set(0, 0, 0);
CARD_SETTINGS.COLLAPSE.position.set(CARD_SETTINGS.xOffset -0.5, CARD_SETTINGS.yOffset + 2.2, CARD_PROPERTIES.depth/2);
CARD_SETTINGS.COLLAPSE.nodesMaterial = new THREE.MeshStandardMaterial({ color: 0x505050, });
CARD_SETTINGS.COLLAPSE.linksMaterial = new THREE.MeshStandardMaterial({ color: 0x404040, });
CARD_SETTINGS.COLLAPSE.mainNode = new THREE.Mesh(new THREE.SphereGeometry(0.3), CARD_SETTINGS.COLLAPSE.nodesMaterial);
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.mainNode);
CARD_SETTINGS.COLLAPSE.node1 = new THREE.Mesh(new THREE.SphereGeometry(0.15), CARD_SETTINGS.COLLAPSE.nodesMaterial);
CARD_SETTINGS.COLLAPSE.node1.position.set(-0.7, -0.7, 0);
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node1);
CARD_SETTINGS.COLLAPSE.node1link = new THREE.Mesh(new THREE.CylinderGeometry(0.03, 0.03, 0.5, 5), CARD_SETTINGS.COLLAPSE.linksMaterial);
CARD_SETTINGS.COLLAPSE.node1link.position.set(-0.45, -0.45, 0);
CARD_SETTINGS.COLLAPSE.node1link.rotation.z = -0.78;
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node1link);
CARD_SETTINGS.COLLAPSE.node1linkCone = new THREE.Mesh(new THREE.ConeGeometry(0.07, 0.1), CARD_SETTINGS.COLLAPSE.linksMaterial);
CARD_SETTINGS.COLLAPSE.node1linkCone.position.set(-0.25, -0.25, 0);
CARD_SETTINGS.COLLAPSE.node1linkCone.rotation.z = -0.78;
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node1linkCone);
CARD_SETTINGS.COLLAPSE.node2 = new THREE.Mesh(new THREE.SphereGeometry(0.15), CARD_SETTINGS.COLLAPSE.nodesMaterial);
CARD_SETTINGS.COLLAPSE.node2.position.set(0, -0.8, 0);
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node2);
CARD_SETTINGS.COLLAPSE.node2link = new THREE.Mesh(new THREE.CylinderGeometry(0.03, 0.03, 0.4, 5), CARD_SETTINGS.COLLAPSE.linksMaterial);
CARD_SETTINGS.COLLAPSE.node2link.position.set(0, -0.6, 0);
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node2link);
CARD_SETTINGS.COLLAPSE.node2linkCone = new THREE.Mesh(new THREE.ConeGeometry(0.07, 0.1), CARD_SETTINGS.COLLAPSE.linksMaterial);
CARD_SETTINGS.COLLAPSE.node2linkCone.position.set(0, -0.35, 0);
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node2linkCone);
CARD_SETTINGS.COLLAPSE.node3 = new THREE.Mesh(new THREE.SphereGeometry(0.15), CARD_SETTINGS.COLLAPSE.nodesMaterial);
CARD_SETTINGS.COLLAPSE.node3.position.set(0.7, -0.7, 0);
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node3);
CARD_SETTINGS.COLLAPSE.node3link = new THREE.Mesh(new THREE.CylinderGeometry(0.03, 0.03, 0.5, 5), CARD_SETTINGS.COLLAPSE.linksMaterial);
CARD_SETTINGS.COLLAPSE.node3link.position.set(0.45, -0.45, 0);
CARD_SETTINGS.COLLAPSE.node3link.rotation.z = 0.78;
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node3link);
CARD_SETTINGS.COLLAPSE.node3linkCone = new THREE.Mesh(new THREE.ConeGeometry(0.07, 0.1), CARD_SETTINGS.COLLAPSE.linksMaterial);
CARD_SETTINGS.COLLAPSE.node3linkCone.position.set(0.25, -0.25, 0);
CARD_SETTINGS.COLLAPSE.node3linkCone.rotation.z = 0.78;
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.node3linkCone);
CARD_SETTINGS.COLLAPSE.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
CARD_SETTINGS.COLLAPSE.INTERACTIVE_SPRITE.scale.set(1.7, 1.3, 1);
CARD_SETTINGS.COLLAPSE.INTERACTIVE_SPRITE.position.set(0, -0.3, 0);
CARD_SETTINGS.COLLAPSE.INTERACTIVE_SPRITE.rcID = RC_CARD_SETTINGS_COLLAPSE;
CARD_SETTINGS.COLLAPSE.add(CARD_SETTINGS.COLLAPSE.INTERACTIVE_SPRITE);
CARD_SETTINGS.add(CARD_SETTINGS.COLLAPSE);

CARD_SETTINGS.COLLAPSE.onSettingsClick = () => {
  CARD_SETTINGS.COLLAPSE.shown = !CARD_SETTINGS.COLLAPSE.shown;
  gsap.to(CARD_SETTINGS.COLLAPSE.scale, {
    x: CARD_SETTINGS.COLLAPSE.shown ? 1 : 0,
    y: CARD_SETTINGS.COLLAPSE.shown ? 1 : 0,
    z: CARD_SETTINGS.COLLAPSE.shown ? 1 : 0,
    duration: CARD_SETTINGS.COLLAPSE.shown ? 0.2 : 0.1,
    delay: CARD_SETTINGS.COLLAPSE.shown ?  0.2 : 0, 
    ease: CARD_SETTINGS.COLLAPSE.shown ? 'bounce.out' : 'sine.out',
  });
}

CARD_SETTINGS.COLLAPSE.onHover = (hovered, collapsed) => {
  if (CARD_SETTINGS.COLLAPSE.isHovered === hovered) return;
  CARD_SETTINGS.COLLAPSE.isHovered = hovered;
  if (collapsed) {

  } else {
    gsap.to(CARD_SETTINGS.COLLAPSE.mainNode.scale, { x: hovered ? 1.2 : 1, y: hovered ? 1.2 : 1, z: hovered ? 1.2 : 1, duration: 0.2, ease: 'bounce.out', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node1.position, { x: hovered ? 0 : -0.7, y: hovered ? 0 : -0.7, duration: 0.2, delay: 0.05, ease: 'sine.inOut', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node1link.position, { x: hovered ? 0 : -0.45, y: hovered ? 0 : -0.45, duration: 0.2, delay: 0.05, ease: 'sine.inOut', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node1linkCone.position, { x: hovered ? 0 : -0.25, y: hovered ? 0 : -0.25, duration: 0.2, delay: hovered ? 0.15 : 0, ease: 'sine.inOut', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node2.position, { y: hovered ? 0 : -0.8, duration: 0.2, delay: 0.1, ease: 'sine.inOut', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node2link.position, { y: hovered ? 0 : -0.6, duration: 0.2, delay: 0.1, ease: 'sine.inOut', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node2linkCone.position, { y: hovered ? 0 : -0.35, duration: 0.2, delay: hovered ? 0.2 : 0, ease: 'sine.inOut', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node3.position, { x: hovered ? 0 : 0.7, y: hovered ? 0 : -0.7, duration: 0.2, ease: 'sine.inOut', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node3link.position, { x: hovered ? 0 : 0.45, y: hovered ? 0 : -0.45, duration: 0.2, ease: 'sine.inOut', });
    gsap.to(CARD_SETTINGS.COLLAPSE.node3linkCone.position, { x: hovered ? 0 : 0.25, y: hovered ? 0 : -0.25, duration: 0.2, delay: hovered ?  0.1 : 0, ease: 'sine.inOut', });
  }
};

export const CARD_PIN = new THREE.Group();
gltf = await new Promise((resolve, reject) => GLTF_LOADER.load('models/card_pin/scene.gltf', resolve, undefined, reject));
CARD_PIN.xOffset = -CARD_PROPERTIES.height/2 - 0.2;
CARD_PIN.yOffset = CARD_PROPERTIES.height/2 + 0.2;
CARD_PIN.PIN = gltf.scene;
CARD_PIN.PIN.traverse(child => {if (child.isMesh && child.material) child.material.metalness = 0.9});
CARD_PIN.PIN.scale.set(0, 0, 0);
CARD_PIN.PIN.rotation.set(0, 0, 1);
CARD_PIN.PIN.position.set(CARD_PIN.xOffset, CARD_PIN.yOffset, CARD_PROPERTIES.depth/2 + 0.5);
CARD_PIN.add(CARD_PIN.PIN);
CARD_PIN.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
CARD_PIN.INTERACTIVE_SPRITE.scale.set(2, 2, 1);
CARD_PIN.INTERACTIVE_SPRITE.position.set(CARD_PIN.xOffset, CARD_PIN.yOffset, CARD_PROPERTIES.depth/2);
CARD_PIN.INTERACTIVE_SPRITE.rcID = RC_CARD_PIN;
CARD_PIN.add(CARD_PIN.INTERACTIVE_SPRITE);

CARD_PIN.onCardHover = (instanceId, scale, pinned) => {
    if (CARD_PIN.instanceId === instanceId) return;
    CARD_PIN.instanceId = instanceId;
    
    if (instanceId === null) {
        gsap.to(CARD_PIN.PIN.scale, { x: 0, y: 0, z: 0 , duration: 0.2, ease: 'sine.inOut', });
        return;
    }
    CARD_PIN.scale.set(scale, scale, scale);
    CARD_PIN.PIN.rotation.set(pinned ? 0 : 0, pinned ? 0 : 0, pinned ? 0 : 1);
    gsap.fromTo(CARD_PIN.PIN.scale, { x: 0, y: 0, z: 0}, { x: 70, y: 70, z: 70, duration: 0.2, ease: 'sine.inOut', });
}

CARD_PIN.animate = (position, quaternion) => {
    if (CARD_PIN.instanceId || CARD_PIN.instanceId === 0) {
        CARD_PIN.position.copy(position);
        CARD_PIN.quaternion.copy(quaternion);
    }
}

CARD_PIN.onHover = (hovered, pinned) => {
    if (CARD_PIN.isHovered === hovered) return;
    CARD_PIN.isHovered = hovered;
    CARD_PIN.pinned = pinned;

    gsap.to(CARD_PIN.PIN.position, {
        x: hovered ? CARD_PIN.xOffset + 0.1 : (pinned ? CARD_PIN.xOffset : CARD_PIN.xOffset),
        y: hovered ? CARD_PIN.yOffset - 0.1 : (pinned ? CARD_PIN.yOffset : CARD_PIN.yOffset),
        duration: 0.2,
        ease: 'sine.inOut', 
    });
    gsap.to(CARD_PIN.PIN.rotation, {
        x: hovered ? 0.3 : (pinned ? 0 : 0),
        y: hovered ? 0.3 : (pinned ? 0 : 0),
        duration: 0.2,
        ease: 'sine.inOut',
    });

    document.body.style.cursor = hovered ? 'pointer' : 'default';
}

CARD_PIN.onClick = (dispatch) => {
    gsap.to(CARD_PIN.PIN.position, {
        x: CARD_PIN.pinned ? CARD_PIN.xOffset : CARD_PIN.xOffset + 0.4,
        y: CARD_PIN.pinned ? CARD_PIN.yOffset : CARD_PIN.yOffset - 0.4,
        z: CARD_PIN.pinned ? CARD_PROPERTIES.depth/2 + 0.5 : CARD_PROPERTIES.depth/2,
        duration: 0.2,
        ease: 'sine.inOut', 
    });
    gsap.to(CARD_PIN.PIN.rotation, {
        x: CARD_PIN.pinned ? 0.3 : 0.7,
        y: CARD_PIN.pinned ? 0.3 : 0.7,
        duration: 0.2,
        ease: 'sine.inOut', 
    });
}



export const LINK_SETTINGS = new THREE.Group();
LINK_SETTINGS.scale.set(0, 0, 0);
LINK_SETTINGS.material = new THREE.MeshStandardMaterial({metalness: 0.7, roughness: 0.3, transparent: true, opacity: 0.5, alphaTest: 0.01,});
gltf = await new Promise((resolve, reject) => GLTF_LOADER.load('models/link_settings/scene.gltf', resolve, undefined, reject));
LINK_SETTINGS.GEAR = gltf.scene;
LINK_SETTINGS.GEAR.traverse(child => {
    if(child.isMesh) {
        child.material.dispose();
        child.material = LINK_SETTINGS.material;
    }
});
LINK_SETTINGS.GEAR.scale.set(0.05, 0.05, 0.05);
LINK_SETTINGS.GEAR.rotation.y = Math.PI;
LINK_SETTINGS.GEAR.position.set(0, 0, -2.5);
LINK_SETTINGS.GEAR.renderOrder = -2;
LINK_SETTINGS.add(LINK_SETTINGS.GEAR);
LINK_SETTINGS.rotationSpeed = 0.002;
LINK_SETTINGS.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
LINK_SETTINGS.INTERACTIVE_SPRITE.scale.set(5, 5, 1);
LINK_SETTINGS.INTERACTIVE_SPRITE.rcID = RC_LINK_SETTINGS;
LINK_SETTINGS.add(LINK_SETTINGS.INTERACTIVE_SPRITE);

LINK_SETTINGS.onHover = (hovered) => {
    if (LINK_SETTINGS.isHovered === hovered) return;
    LINK_SETTINGS.isHovered = hovered;
    gsap.to(LINK_SETTINGS.material, { opacity: hovered || LINK_SETTINGS.pinned ? 1 : 0.25, duration: 0.2, ease: 'sine.inOut', });
    LINK_SETTINGS.rotationSpeed = hovered || LINK_SETTINGS.pinned ? -0.008 : 0.002;
    document.body.style.cursor = hovered ? 'pointer' : 'default';
}
LINK_SETTINGS.animate = () => {LINK_SETTINGS.GEAR.rotation.z += LINK_SETTINGS.rotationSpeed};

gltf = await new Promise((resolve, reject) => GLTF_LOADER.load('models/link_settings/scissors/scene.gltf', resolve, undefined, reject));
LINK_SETTINGS.SCISSORS = gltf.scene;
LINK_SETTINGS.SCISSORS.mashes = [];
LINK_SETTINGS.SCISSORS.traverse(child => {if(child.isMesh) LINK_SETTINGS.SCISSORS.mashes.push(child)});
LINK_SETTINGS.SCISSORS.openAngle = Math.PI/6;
LINK_SETTINGS.SCISSORS.halfOpenAngle = Math.PI/12;
LINK_SETTINGS.SCISSORS.mashes[0].rotation.z = -LINK_SETTINGS.SCISSORS.openAngle;
LINK_SETTINGS.SCISSORS.mashes[1].rotation.z = LINK_SETTINGS.SCISSORS.openAngle;
LINK_SETTINGS.SCISSORS.mashes[0].material.transparent = LINK_SETTINGS.SCISSORS.mashes[1].material.transparent = true;
LINK_SETTINGS.SCISSORS.mashes[0].material.opacity = LINK_SETTINGS.SCISSORS.mashes[1].material.opacity = 0.8;
LINK_SETTINGS.SCISSORS.rotation.y = Math.PI * 2/3;
LINK_SETTINGS.SCISSORS.rotation.z = Math.PI / 2;
LINK_SETTINGS.SCISSORS.position.set(2, 0, -12);
LINK_SETTINGS.SCISSORS.renderOrder = -2;
LINK_SETTINGS.add(gltf.scene);
LINK_SETTINGS.SCISSORS.scale.set(0, 0, 0);
LINK_SETTINGS.SCISSORS.INTERACTIVE_SPRITE = new THREE.Sprite(new THREE.SpriteMaterial({ alphaTest: 1, transparent: true, opacity: 0.1, }));
LINK_SETTINGS.SCISSORS.INTERACTIVE_SPRITE.scale.set(0.1, 0.1, 1);
LINK_SETTINGS.SCISSORS.INTERACTIVE_SPRITE.rcID = RC_LINK_SETTINGS_SCISSORS;
LINK_SETTINGS.SCISSORS.add(LINK_SETTINGS.SCISSORS.INTERACTIVE_SPRITE);
LINK_SETTINGS.add(LINK_SETTINGS.SCISSORS);

LINK_SETTINGS.SCISSORS.onHover = (hovered) => {
    if (LINK_SETTINGS.SCISSORS === hovered) return;
    LINK_SETTINGS.SCISSORS.isHovered = hovered;
    gsap.to(LINK_SETTINGS.SCISSORS.mashes[0].rotation, { z: hovered ? -LINK_SETTINGS.SCISSORS.halfOpenAngle : -LINK_SETTINGS.SCISSORS.openAngle, duration: 0.2, ease: 'sine.inOut', });
    gsap.to(LINK_SETTINGS.SCISSORS.mashes[1].rotation, { z: hovered ? LINK_SETTINGS.SCISSORS.halfOpenAngle : LINK_SETTINGS.SCISSORS.openAngle, duration: 0.2, ease: 'sine.inOut', });
};

LINK_SETTINGS.SCISSORS.onClick = (dispatch) => {
    const duration = 0.2;
    gsap.to(LINK_SETTINGS.SCISSORS.mashes[0].rotation, { z: 0, duration, ease: 'sine.inOut', });
    gsap.to(LINK_SETTINGS.SCISSORS.mashes[1].rotation, { z: 0, duration, ease: 'sine.inOut', });
    setTimeout(() => {
        dispatch(deleteRequest({links: [LINK_SETTINGS.link.id]}))
        if (LINK_SETTINGS.link.target.status === 2) {
            const isBlocked = LINK_SETTINGS.link.childs.some(child => child !== LINK_SETTINGS.link.source && child.status !== 3);
            if (!isBlocked) {
                dispatch(updateNodesRequest([{ id: LINK_SETTINGS.link.target.id, status: 0 }]));
            }
        }
    }, duration*1000);
};

LINK_SETTINGS.STATUS_MATERIALS = [];
LINK_STATUS_COLORS.forEach(color => {
    const canvas = document.createElement('canvas');
    const size = 256;
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(size / 2, size / 2, size / 2 - 20, 0, Math.PI * 2);
    ctx.fillStyle = `${color.slice(0,7)}33`;
    ctx.fill();
    ctx.beginPath();
    ctx.arc(size / 2, size / 2, size / 2 - 10, 0, Math.PI * 2);
    ctx.lineWidth = 20;
    ctx.strokeStyle = `${color.slice(0,7)}66`; 
    ctx.stroke();
    LINK_SETTINGS.STATUS_MATERIALS.push(new THREE.MeshBasicMaterial({
    map: new THREE.CanvasTexture(canvas),
    transparent: true,
    polygonOffset: true,
    polygonOffsetFactor: -10,
    polygonOffsetUnits: -50,
    opacity: 0.5,
    alphaTest: 0.06,
    side: THREE.DoubleSide,
    }));
});

LINK_SETTINGS.CHANGE_TYPE_BUTTON = new THREE.Group();
LINK_SETTINGS.CHANGE_TYPE_BUTTON.scale.set(0, 0, 0);
LINK_SETTINGS.CHANGE_TYPE_BUTTON.position.set(0, 0, -6);
LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND = new THREE.Mesh(new THREE.PlaneGeometry(5, 5), LINK_SETTINGS.STATUS_MATERIALS[LINK_STATUS_COLORS.length-1], );
LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND.renderOrder = -1;
LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND.rcID = RC_LINK_SETTINGS_CHANGE_TYPE_BUTTON;
LINK_SETTINGS.CHANGE_TYPE_BUTTON.add(LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND);

LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT = new Text();
LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.renderOrder = -2;
LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.color = 0xffffff;
LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.fillOpacity = 0.5;
LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.font = 'fonts/Roboto-Bold.ttf'; // URL до шрифту
LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.fontSize = 3; // Розмір тексту
LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.position.set(-LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.fontSize / 1.8, LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.fontSize / 2.2, 0);
LINK_SETTINGS.CHANGE_TYPE_BUTTON.add(LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT);

LINK_SETTINGS.add(LINK_SETTINGS.CHANGE_TYPE_BUTTON);

LINK_SETTINGS.CHANGE_TYPE_BUTTON.onHover = (hovered) => {
    if (LINK_SETTINGS.CHANGE_TYPE_BUTTON.isHovered === hovered) return;
    LINK_SETTINGS.CHANGE_TYPE_BUTTON.isHovered = hovered;
    gsap.to(LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND.material, { opacity: hovered ? 1 : 0.5, duration: 0.1, ease: 'sine.inOut', });
    gsap.to(LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT, { fillOpacity: hovered ? 1 : 0.5, duration: 0.1, ease: 'sine.inOut', });
    if (!LINK_SETTINGS.link) return;
    const type = hovered ? !LINK_SETTINGS.link.type : LINK_SETTINGS.link.type;
    const color = new THREE.Color(type ? LINK_STATUS_COLORS[LINK_STATUS_COLORS.length - 1] : LINK_STATUS_COLORS[LINK_SETTINGS.link.source.status]);
    
    if (!LINK_SETTINGS.link.__lineObj.material.isUnque) {
        LINK_SETTINGS.link.__lineObj.material = LINK_SETTINGS.link.__lineObj.material.clone();
        LINK_SETTINGS.link.__lineObj.material.isUnque = true;
    }
    gsap.to(LINK_SETTINGS.link.__lineObj.material.color, {r: color.r, g: color.g, b: color.b, duration: 0.1, ease: 'sine.inOut', });
};

LINK_SETTINGS.REVERSE_BUTTON = new THREE.Group();
LINK_SETTINGS.REVERSE_BUTTON.scale.set(0, 0, 0);
LINK_SETTINGS.REVERSE_BUTTON.position.set(0, 0, 6);
LINK_SETTINGS.REVERSE_BUTTON.BACKGROUND = LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND.clone();
LINK_SETTINGS.REVERSE_BUTTON.BACKGROUND.rcID = RC_LINK_SETTINGS_REVERSE_BUTTON;
LINK_SETTINGS.REVERSE_BUTTON.add(LINK_SETTINGS.REVERSE_BUTTON.BACKGROUND);
TEXTURE_LOADER.load('svg/link_reverse.svg', texture => {
    LINK_SETTINGS.REVERSE_BUTTON.ICON = new THREE.Mesh(new THREE.PlaneGeometry(3, 3), new THREE.MeshBasicMaterial({map: texture, transparent: true, opacity: 0.5}));
    LINK_SETTINGS.REVERSE_BUTTON.ICON.renderOrder = -2;
    LINK_SETTINGS.REVERSE_BUTTON.add(LINK_SETTINGS.REVERSE_BUTTON.ICON);
});
LINK_SETTINGS.add(LINK_SETTINGS.REVERSE_BUTTON);

LINK_SETTINGS.REVERSE_BUTTON.onHover = (hovered) => {
    if (LINK_SETTINGS.REVERSE_BUTTON.isHovered === hovered) return;
    LINK_SETTINGS.REVERSE_BUTTON.isHovered = hovered;
    gsap.to(LINK_SETTINGS.REVERSE_BUTTON.BACKGROUND.material, { opacity: hovered ? 1 : 0.5, duration: 0.1, ease: 'sine.inOut', });
    gsap.to(LINK_SETTINGS.REVERSE_BUTTON.ICON?.material, { opacity: hovered ? 1 : 0.5, duration: 0.1, ease: 'sine.inOut', });
    if (!LINK_SETTINGS.link || !LINK_SETTINGS.pinned) return;
    console.log('swapping', LINK_SETTINGS);
    [LINK_SETTINGS.link.source, LINK_SETTINGS.link.target] = [LINK_SETTINGS.link.target, LINK_SETTINGS.link.source];
};

LINK_SETTINGS.NEW_NODE_BUTTON = new THREE.Group();
LINK_SETTINGS.NEW_NODE_BUTTON.scale.set(0, 0, 0);
LINK_SETTINGS.NEW_NODE_BUTTON.position.set(0, 0, 13);
LINK_SETTINGS.NEW_NODE_BUTTON.BACKGROUND = LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND.clone();
LINK_SETTINGS.NEW_NODE_BUTTON.BACKGROUND.rcID = RC_LINK_SETTINGS_NEW_NODE_BUTTON;
LINK_SETTINGS.NEW_NODE_BUTTON.add(LINK_SETTINGS.NEW_NODE_BUTTON.BACKGROUND);

LINK_SETTINGS.NEW_NODE_BUTTON.TEXT = LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.clone();
LINK_SETTINGS.NEW_NODE_BUTTON.TEXT.text = "+";
LINK_SETTINGS.NEW_NODE_BUTTON.TEXT.fillOpacity = 0.5;
LINK_SETTINGS.NEW_NODE_BUTTON.add(LINK_SETTINGS.NEW_NODE_BUTTON.TEXT);
LINK_SETTINGS.NEW_NODE_BUTTON.TEXT.position.set(-LINK_SETTINGS.NEW_NODE_BUTTON.TEXT.fontSize / 3.5, LINK_SETTINGS.NEW_NODE_BUTTON.TEXT.fontSize / 2.2, 0);
LINK_SETTINGS.add(LINK_SETTINGS.NEW_NODE_BUTTON);
LINK_SETTINGS.NEW_NODE_BUTTON.onHover = (hovered) => {
    if (LINK_SETTINGS.NEW_NODE_BUTTON.isHovered === hovered) return;
    LINK_SETTINGS.NEW_NODE_BUTTON.isHovered = hovered;
    gsap.to(LINK_SETTINGS.NEW_NODE_BUTTON.BACKGROUND.material, { opacity: hovered ? 1 : 0.5, duration: 0.1, ease: 'sine.inOut', });
    gsap.to(LINK_SETTINGS.NEW_NODE_BUTTON.TEXT, { fillOpacity: hovered ? 1 : 0.5, duration: 0.1, ease: 'sine.inOut', });
};

LINK_SETTINGS.onClick = (hovered, quaternion) => {
    LINK_SETTINGS.pinned = hovered ? !LINK_SETTINGS.pinned : false;
    if (hovered) {
        LINK_SETTINGS.NEW_NODE_BUTTON.BACKGROUND.material.dispose();
        LINK_SETTINGS.NEW_NODE_BUTTON.BACKGROUND.material = LINK_SETTINGS.STATUS_MATERIALS[LINK_SETTINGS.link.type ? LINK_SETTINGS.STATUS_MATERIALS.length-1 : LINK_SETTINGS.link.source.status].clone();
        LINK_SETTINGS.REVERSE_BUTTON.BACKGROUND.material.dispose();
        LINK_SETTINGS.REVERSE_BUTTON.BACKGROUND.material = LINK_SETTINGS.STATUS_MATERIALS[LINK_SETTINGS.link.type ? LINK_SETTINGS.STATUS_MATERIALS.length-1 : LINK_SETTINGS.link.source.status].clone();
        LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND.material.dispose();
        LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND.material = LINK_SETTINGS.STATUS_MATERIALS[LINK_SETTINGS.link.type ? LINK_SETTINGS.link.source.status : LINK_SETTINGS.STATUS_MATERIALS.length-1].clone();
        LINK_SETTINGS.CHANGE_TYPE_BUTTON.TEXT.text = LINK_SETTINGS.link.type ? "Pr" : "Sc";
        const buttonsQuaternion = new THREE.Quaternion().multiplyQuaternions(LINK_SETTINGS.quaternion.clone().invert(), quaternion);
        const linkDirection = new THREE.Vector3().subVectors(LINK_SETTINGS.link.target, LINK_SETTINGS.link.source).normalize();
        LINK_SETTINGS.CHANGE_TYPE_BUTTON.quaternion.copy(buttonsQuaternion);
        LINK_SETTINGS.REVERSE_BUTTON.quaternion.copy(buttonsQuaternion).multiply(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), Math.atan2(linkDirection.y, linkDirection.x)));
        LINK_SETTINGS.NEW_NODE_BUTTON.quaternion.copy(buttonsQuaternion);
    } else if (!LINK_SETTINGS.pinned) {
        gsap.to(LINK_SETTINGS.material, { opacity: 0.25, duration: 0.2, ease: 'sine.inOut', });
        LINK_SETTINGS.rotationSpeed = -0.008;
    }
    gsap.to(LINK_SETTINGS.NEW_NODE_BUTTON.scale, {
        x: LINK_SETTINGS.pinned ? 1 : 0,
        y: LINK_SETTINGS.pinned ? 1 : 0,
        z: LINK_SETTINGS.pinned ? 1 : 0,
        duration: LINK_SETTINGS.pinned ? 0.4 : 0.2, ease: LINK_SETTINGS.pinned ? 'bounce.out' : 'sine.out',
    });
    gsap.to(LINK_SETTINGS.REVERSE_BUTTON.scale, {
        x: LINK_SETTINGS.pinned ? 1 : 0,
        y: LINK_SETTINGS.pinned ? 1 : 0,
        z: LINK_SETTINGS.pinned ? 1 : 0,
        duration: LINK_SETTINGS.pinned ? 0.4 : 0.2, delay: LINK_SETTINGS.pinned ? 0.1 : 0, ease: LINK_SETTINGS.pinned ? 'bounce.out' : 'sine.out',
    });
    gsap.to(LINK_SETTINGS.CHANGE_TYPE_BUTTON.scale, {
        x: LINK_SETTINGS.pinned ? 1 : 0,
        y: LINK_SETTINGS.pinned ? 1 : 0,
        z: LINK_SETTINGS.pinned ? 1 : 0,
        duration: LINK_SETTINGS.pinned ? 0.4 : 0.2, delay: LINK_SETTINGS.pinned ? 0.2 : 0, ease: LINK_SETTINGS.pinned ? 'bounce.out' : 'sine.out',
    });
    gsap.to(LINK_SETTINGS.SCISSORS.scale, {
        x: LINK_SETTINGS.pinned ? 50 : 0,
        y: LINK_SETTINGS.pinned ? 50 : 0,
        z: LINK_SETTINGS.pinned ? 50 : 0,
        duration: LINK_SETTINGS.pinned ? 0.2 : 0.2, delay: LINK_SETTINGS.pinned ? 0.3 : (LINK_SETTINGS.SCISSORS.isHovered ? 0.18 : 0), ease: 'sine.out',
    });
};

LINK_SETTINGS.hideAll = () => {
    if (LINK_SETTINGS.hidden) return;
    LINK_SETTINGS.hidden = true;
    gsap.to(LINK_SETTINGS.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
    gsap.to(LINK_SETTINGS.SCISSORS.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
    gsap.to(LINK_SETTINGS.NEW_NODE_BUTTON.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
    gsap.to(LINK_SETTINGS.REVERSE_BUTTON.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
    gsap.to(LINK_SETTINGS.CHANGE_TYPE_BUTTON.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
    LINK_SETTINGS.pinned = false;
}

LINK_SETTINGS.onMouseMove = (links, raycaster, ) => {
    let minDistance = Infinity;
    let closestPoint = null;
    let scale = 0;
    links.forEach(link => {
        const sourcePos = link.source.__threeObj?.position;
        const targetPos = link.target.__threeObj?.position;
        if (!sourcePos || !targetPos || (LINK_SETTINGS.pinned && LINK_SETTINGS.link !== link)) return;
        const closestPointOnLink = new THREE.Vector3();
        const distance = Math.sqrt(raycaster.ray.distanceSqToSegment(sourcePos, targetPos, null, closestPointOnLink));
        if (LINK_SETTINGS.pinned && distance > 100) {
            LINK_SETTINGS.pinned = false;
            gsap.to(LINK_SETTINGS.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
            gsap.to(LINK_SETTINGS.material, { opacity: 0.25, duration: 0.2, ease: 'sine.inOut', });
            gsap.to(LINK_SETTINGS.SCISSORS.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
            gsap.to(LINK_SETTINGS.NEW_NODE_BUTTON.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
            gsap.to(LINK_SETTINGS.REVERSE_BUTTON.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
            gsap.to(LINK_SETTINGS.CHANGE_TYPE_BUTTON.scale, { x: 0, y: 0, z: 0, duration: 0.1, ease: 'sine.inOut' });
        }
        if (!LINK_SETTINGS.pinned && distance < minDistance && distance <= 70) {
            closestPoint = closestPointOnLink;
            const linkLength = sourcePos.distanceTo(targetPos);
            const distanceToSource = closestPoint.distanceTo(sourcePos);
            const relativePosition = distanceToSource / linkLength;
            if (relativePosition < 0.3) closestPoint.lerpVectors(sourcePos, targetPos, 0.3);
            else if (relativePosition > 0.7) closestPoint.lerpVectors(sourcePos, targetPos, 0.7);
            minDistance = distance;
            if (LINK_SETTINGS.link !== link || LINK_SETTINGS.hidden) {
                LINK_SETTINGS.hidden = false;
                LINK_SETTINGS.link = link;
                scale = Math.log((link.source.weight || 4) + 1);
                LINK_SETTINGS.scale.set(scale, scale, scale);
                LINK_SETTINGS.material.color.set(LINK_SETTINGS.link.type ? 0x777777 : new THREE.Color(LINK_STATUS_COLORS[LINK_SETTINGS.link.source.status]));
                LINK_SETTINGS.quaternion.copy(
                    new THREE.Quaternion().setFromUnitVectors(
                    new THREE.Vector3(0, 0, 1),
                    new THREE.Vector3().subVectors(LINK_SETTINGS.link.target, LINK_SETTINGS.link.source).normalize(),
                ));
            }
        } 
    });
    if (closestPoint) {
        if (!LINK_SETTINGS.pinned) {
            LINK_SETTINGS.position.set(closestPoint.x, closestPoint.y, closestPoint.z);
        }
    }
}