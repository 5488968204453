const initialState = {
    token: null,
    isAuthenticated: false,
    error: null,
  };
  
  export const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return { ...state, token: action.payload, isAuthenticated: true, error: null };
      case 'LOGIN_FAILURE':
        return { ...state, error: action.payload, isAuthenticated: false };
      case 'LOGOUT':
        return { ...state, token: null, isAuthenticated: false };
      default:
        return state;
    }
  };
  
  // Екшени для авторизації
export const requestLogin = (credentials) => {
  return {
    type: 'REQUEST_LOGIN',
    payload: credentials,
  }
  };
  
  export const loginSuccess = (token) => ({
    type: 'LOGIN_SUCCESS',
    payload: token,
  });
  
  export const loginFailure = (error) => ({
    type: 'LOGIN_FAILURE',
    payload: error,
  });
  
  export const logout = () => {
    localStorage.removeItem('token');
    return { type: 'LOGOUT' };
  };
  
  export const checkAuthentication = () => {
    const token = localStorage.getItem('token');
    if (token) {
      return { type: 'LOGIN_SUCCESS', payload: token };
    } else {
      return { type: 'LOGOUT' };
    }
  };
  