import React, { useRef, useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ForceGraph3D from 'react-force-graph-3d';
import * as THREE from 'three';
import { updateCoordinatesRequest } from '../store/graphSlice';
import { createGradientTexture } from 'utils/Helpers';
import { gsap } from 'gsap';
import { v4 as uuidv4 } from 'uuid';
import { Text } from 'troika-three-text';
import CardPage from './layouts/CardPage';
import {
  turnOffLoading,
  turnOnLoading,
  addNodeRequest,
  updateLinkRequest,
  deleteRequest,
  updateNodesRequest,
  addLinkRequest
} from 'store/graphSlice';
import { 
  LINK_STATUS_COLORS,
  NODE_STATUS_COLORS,
  SHOW_STARS,
  USE_AUDIO,
  STATUS_COLORS,
  CARD_PROPERTIES,
  TITLE_MAX_CHARS,
  UNACTIVE_THRESHOLD,
  GLTF_LOADER,
  STARS_VERTEX_SHADER,
  STARS_FRAGMENT_SHADER,
  RC_CLASS_MOD,
  RC_SUB_CLASS_MOD,
  RC_CARD,
  RC_CARD_SETTINGS,
  RC_CARD_SETTINGS_TRASH,
  RC_LINK_SETTINGS,
  RC_LINK_SETTINGS_SCISSORS,
  RC_CARD_VOLUME,
  RC_CARD_STATUS,
  RC_CARD_CORE,
  RC_CARD_CONNECTIONS_SUPER,
  RC_CARD_CONNECTIONS_SUB,
  RC_LINK_SETTINGS_CHANGE_TYPE_BUTTON,
  RC_LINK_SETTINGS_REVERSE_BUTTON,
  RC_LINK_SETTINGS_NEW_NODE_BUTTON,
  RC_CARD_SETTINGS_SHARE,
  RC_CARD_SETTINGS_COLLAPSE,
  TEXTURE_LOADER,
  RC_CARD_PIN,
  RC_CARD_SUBTASK_BUTTON,
  RC_CORE,
} from 'utils/Constants'
import {
  SNOISE_MATERIAL,
  CARD_GEOMETRY,
  CARD_MATERIAL,
  SUBTASK_BUTTON,
  NEW_CONNECTION_BUTTONS,
  CARD_STATUS,
  CARD_VOLUME,
  NEW_LINK,
  CARD_SETTINGS,
  LINK_SETTINGS,
  CARD_PIN,
} from 'utils/UIModels'

const Graph3D = ({
  data,
  needSimulation,
  fgRef,
  focusOnNode,
  focusNodeId,
  getConnected,
  showAll,
}) => {
  const dispatch = useDispatch();

  let tickCount = 0;
  const firstTime = useRef(true);
  
  const lastActive = useRef(Date.now());
  const flyControlsRef = useRef();
  const attributesRef = useRef();
  const raycasterRef = useRef(new THREE.Raycaster());

  const snoisesRef = useRef();
  const cardsRef = useRef();
  const titlesRef = useRef();

  const interactableObjectsRef = useRef();

  const commonQuaternionRef = useRef();

  const [graphData, setGraphData] = useState();
  const graphDataRef = useRef();

  const blockAllTargets = (node, changes = null, visited = new Set()) => {
    if (visited.has(node)) return;
    visited.add(node);

    const firstCall = changes === null;
    if (firstCall) changes = [];
    
    changes.push({ id: node.id, status: 2 });

    node.targets.forEach(target => {
      if (target.type === 1) return;
      if (target.node.status !== 2) blockAllTargets(target.node, changes, visited)});

    if (firstCall) dispatch(updateNodesRequest(changes));
  }

  useEffect(() => {
    const updatedNodes = data.nodes.map((node) => ({
      ...node,
      targets: [], // Ініціалізуємо пусті масиви
      sources: [],
    }));
    data.links.forEach((link) => {
      const sourceNode = updatedNodes.find(node => node.id === link.source);
      const targetNode = updatedNodes.find(node => node.id === link.target);

      if (sourceNode && targetNode) {
        sourceNode.targets.push({node: updatedNodes.find(node => node.id === link.target), link: link}); // Додаємо target до джерела
        targetNode.sources.push({node: updatedNodes.find(node => node.id === link.source), link: link}); // Додаємо source до цілі
      }
    });
    setGraphData({...data, nodes: updatedNodes,});
  }, [data]);

  useEffect(() => {
    dispatch(turnOnLoading());
    if (!graphData) return;
    const camera = fgRef.current.camera();
    const scene = fgRef.current.scene();

    fgRef.current.d3Force('link').distance(link => (Math.log((link.source.weight || 5) + 1)*7 + Math.log((link.target.weight || 5) +1)*2.5) * 8);
    fgRef.current.d3Force('charge').strength(Math.max(-graphData.nodes.length*2, -200));

    attributesRef.current = {
      times: new Float32Array(graphData.nodes.length),
      rs: new Float32Array(graphData.nodes.length),
      firstColors: new Float32Array(graphData.nodes.length * 3),
      secondColors: new Float32Array(graphData.nodes.length * 3),
      opacities: new Float32Array(graphData.nodes.length),
      positions: new Float32Array(graphData.nodes.length * 3),
      scales: new Float32Array(graphData.nodes.length),
      hovered: new Float32Array(graphData.nodes.length),
    }
    commonQuaternionRef.current = camera.quaternion.clone();

    graphData.nodes.forEach((node, index) => {
      attributesRef.current.times[index] = index;

      attributesRef.current.rs[index] = Math.log(node.weight + 10)**2;
      const firstColor = new THREE.Color(STATUS_COLORS[node.status].firstColor);
      const secondColor = new THREE.Color(STATUS_COLORS[node.status].secondColor);
    
      attributesRef.current.firstColors.set([firstColor.r, firstColor.g, firstColor.b], index * 3);
      attributesRef.current.secondColors.set([secondColor.r, secondColor.g, secondColor.b], index * 3);
  
      attributesRef.current.positions.set([node.x, node.y, node.z], index * 3);
      attributesRef.current.scales[index] = Math.log(node.weight + 10) ** 1.8;

      attributesRef.current.opacities[index] = 1;
      attributesRef.current.quaternion = camera.quaternion.clone();
    });

    snoisesRef.current = new THREE.InstancedMesh(new THREE.SphereGeometry(1, 32, 32), SNOISE_MATERIAL, graphData.nodes.length);
    snoisesRef.current.frustumCulled = false;
    snoisesRef.current.rcID = RC_CORE;
    snoisesRef.current.geometry.setAttribute("u_time", new THREE.InstancedBufferAttribute(attributesRef.current.times, 1));
    snoisesRef.current.geometry.setAttribute("u_r", new THREE.InstancedBufferAttribute(attributesRef.current.rs, 1));
    snoisesRef.current.geometry.setAttribute("firstColor",new THREE.InstancedBufferAttribute(attributesRef.current.firstColors, 3));
    snoisesRef.current.geometry.setAttribute("secondColor",new THREE.InstancedBufferAttribute(attributesRef.current.secondColors, 3));
    snoisesRef.current.geometry.setAttribute("opacity",new THREE.InstancedBufferAttribute(attributesRef.current.opacities, 1));
    scene.add(snoisesRef.current);

    cardsRef.current = new THREE.InstancedMesh(CARD_GEOMETRY, CARD_MATERIAL, graphData.nodes.length);
    cardsRef.current.frustumCulled = false;
    cardsRef.current.hoveredPosition = new THREE.Vector3();
    cardsRef.current.hoveredScale = new THREE.Vector3();
    cardsRef.current.rcID = RC_CARD;
    cardsRef.current.geometry.setAttribute("u_time", new THREE.InstancedBufferAttribute(attributesRef.current.times, 1));
    cardsRef.current.geometry.setAttribute("u_r", new THREE.InstancedBufferAttribute(attributesRef.current.rs, 1));
    cardsRef.current.geometry.setAttribute("firstColor",new THREE.InstancedBufferAttribute(attributesRef.current.firstColors, 3));
    cardsRef.current.geometry.setAttribute("secondColor",new THREE.InstancedBufferAttribute(attributesRef.current.secondColors, 3));
    cardsRef.current.geometry.setAttribute("opacity",new THREE.InstancedBufferAttribute(attributesRef.current.opacities, 1));
    cardsRef.current.geometry.setAttribute("hovered",new THREE.InstancedBufferAttribute(attributesRef.current.hovered, 1));
    scene.add(cardsRef.current);
    // cards.boundingSphere.radius.multiplyScalar(2);

    titlesRef.current = [];
    graphData.nodes.forEach(node => {
      const title = new Text();
      title.geometry.translate(0, 0, CARD_PROPERTIES.depth / 2 + 0.3);
      title.depthOffset = -1;
      title.anchorX = -CARD_PROPERTIES.titleX;
      title.anchorY = -CARD_PROPERTIES.titleY;
      title.text = (node.title?.length || 0) > TITLE_MAX_CHARS ? node.title.slice(0, TITLE_MAX_CHARS-3) + '...' : node.title || "Untitled";
      title.font = CARD_PROPERTIES.titleFont;
      title.fontSize = CARD_PROPERTIES.titleFontSize;
      title.color = node.title ? CARD_PROPERTIES.titleColor : CARD_PROPERTIES.untitledColor;
      title.maxWidth = CARD_PROPERTIES.titleMaxWidth;
      title.lineHeight = CARD_PROPERTIES.textLineHeight; // Відстань між рядками
      title.position.set(node.x, node.y, node.z-10);
      title.quaternion.copy(scene.quaternion);
      titlesRef.current.push(title);
      scene.add(title);
    });

    interactableObjectsRef.current = [
      snoisesRef.current,
      cardsRef.current,
      SUBTASK_BUTTON.BACKGROUND,
      CARD_STATUS.INTERACTIVE_SPRITE,
      NEW_CONNECTION_BUTTONS.INTERACTIVE_SPRITE,
      NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE,
      NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE,
      CARD_VOLUME.INTERACTIVE_SPRITE,
      CARD_SETTINGS.SHARE_BUTTON.BACKGROUND,
      CARD_SETTINGS.COLLAPSE.INTERACTIVE_SPRITE,
      LINK_SETTINGS.CHANGE_TYPE_BUTTON.BACKGROUND,
      LINK_SETTINGS.REVERSE_BUTTON.BACKGROUND,
      LINK_SETTINGS.NEW_NODE_BUTTON.BACKGROUND,
      CARD_SETTINGS.INTERACTIVE_SPRITE,
      CARD_SETTINGS.TRASH.INTERACTIVE_SPRITE,
      CARD_PIN.INTERACTIVE_SPRITE,
      LINK_SETTINGS.INTERACTIVE_SPRITE,
      LINK_SETTINGS.SCISSORS.INTERACTIVE_SPRITE,
      ...CARD_STATUS.BUTTONS.map(button => button.background)
    ];

    graphDataRef.current = graphData;

    dispatch(turnOffLoading());
    return () => {
      scene.remove(snoisesRef.current);
      scene.remove(cardsRef.current);
      // snoisesRef.current.geometry.dispose();
      // snoisesRef.current.material.dispose();
      // cardsRef.current.geometry.dispose();
      // cardsRef.current.material.dispose();

      titlesRef.current.forEach(title => {
        scene.remove(title);
        // title.dispose();
      });
    }
  }, [graphData]);

  useEffect(() => {
    if (!fgRef.current) return;
    const scene = fgRef.current.scene();
    const controls = fgRef.current.controls();
    controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
    controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;
    controls.keyPanSpeed = 70;
    controls.keys = ['KeyF', 'KeyG', 'KeyH'];
    controls.panSpeed = 0.06;
    controls.zoomToCursor = true;
    controls.update();

    scene.background = createGradientTexture(window.innerWidth, window.innerHeight);

    scene.add(SUBTASK_BUTTON);
    scene.add(NEW_LINK);
    scene.add(CARD_STATUS);
    scene.add(NEW_CONNECTION_BUTTONS);
    scene.add(CARD_VOLUME);
    scene.add(CARD_SETTINGS);
    scene.add(LINK_SETTINGS);
    scene.add(CARD_PIN);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(0, -100000, 0);  
    scene.add(directionalLight);

    flyControlsRef.current = { speed: 10, direction : {x: 0, y: 0, z: 0} };
    const handleKeyDown = (event) => {
      switch (event.code) {
        case 'KeyW': flyControlsRef.current.direction.z = 1; break;
        case 'KeyS': flyControlsRef.current.direction.z = -1; break;
        case 'KeyA': flyControlsRef.current.direction.x = 1; break;
        case 'KeyD': flyControlsRef.current.direction.x = -1; break;
        case 'KeyQ': flyControlsRef.current.direction.y = -1; break;
        case 'KeyE': flyControlsRef.current.direction.y = 1; break;
        default: break;
    }};
    const handleKeyUp = (event) => {
      switch (event.code) {
        case 'KeyW': case 'KeyS': flyControlsRef.current.direction.z = 0; break;
        case 'KeyA': case 'KeyD': flyControlsRef.current.direction.x = 0; break;
        case 'KeyQ': case 'KeyE': flyControlsRef.current.direction.y = 0; break;
        default: break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    
    const onCardHover = (hovered, instanceId, uv) => {
      if (cardsRef.current.hoveredNode && instanceId && uv) {
        const size = new THREE.Vector3();
        const padding = CARD_PROPERTIES.padding*CARD_PROPERTIES.titleFontSize;
        titlesRef.current[cardsRef.current.hoveredInstance].geometry.boundingBox.getSize(size);

        cardsRef.current.isTitleHovered = uv.x > CARD_PROPERTIES.titleX - padding && uv.x < CARD_PROPERTIES.titleX + size.x
          && uv.y < CARD_PROPERTIES.height/2 - padding && uv.y > CARD_PROPERTIES.titleY - size.y;

        document.body.style.cursor = cardsRef.current.isTitleHovered ? 'text' : 'default';
      }
        
      if (cardsRef.current.isHovered === hovered && cardsRef.current.hoveredInstance === instanceId) return;
      cardsRef.current.isHovered = hovered;
  
      if (instanceId !== undefined) {
        attributesRef.current.hovered[cardsRef.current.hoveredInstance] = 0;
        cardsRef.current.hoveredInstance = instanceId;
        cardsRef.current.hoveredNode = graphDataRef.current.nodes[instanceId];
        attributesRef.current.hovered[instanceId] = 1;
        cardsRef.current.geometry.attributes.hovered.needsUpdate = true;
      }
      
      if (NEW_LINK.node && cardsRef.current.hoveredNode) {
        if (!NEW_LINK.source && cardsRef.current.hoveredNode.dependent) NEW_LINK.material.color = new THREE.Color(LINK_STATUS_COLORS[hovered ? cardsRef.current.hoveredNode.status : LINK_STATUS_COLORS.length-1]);
        NEW_LINK.material.opacity = hovered ? 0.4 : 0.2;
      }
      
      if (!hovered) {
        attributesRef.current.hovered[cardsRef.current.hoveredInstance] = 0;
        cardsRef.current.geometry.attributes.hovered.needsUpdate = true;
  
        cardsRef.current.hoveredInstance = instanceId;
        cardsRef.current.hoveredNode = null;
  
        CARD_SETTINGS.onCardHover(null);
        CARD_VOLUME.onCardHover(null);
        CARD_STATUS.onCardHover(null);
        SUBTASK_BUTTON.onCardHover(null);
        NEW_CONNECTION_BUTTONS.onCardHover(null);
        CARD_PIN.onCardHover(null);
  
      } else if (instanceId !== undefined && !NEW_LINK.node) {
        NEW_CONNECTION_BUTTONS.onCardHover(instanceId, cardsRef.current.hoveredNode.status, attributesRef.current.scales[instanceId]);
        CARD_SETTINGS.onCardHover(instanceId, cardsRef.current.hoveredNode.status, attributesRef.current.scales[instanceId]);
        CARD_STATUS.onCardHover(instanceId, cardsRef.current.hoveredNode.status, attributesRef.current.scales[instanceId]);
        CARD_VOLUME.onCardHover(instanceId, cardsRef.current.hoveredNode.volume, attributesRef.current.scales[instanceId]);
        SUBTASK_BUTTON.onCardHover(instanceId, attributesRef.current.scales[instanceId]);
        CARD_PIN.onCardHover(instanceId, attributesRef.current.scales[instanceId], cardsRef.current.hoveredNode.pinned);
      }
    };

    const onTitleClick = () => {
      cardsRef.current.titleInstance = cardsRef.current.hoveredInstance;
      const node = cardsRef.current.hoveredNode;

      cardsRef.current.titleInput = document.createElement('textarea');
      cardsRef.current.titleInput.value = cardsRef.current.hoveredNode.title || '';
      cardsRef.current.titleInput.style.position = 'absolute';
      cardsRef.current.titleInput.style.zIndex = '1000';
      cardsRef.current.titleInput.style.fontFamily = 'Roboto, Arial, sans-serif';
      cardsRef.current.titleInput.style.fontSize = `28px`;
      cardsRef.current.titleInput.style.fontWeight = 'bold';
      cardsRef.current.titleInput.style.backgroundColor = 'rgba(0, 0, 0, 0)';
      cardsRef.current.titleInput.style.outline = 'none';
      cardsRef.current.titleInput.style.transformOrigin = "left top";
      cardsRef.current.titleInput.style.maxWidth = "1100px";
      cardsRef.current.titleInput.style.height = "calc(3 * 1.2em)";
      cardsRef.current.titleInput.style.lineHeight = "1.2";
      cardsRef.current.titleInput.style.overflow = 'hidden';
      cardsRef.current.titleInput.style.resize = 'none';
      document.body.appendChild(cardsRef.current.titleInput);
      cardsRef.current.titleInput.focus();
      titlesRef.current[cardsRef.current.titleInstance].text = '';
      cardsRef.current.titleInput.addEventListener('blur', () => {
        dispatch(updateNodesRequest([{ id: node.id, 'title': cardsRef.current.titleInput.value }]));
        titlesRef.current[cardsRef.current.titleInstance].text = cardsRef.current.titleInput.value || "Untitled";
        titlesRef.current[cardsRef.current.titleInstance].color = cardsRef.current.titleInput.value ? CARD_PROPERTIES.titleColor : CARD_PROPERTIES.untitledColor;
        node.title = cardsRef.current.titleInput.value;
        document.body.removeChild(cardsRef.current.titleInput);
        cardsRef.current.titleInput = cardsRef.current.titleInstance =null;
      });
      cardsRef.current.titleInput.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') cardsRef.current.titleInput.blur()
        else if(e.key === 'Escape') {
          cardsRef.current.titleInput.value = node.title;
          cardsRef.current.titleInput.blur();
        }
      });
    };

    let rcID;
    const handleMouseMove = (e) => {
      if (!cardsRef.current || !graphDataRef.current) return;
      snoisesRef.current.computeBoundingSphere();
      cardsRef.current.computeBoundingSphere();

      const mouse = new THREE.Vector2();
      mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
      raycasterRef.current.setFromCamera(mouse, camera);
      if (cardsRef.current?.isHovered || NEW_LINK.node) LINK_SETTINGS.hideAll();
      else LINK_SETTINGS.onMouseMove(graphDataRef.current.links, raycasterRef.current);
      const intersect = NEW_LINK.node
        ? raycasterRef.current.intersectObjects(interactableObjectsRef.current).find(intersection => !NEW_LINK.connected.some(node => node.index === intersection.instanceId))
        : raycasterRef.current.intersectObjects(interactableObjectsRef.current)[0];
      rcID = intersect?.object?.rcID;
  
      onCardHover(Math.floor(rcID / RC_CLASS_MOD) * RC_CLASS_MOD === RC_CARD, intersect?.instanceId, intersect?.uv);
    
      CARD_SETTINGS.onHover(Math.floor(rcID / RC_SUB_CLASS_MOD) * RC_SUB_CLASS_MOD === RC_CARD_SETTINGS);
      CARD_SETTINGS.TRASH.onHover(rcID === RC_CARD_SETTINGS_TRASH);
      CARD_SETTINGS.SHARE_BUTTON.onHover(rcID === RC_CARD_SETTINGS_SHARE);
      CARD_SETTINGS.COLLAPSE.onHover(rcID === RC_CARD_SETTINGS_COLLAPSE);
  
      CARD_VOLUME.onHover(rcID === RC_CARD_VOLUME, intersect?.uv?.y);
  
      CARD_STATUS.onHover(Math.floor(rcID / RC_SUB_CLASS_MOD) * RC_SUB_CLASS_MOD === RC_CARD_STATUS, cardsRef.current.hoveredNode?.status);
      CARD_STATUS.BUTTONS.forEach(button => button.onHover(rcID === button.background.rcID));
  
      CARD_PIN.onHover(Math.floor(rcID / RC_SUB_CLASS_MOD) * RC_SUB_CLASS_MOD === RC_CARD_PIN);
  
      SUBTASK_BUTTON.onHover(rcID === SUBTASK_BUTTON.BACKGROUND.rcID);
  
      NEW_CONNECTION_BUTTONS.onHover(Math.floor(rcID / RC_SUB_CLASS_MOD) * RC_SUB_CLASS_MOD === RC_CARD_CORE);
      NEW_CONNECTION_BUTTONS.SUPER.onHover(rcID === NEW_CONNECTION_BUTTONS.SUPER.INTERACTIVE_SPRITE.rcID);
      NEW_CONNECTION_BUTTONS.SUB.onHover(rcID === NEW_CONNECTION_BUTTONS.SUB.INTERACTIVE_SPRITE.rcID);
  
      
      if (!cardsRef.current.isHovered) LINK_SETTINGS.onHover(Math.floor(rcID / RC_SUB_CLASS_MOD) * RC_SUB_CLASS_MOD === RC_LINK_SETTINGS);
      LINK_SETTINGS.SCISSORS.onHover(rcID === RC_LINK_SETTINGS_SCISSORS);
      LINK_SETTINGS.NEW_NODE_BUTTON.onHover(rcID === RC_LINK_SETTINGS_NEW_NODE_BUTTON);
      LINK_SETTINGS.REVERSE_BUTTON.onHover(rcID === RC_LINK_SETTINGS_REVERSE_BUTTON);
      LINK_SETTINGS.CHANGE_TYPE_BUTTON.onHover(rcID === RC_LINK_SETTINGS_CHANGE_TYPE_BUTTON);

      if (NEW_LINK.node) {
        if (cardsRef.current.hoveredNode && !NEW_LINK.connected.includes(cardsRef.current.hoveredNode)) {
          NEW_LINK.position.addVectors(NEW_LINK.node.__threeObj.position, cardsRef.current.hoveredNode.__threeObj.position).multiplyScalar(0.5);
          NEW_LINK.scale.set(1, NEW_LINK.node.__threeObj.position.distanceTo(cardsRef.current.hoveredNode.__threeObj.position), 1);
          NEW_LINK.quaternion.setFromUnitVectors(
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3().subVectors(cardsRef.current.hoveredNode.__threeObj.position, NEW_LINK.node.__threeObj.position).normalize()
          );
        } else {
          const target = new THREE.Vector3();
          raycasterRef.current.ray.at(Math.abs(cardsRef.current.position.z - controls.target.z), target);
          NEW_LINK.position.addVectors(NEW_LINK.node.__threeObj.position, target).multiplyScalar(0.5);
          NEW_LINK.scale.set(1, NEW_LINK.node.__threeObj.position.distanceTo(target), 1);
          NEW_LINK.quaternion.setFromUnitVectors(
            new THREE.Vector3(0, 1, 0),
            new THREE.Vector3().subVectors(target, NEW_LINK.node.__threeObj.position).normalize()
          );
        }
      }
    };
    window.addEventListener('mousemove', handleMouseMove);
    
    const handleMouseClick = (e) => {
      LINK_SETTINGS.onClick(LINK_SETTINGS.isHovered, fgRef.current.camera().quaternion);
      if(CARD_STATUS.BUTTONS.find(button => button.background.rcID === rcID)?.onClick(cardsRef.current.hoveredNode, dispatch, blockAllTargets)) {
        rcID = RC_CARD_STATUS;
        const instanceId = cardsRef.current.hoveredInstance;

        const oldFirstColor = new THREE.Color(
          attributesRef.current.firstColors[instanceId * 3],
          attributesRef.current.firstColors[instanceId * 3 + 1],
          attributesRef.current.firstColors[instanceId * 3 + 2]
        );
        const oldSecondColor = new THREE.Color(
          attributesRef.current.secondColors[instanceId * 3],
          attributesRef.current.secondColors[instanceId * 3 + 1],
          attributesRef.current.secondColors[instanceId * 3 + 2]
        );
        const newFirstColor = new THREE.Color(STATUS_COLORS[cardsRef.current.hoveredNode.status].firstColor);
        const newSecondColor = new THREE.Color(STATUS_COLORS[cardsRef.current.hoveredNode.status].secondColor);

        const delta = { t: 0 };
        gsap.to(delta, {
          t: 1,
          duration: 5,
          onUpdate: () => {
            oldFirstColor.lerp(newFirstColor, delta.t);
            oldSecondColor.lerp(newSecondColor, delta.t);
      
            attributesRef.current.firstColors.set([oldFirstColor.r, oldFirstColor.g, oldFirstColor.b], instanceId * 3);
            attributesRef.current.secondColors.set([oldSecondColor.r, oldSecondColor.g, oldSecondColor.b], instanceId * 3);

            snoisesRef.current.geometry.attributes.firstColor.needsUpdate = true;
            snoisesRef.current.geometry.attributes.secondColor.needsUpdate = true;
            cardsRef.current.geometry.attributes.firstColor.needsUpdate = true;
            cardsRef.current.geometry.attributes.secondColor.needsUpdate = true;
          },
          ease: 'sine.in',
        });

        CARD_STATUS.onChangeStatus(cardsRef.current.hoveredNode.status);
      }

      switch(rcID) {
        case RC_CARD_SETTINGS:
          CARD_SETTINGS.onClick();
        break;

        case RC_LINK_SETTINGS_SCISSORS:
          dispatch(updateCoordinatesRequest({nodes: graphDataRef.current.nodes.map(node => ({id: node.id, x: node.x, y: node.y, z: node.z, }))}));
          LINK_SETTINGS.SCISSORS.onClick(dispatch);
        break;

        case RC_CARD_CORE:
          // focusOnNode.current(cards.current.hoveredNode)
        break;

        case RC_CARD_PIN:
          CARD_PIN.onClick(dispatch);
        break;

        case RC_CARD_SUBTASK_BUTTON:
          dispatch(updateCoordinatesRequest({nodes: graphDataRef.current.nodes.map(node => ({id: node.id, x: node.x, y: node.y, z: node.z, }))}));
          SUBTASK_BUTTON.onClick(cardsRef.current.hoveredNode, dispatch, blockAllTargets, focusOnNode.current);
          onCardHover(null)
        break;

        case RC_CARD_SETTINGS_TRASH:
          dispatch(updateCoordinatesRequest({nodes: graphDataRef.current.nodes.map(node => ({id: node.id, x: node.x, y: node.y, z: node.z, }))}));
          CARD_SETTINGS.TRASH.onClick(cardsRef.current.hoveredNode, dispatch);
          CARD_SETTINGS.scale.set(0, 0, 0);
          onCardHover(null);
        break;

        case RC_CARD:
          if (cardsRef.current.isTitleHovered) onTitleClick();
        break;

        default:
        return;
      };
    };
    window.addEventListener('click', handleMouseClick);

    const handleMouseDblClick = (e) => {
      switch(rcID) {
        case RC_CORE:
        case RC_CARD_CORE:
          focusOnNode.current(cardsRef.current.hoveredNode)
        break;

        default:
        return;
      };
    };
    window.addEventListener('dblclick', handleMouseDblClick);

    const handleMouseDown = (e) => {
      if (rcID !== RC_CARD_CONNECTIONS_SUPER && rcID !== RC_CARD_CONNECTIONS_SUB) return;

      const controls = fgRef.current.controls();
      controls.noPan = true;

      NEW_LINK.node = cardsRef.current.hoveredNode;
      NEW_LINK.source = rcID === RC_CARD_CONNECTIONS_SUPER;
      
      NEW_LINK.connected = [];
      graphDataRef.current.links.forEach(link => {
        if(link.source === NEW_LINK.node) {
          NEW_LINK.connected.push(link.target);

          link.target.__threeObj.material.opacity = 0.1;
          titlesRef.current[link.target.index].fillOpacity = 0.2;
          attributesRef.current.opacities[link.target.index] = 0.2;

        } else if (link.target !== NEW_LINK.node) {
          link.__lineObj.material.opacity = 0.03;
          link.__photonsObj.visible = false;
        }

        if(link.target === NEW_LINK.node) {
          NEW_LINK.connected.push(link.source);

          link.source.__threeObj.material.opacity = 0.1;
          titlesRef.current[link.source.index].fillOpacity = 0.2;
          attributesRef.current.opacities[link.source.index] = 0.2;

        } else if (link.source !== NEW_LINK.node) {
          link.__lineObj.material.opacity = 0.03;
          link.__photonsObj.visible = false;
        }
      });
      NEW_LINK.connected.push(NEW_LINK.node);

      snoisesRef.current.material.depthWrite = false;
      cardsRef.current.material.depthWrite = false;
      snoisesRef.current.geometry.attributes.opacity.needsUpdate = true;
      cardsRef.current.geometry.attributes.opacity.needsUpdate = true;

      const target = new THREE.Vector3((e.clientX / window.innerWidth) * 2 - 1, -(e.clientY / window.innerHeight) * 2 + 1, controls.target.z);
      NEW_LINK.position.addVectors(NEW_LINK.node.__threeObj.position, target).multiplyScalar(0.5);
      const scale = NEW_LINK.source ? Math.log(NEW_LINK.node.weight+1) / 1.5 : 1;
      NEW_LINK.scale.set(scale, 0, scale);
      
      NEW_LINK.material.color = new THREE.Color(NEW_LINK.source ? LINK_STATUS_COLORS[NEW_LINK.node.status] : LINK_STATUS_COLORS[0]);
    };
    window.addEventListener('mousedown', handleMouseDown);

    const handleMouseUp = () => {
      if (!NEW_LINK.node) return;

      const controls = fgRef.current.controls();

      NEW_LINK.scale.set(0, 0, 0);

      graphDataRef.current.links.forEach(link => {
        link.__lineObj.material.opacity = 0.2
        link.__photonsObj.visible = true;
      });
      graphDataRef.current.nodes.forEach(node => {
        node.__threeObj.material.opacity = 0.7;
        titlesRef.current[node.index].fillOpacity = 1;

        attributesRef.current.opacities[node.index] = 1;
      });
      snoisesRef.current.material.depthWrite = true;
      cardsRef.current.material.depthWrite = true;
      snoisesRef.current.geometry.attributes.opacity.needsUpdate = true;
      cardsRef.current.geometry.attributes.opacity.needsUpdate = true;

      controls.noPan = false;

      if (cardsRef.current.hoveredNode) {
        dispatch(updateCoordinatesRequest({nodes: graphDataRef.current.nodes.map(node => ({id: node.id, x: node.x, y: node.y, z: node.z, }))}));
        if (NEW_LINK.source) {
          dispatch(addLinkRequest({id: uuidv4(), target: cardsRef.current.hoveredNode.id, source: NEW_LINK.node.id, type: cardsRef.current.hoveredNode.dependent ? 0 : 1, }));

          if (cardsRef.current.hoveredNode.dependent
            && NEW_LINK.node.status !== 3
            && cardsRef.current.hoveredNode.status !== 2
          ) blockAllTargets(cardsRef.current.hoveredNode);

        } else {
          dispatch(addLinkRequest({id: uuidv4(), target: NEW_LINK.node.id, source: cardsRef.current.hoveredNode.id, type: NEW_LINK.node.dependent ? 0 : 1, }));

          if (NEW_LINK.node.dependent
            && cardsRef.current.hoveredNode.status !== 3
            && NEW_LINK.node.status !== 2
          ) blockAllTargets(NEW_LINK.node);
        }
      }

      NEW_LINK.node = null;
    };
    window.addEventListener('mouseup', handleMouseUp);


    const camera = fgRef.current.camera();
    const animate = () => {
      requestAnimationFrame(animate);
      if (!graphDataRef.current) return;
      LINK_SETTINGS.animate();
  
      const up = camera.up.clone();
      const direction = new THREE.Vector3();
      camera.getWorldDirection(direction);
  
      const right = new THREE.Vector3();
      right.crossVectors(up, direction).normalize();
  
      const unactiveUserAnimation = { rotationSpeed: 0};
      if (Date.now() - lastActive.current > UNACTIVE_THRESHOLD) {
        if (unactiveUserAnimation.needStart) {
          unactiveUserAnimation.basicSpeed = Math.random() < 0.5 ? -0.0005 : 0.0005;
          unactiveUserAnimation.needStart = false;
          unactiveUserAnimation.cameraPosition = camera.position.clone();
          unactiveUserAnimation.targetPosition = controls.target.clone();
        }
        unactiveUserAnimation.rotationSpeed = THREE.MathUtils.lerp(unactiveUserAnimation.rotationSpeed, unactiveUserAnimation.basicSpeed * camera.position.distanceTo(controls.target), 0.002);
  
      } else {
        if (!unactiveUserAnimation.needStart) {
          unactiveUserAnimation.needStart = true;
          fgRef.current.cameraPosition(unactiveUserAnimation.cameraPosition, unactiveUserAnimation.targetPosition, 500);
        }
        unactiveUserAnimation.rotationSpeed = 0;
      }
      
      if (!cardsRef.current.titleInstance) {
        camera.position.addScaledVector(direction, flyControlsRef.current.direction.z * flyControlsRef.current.speed);
        camera.position.addScaledVector(right, flyControlsRef.current.direction.x * flyControlsRef.current.speed + unactiveUserAnimation.rotationSpeed);
        camera.position.addScaledVector(up, flyControlsRef.current.direction.y * flyControlsRef.current.speed);
        
        controls.target.addScaledVector(direction, flyControlsRef.current.direction.z * flyControlsRef.current.speed);
        controls.target.addScaledVector(right, flyControlsRef.current.direction.x * flyControlsRef.current.speed);
        controls.target.addScaledVector(up, flyControlsRef.current.direction.y * flyControlsRef.current.speed);      
        controls.update();
      }
  
      const dummy = new THREE.Object3D();
      graphDataRef.current.nodes.forEach((node, index) => {
        dummy.position.set(
          attributesRef.current.positions[index * 3],
          attributesRef.current.positions[index * 3 + 1],
          attributesRef.current.positions[index * 3 + 2]
        );
  
        node.isFar = showAll.current ? false : camera.position.distanceTo(dummy.position) > Math.max(1200, 200 * Math.sqrt(node.weight + 1));
        
        attributesRef.current.scales[index] = THREE.MathUtils.lerp(attributesRef.current.scales[index], node.isFar ? 0 : Math.log(node.weight + 10) ** 1.8, 0.2);
        dummy.scale.set(attributesRef.current.scales[index], attributesRef.current.scales[index], attributesRef.current.scales[index]);
        commonQuaternionRef.current.slerp(camera.quaternion, 0.0005);
        
        titlesRef.current[index].position.set(
          attributesRef.current.positions[index * 3],
          attributesRef.current.positions[index * 3 + 1],
          attributesRef.current.positions[index * 3 + 2]
        );
        titlesRef.current[index].quaternion.copy(commonQuaternionRef.current);
        titlesRef.current[index].scale.set(attributesRef.current.scales[index], attributesRef.current.scales[index], attributesRef.current.scales[index]);
        dummy.quaternion.copy(commonQuaternionRef.current);
  
        dummy.updateMatrix();
  
        const scale = Math.log(node.weight + 10) ** 1.8;
        const snoiseMatrix = new THREE.Matrix4();
        snoiseMatrix.compose(
          dummy.position,      // Позиція
          new THREE.Quaternion(),      // Без обертання
          new THREE.Vector3(scale, scale, scale) // Старий масштаб
        );
        snoisesRef.current.setMatrixAt(index, snoiseMatrix);
        cardsRef.current.setMatrixAt(index, dummy.matrix);
  
        if (cardsRef.current.hoveredInstance === index) {
          SUBTASK_BUTTON.animate(dummy.position, dummy.quaternion);
          NEW_CONNECTION_BUTTONS.animate(cardsRef.current.hoveredInstance, dummy.position, dummy.quaternion);
          CARD_STATUS.animate(dummy.position, dummy.quaternion);
          CARD_VOLUME.animate(dummy.position, dummy.quaternion);
          CARD_SETTINGS.animate(dummy.position, dummy.quaternion);
          CARD_PIN.animate(dummy.position, dummy.quaternion);
        }

        if(cardsRef.current.titleInstance === index) {
          const projection = new THREE.Vector3();
          titlesRef.current[index].getWorldPosition(projection);
          direction.normalize().multiplyScalar(-CARD_PROPERTIES.depth/2*scale);
          projection.add(direction);
          const distanceScale = scale*20 / camera.position.distanceTo(projection);
          projection.project(camera);
          const x = (projection.x * 0.5 + 0.5) * window.innerWidth;
          const y = (1 - (projection.y * 0.5 + 0.5)) * window.innerHeight;
          cardsRef.current.titleInput.style.left = `${x + CARD_PROPERTIES.titleX * distanceScale * window.innerWidth / 20}px`;
          cardsRef.current.titleInput.style.top = `${y - (CARD_PROPERTIES.titleY + 0.2) * distanceScale * window.innerHeight / 20}px`;
          cardsRef.current.titleInput.style.transform = `scale(${distanceScale})`;
        }
  
        attributesRef.current.times[index] += 0.02;
      });
  
      snoisesRef.current.instanceMatrix.needsUpdate = true;
      cardsRef.current.instanceMatrix.needsUpdate = true;
  
      snoisesRef.current.geometry.attributes.u_time.needsUpdate = true;
      cardsRef.current.geometry.attributes.u_time.needsUpdate = true;
    };
    animate();
    
    focusOnNode.current = (node) => {
      if (!node) return;
      const controls = fgRef.current.controls();
      const camera = fgRef.current.camera();
      
      const distance = Math.log(node.weight + 1) * 100; // Відстань до вузла
      const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
      const newCameraPos = {x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio,};
    
      const duration = 1.5;
      gsap.to(controls.target, {x: node.x, y: node.y, z: node.z, duration, ease: 'sine.out',});
      gsap.to(camera.position, {
        x: newCameraPos.x,
        y: newCameraPos.y,
        z: newCameraPos.z,
        duration,
        ease: 'sine.out',
        onUpdate: () => {
          const newDirection = new THREE.Vector3();
          camera.getWorldDirection(newDirection);
          const rightVector = new THREE.Vector3().crossVectors(newDirection, new THREE.Vector3(0, 1, 0)).normalize();
          const adjustedUp = new THREE.Vector3().crossVectors(rightVector, newDirection).normalize();
          camera.up = adjustedUp;
          controls.update();
        },
        onComplete: () => {
          const newDirection = new THREE.Vector3();
          camera.getWorldDirection(newDirection);
          const rightVector = new THREE.Vector3().crossVectors(newDirection, new THREE.Vector3(0, 1, 0)).normalize();
          const adjustedUp = new THREE.Vector3().crossVectors(rightVector, newDirection).normalize();
          controls.up = adjustedUp;
          controls.update();
        },
      });
    };

    const handleActivity = () => {lastActive.current = Date.now()};
    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart', 'touchmove'];
    events.forEach((event) => {window.addEventListener(event, handleActivity)});

    if (SHOW_STARS) {
      const starGeometry = new THREE.BufferGeometry();
      
      const blurredCircleTexture = TEXTURE_LOADER.load('https://threejs.org/examples/textures/sprites/circle.png');

      const starsVertices = [];
      const starsAlphas = [];
      const starsDirections = [];
      for (let i = 0; i < data.nodes.length*4; i++) { // Кількість зірок
        const x = THREE.MathUtils.randFloatSpread(10000) * Math.random();
        const y = THREE.MathUtils.randFloatSpread(10000) * Math.random();
        const z = THREE.MathUtils.randFloatSpread(10000) * Math.random();
        starsVertices.push(x, y, z);

        starsAlphas.push(1.0); // Початкове значення альфа-каналу для кожної зірки

        starsDirections.push(new THREE.Vector3(
          (Math.random() - 0.5) * 0.01,
          (Math.random() - 0.5) * 0.01,
          (Math.random() - 0.5) * 0.01
        ));
      }

      starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starsVertices, 3));
      starGeometry.setAttribute('alpha', new THREE.Float32BufferAttribute(starsAlphas, 1));
      
      const stars = new THREE.Points(starGeometry, new THREE.ShaderMaterial({
        uniforms: { pointTexture: { value: blurredCircleTexture },},
        vertexShader: STARS_VERTEX_SHADER,
        fragmentShader: STARS_FRAGMENT_SHADER,
        transparent: true,
        depthWrite: false,
      }));

      stars.userData.directions = starsDirections; // Зберігаємо напрямки руху як користувацькі дані
      scene.add(stars); // Додаємо зірки безпосередньо до сцени графу

      const animateStars = () => {
        const positions = stars.geometry.attributes.position.array;
        const directions = stars.userData.directions;

        for (let i = 0; i < positions.length; i += 3) {
          positions[i] += directions[i / 3].x     * 2; // Оновлення позицій зірок
          positions[i + 1] += directions[i / 3].y * 2;
          positions[i + 2] += directions[i / 3].z * 2;
          
          // Зміна напрямку руху для створення ефекту мерехтіння
          
          if (Math.random() > 0.995) {
            directions[i / 3].x *= -1;
          }
          if (Math.random() > 0.995) {
            directions[i / 3].y *= -1;
          }
          if (Math.random() > 0.995) {
            directions[i / 3].z *= -1;
          }
        }
        stars.geometry.attributes.position.needsUpdate = true; // Оновлюємо геометрію для рендерингу
        requestAnimationFrame(animateStars);
      }
      animateStars();
    }

    if (USE_AUDIO) {
      // Створення аудіо-контексту та аналізатора
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
      // Завантаження аудіо-треку
      const audio = new Audio('/track-0.mp3');
      const source = audioContext.createMediaElementSource(audio);

      audio.currentTime = 3600;

      // Створення аналізаторів
      const bassAnalyser = audioContext.createAnalyser();
      const bassDataArray = new Uint8Array(bassAnalyser.frequencyBinCount);
      const bass = audioContext.createBiquadFilter();
      bass.type = 'lowpass';
      bass.frequency.setValueAtTime(150, audioContext.currentTime);
      source.connect(bass);
      bass.connect(bassAnalyser);

      const midAnalyser = audioContext.createAnalyser();
      const midDataArray = new Uint8Array(midAnalyser.frequencyBinCount);
      const highCut = audioContext.createBiquadFilter();
      highCut.type = 'lowpass';
      highCut.frequency.setValueAtTime(2000, audioContext.currentTime);
      source.connect(highCut);
      const lowCut = audioContext.createBiquadFilter();
      lowCut.type = 'highpass';
      lowCut.frequency.setValueAtTime(600, audioContext.currentTime);
      highCut.connect(lowCut);
      lowCut.connect(midAnalyser);
      
      const highAnalyser = audioContext.createAnalyser();
      const highDataArray = new Uint8Array(highAnalyser.frequencyBinCount);
      const high = audioContext.createBiquadFilter();
      high.type = 'highpass';
      high.frequency.setValueAtTime(2000, audioContext.currentTime);
      source.connect(high);
      high.connect(highAnalyser);
      
      source.connect(audioContext.destination);
      // Очікування взаємодії користувача
      const userInteractionHandler = () => {
        audio.play().then(() => {
          document.removeEventListener('click', userInteractionHandler);
          document.removeEventListener('keydown', userInteractionHandler);
    
          // Запуск оновлення графа
          function updateGraph() {
            fgRef.current.d3Force('link').distance(link => {
              const node = graphData.nodes.find(node => node.id === link.source.id);
    
              // Реакція вузлів на вузькі частотні діапазони
              if (node.weight > 200) {
                bassAnalyser.getByteFrequencyData(bassDataArray);
                return bassDataArray.reduce((a, b) => a + b, 0) / bassDataArray.length * 100
                + (
                  Math.log((graphData.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              } else if (node.weight > 10) {
                midAnalyser.getByteFrequencyData(midDataArray);
                return midDataArray.reduce((a, b) => a + b, 0) / midDataArray.length * 5
                + (
                  Math.log((graphData.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              } else {
                highAnalyser.getByteFrequencyData(highDataArray);
                return highDataArray.reduce((a, b) => a + b, 0) / highDataArray.length 
                + (
                  Math.log((graphData.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              }
            });
    
            fgRef.current.d3ReheatSimulation();
            requestAnimationFrame(updateGraph);
          }
    
          updateGraph();
        }).catch(error => {
          console.error('Error playing audio:', error);
        });
      };

      document.addEventListener('click', userInteractionHandler);
      document.addEventListener('keydown', userInteractionHandler);
    }

    const onWindowResize = () => {
      const camera = fgRef.current.camera();
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      fgRef.current.renderer().setSize( window.innerWidth, window.innerHeight );
    }
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleMouseClick);
      window.removeEventListener('dblclick', handleMouseDblClick);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      events.forEach((event) => {window.removeEventListener(event, handleActivity)});
      window.removeEventListener('resize', onWindowResize);
    }
  }, []);

  return (
    <>
      <CardPage
          graphData={graphData}
          getConnected={getConnected}
          blockAllTargets={blockAllTargets}
      />
      <ForceGraph3D
      graphData={graphData}
      ref={fgRef}
      // forceEngine="ngraph"
      // cooldownTicks={50}
      onEngineTick={() => {
        LINK_SETTINGS.onMouseMove(graphData.links, raycasterRef.current);

        graphData.nodes.forEach((node, index) => {
          attributesRef.current.positions.set([node.x, node.y, node.z], index * 3);
        });

        tickCount += 1;  // Збільшуємо кількість тіків
        if (tickCount === 10 && firstTime.current && graphData.nodes.length > 2) {
          firstTime.current = false;

          // const zoomToFitDuration = 500;
          // fgRef.current.zoomToFit(zoomToFitDurations);
          // setTimeout(() => {
          //   fgRef.current.controls().update();
          // }, fitDuration+1);

        }

        if (tickCount === 30) {
          if (graphData.nodes.length > 2 && focusNodeId.current) {
            focusOnNode.current(graphData.nodes.find(node => node.id === focusNodeId.current));
          }
          focusNodeId.current = null;
        }
        
      }}

      nodeColor={node => {
        return NODE_STATUS_COLORS[node.status].slice(0,7);
      }}
      // onNodeHover={node => cardsRef.current.hoveredNode = node}
      onNodeDrag={() => needSimulation.current = true}
      onNodeDragEnd={node => {
        if (node.pinned){
          node.fx = node.x;
          node.fy = node.y;
          node.fz = node.z;
        } else {
          node.fx = null;
          node.fy = null;
          node.fz = null;
        }
      }}
      onEngineStop={() => {
        if (tickCount) {
          needSimulation.current = false;
          dispatch(updateCoordinatesRequest({
            nodes: graphData.nodes.map(node => ({
              id: node.id,
              x: node.x,
              y: node.y,
              z: node.z,
            }
            ))
          }))
        }
        tickCount = 0;
      }}
    
      linkDirectionalParticles={link => Math.log((graphData.nodes.find(node => node.id === (link.source.id || link.source)).weight || 4) + 1)*2}
    
      linkDirectionalParticleColor={link => {
        return link.type !== 0 ? '#40404033' : LINK_STATUS_COLORS[graphData.nodes.find(node => node.id === (link.source.id || link.source)).status];
      }}
      linkDirectionalParticleWidth={link => Math.log((graphData.nodes.find(node => node.id === (link.source.id || link.source)).weight || 4) + 1)/1.5}
      linkDirectionalParticleSpeed={0.0002}
      
      linkWidth={link => {
        return Math.log((graphData.nodes.find(node => node.id === (link.source.id || link.source)).weight || 4) + 1)/1.5;
      }}
    
      linkColor={link => link.type ? LINK_STATUS_COLORS[LINK_STATUS_COLORS.length-1] : LINK_STATUS_COLORS[graphData.nodes.find(node => node.id === (link.source.id || link.source)).status]}
      nodeOpacity={0.4}
      nodeRelSize={1}
      nodeResolution={32}
      nodeVal={node => node.weight*15}
      showNavInfo={false}
    />
  </>
  );

};

export default Graph3D;
