import { useEffect, useRef, useState } from "react";

const GyroscopeComponent = () => {
    const deviceOrientationRef = useRef({
        alpha: 0,
        beta: 0,
        gamma: 0,
        rotation: 0,
        permition: "undefined",
    });
    const initialOrientationRef = useRef(null);
    const [data, setData] = useState({})

    useEffect(() => {
        const requestPermission = async () => {
            if (typeof DeviceOrientationEvent.requestPermission === 'function') {
                try {
                    const permission = await DeviceOrientationEvent.requestPermission();
                    deviceOrientationRef.current.permition = permission;
                    if (permission === 'granted') {
                        window.addEventListener("deviceorientation", handleOrientation);
                    } else {
                        console.log("Permission denied");
                    }
                } catch (error) {
                    console.error("Error while requesting permission:", error);
                }
            } else {
                // Для браузерів, які не вимагають дозволу
                window.addEventListener("deviceorientation", handleOrientation);
            }
        };
        const handleOrientation = (event) => {
            let { alpha, beta, gamma } = event;
            alpha = (alpha > 180 ? alpha - 360 : alpha);
            beta = beta + 90;
            const gammaWeight = Math.sin(Math.abs(gamma * (Math.PI / 180)));
            const tilt = alpha * (gammaWeight - 1) + beta * gammaWeight;

            // Визначаємо орієнтацію пристрою за допомогою window.orientation
            const orientation = window.orientation || 0; // 0, 90, -90, 180

            // Корекція значень в залежності від орієнтації
            if (orientation === 90) {
                // Якщо пристрій повернуто на 90 градусів (ліве обертання, альбомний режим)
                const temp = beta;
                beta = -gamma;
                gamma = temp;
            } else if (orientation === -90) {
                // Якщо пристрій повернуто на -90 градусів (праве обертання, альбомний режим)
                const temp = beta;
                beta = gamma;
                gamma = -temp;
            } else if (orientation === 180) {
                // Якщо пристрій повернуто на 180 градусів (перевернутий портретний режим)
                beta = -beta;
                gamma = -gamma;
            }



            if (!initialOrientationRef.current) {
                initialOrientationRef.current = { alpha, beta, gamma, tilt };
            }
            
            deviceOrientationRef.current = {
                alpha: alpha - initialOrientationRef.current.alpha,
                beta: beta - initialOrientationRef.current.beta,
                gamma: gamma - initialOrientationRef.current.gamma,
                rotation: tilt - initialOrientationRef.current.rotation,
            };
            setData({
                alpha: alpha - initialOrientationRef.current.alpha,
                beta: beta - initialOrientationRef.current.beta,
                gamma: gamma - initialOrientationRef.current.gamma,
                rotation: tilt - initialOrientationRef.current.rotation,
            })
        };

        requestPermission();

        return () => {
            window.removeEventListener("deviceorientation", handleOrientation);
        };
    }, []);

    return (
        <div style={{color: 'white'}}>
            <h2>Gyroscope Data</h2>
            <p>Alpha: {data.alpha}</p>
            <p>Beta: {data.beta}</p>
            <p>Normalized alpha: {data.alpha > 180 ? (data.alpha - 360) : data.alpha}</p>
            <p>Normalized beta: {data.beta + 90}</p>
            <p>Gamma: {data.gamma}</p>
            <p>gammaWeight: {Math.sin(Math.abs(data.gamma * (Math.PI / 180)))}</p>
            <p>z-rot: {(data.alpha > 180 ? (data.alpha - 360) : data.alpha) * ((Math.sin(Math.abs(data.gamma * (Math.PI / 180)))) - 1) + (data.beta + 90) * (Math.sin(Math.abs(data.gamma * (Math.PI / 180))))}</p>
            <p>tilt ROTATION : {data.rotation}</p>
            <button onClick={async () => { await DeviceOrientationEvent.requestPermission(); }}>Request permission</button>
        </div>
    );
};

export default GyroscopeComponent;
