import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { STATUS_COLORS } from 'utils/Constants'
import Particles from '../animations/Particles';

const Loader = () => {
    const loading = useSelector((state) => state.graph.loading);
    const [particlesState, setParticlesState] = useState({
        className: "particles",
        quantity: 70,
        staticity: 10,
        ease: 10,
        size: 0.2,
        refresh: false,
        color: "#ffffff",
        colorDesertion: 80,
        vx: 0.01,
        vy: -0.01,
        targetX: null, // New target positions
        targetY: null,
      });

    return (
        loading && (<div className="loader-overlay">
            <Particles {...particlesState} />
            <svg 
                xmlns="http://www.w3.org/2000/svg"
                width="30vh"
                height="30vh"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="absolute"
                aria-label="dna-loading"
                data-testid="dna-svg"
                aria-busy="true"
                role="progressbar"
                
            >
                {Array.from({ length: 20 }).map((_, index) => (
                    <circle key={index} cx={8 + index * 4}
                        style={{
                            filter: `drop-shadow(0px 0px 4px ${index % 2 === 0 ? STATUS_COLORS[3].firstColor : STATUS_COLORS[3].secondColor}77)`,
                            strokeWidth: "0.4px"
                        }} >
                        <animate attributeName="r" keyTimes="0;0.5;1" values="2.4;3.6;2.4" dur="2s" repeatCount="indefinite" begin={-index * 0.1 - (index % 2 === 0 ? 1 : 0)-0.5} />
                        <animate attributeName="cy" keyTimes="0;0.5;1" values="30.5;69.5;30.5" dur="2s" repeatCount="indefinite" begin={-index * 0.1 - (index % 2 === 0 ? 1 : 0)} keySplines="0.5 0 0.5 1;0.5 0 0.5 1" calcMode="spline" />
                        <animate attributeName="fill" keyTimes="0;0.5;1" values={`${index % 2 === 0 ? STATUS_COLORS[3].firstColor : STATUS_COLORS[3].secondColor}22;#33333311;${index % 2 === 0 ? STATUS_COLORS[3].firstColor : STATUS_COLORS[3].secondColor}22`} dur="2s" repeatCount="indefinite" begin={-index * 0.1 - (index % 2 === 0 ? 1 : 0)-1.5} />
                        <animate attributeName="stroke" keyTimes="0;0.5;1" values={`${index % 2 === 0 ? STATUS_COLORS[3].firstColor : STATUS_COLORS[3].secondColor};#33333322;${index % 2 === 0 ? STATUS_COLORS[3].firstColor : STATUS_COLORS[3].secondColor}`} dur="2s" repeatCount="indefinite" begin={-index * 0.1 - (index % 2 === 0 ? 1 : 0)-1.5} />
                    </circle>
                ))}
            </svg>
        </div>)
    );
};

export default Loader;
