import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { setPageData, updateNodesRequest, updateNodePageContentRequest } from '../../store/graphSlice';
import { STATUS_COLORS, STATUS_OPTIONS, DATE_OPTIONS } from 'utils/Constants'
import debounce from 'lodash.debounce';

import { useEditor, EditorContent, BubbleMenu, FloatingMenu, EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Placeholder from '@tiptap/extension-placeholder';
import { Color } from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';

const CardPage = ({
    graphData,
    getConnected,
    blockAllTargets,
    ...props
}) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const weightRef = useRef();
    const volumeRef = useRef();

    const pageData = useSelector(state => state.graph.pageData);
    const node = graphData?.nodes.find(node => node.id === pageData?.nodeId);
    const rect = pageData?.rect;

    const editor = useEditor({
        extensions: [
            StarterKit,
            Placeholder.configure({
              placeholder: 'Add something here...',
              showOnlyWhenEditable: true,
              emptyEditorClass: 'is-editor-empty',
            }),
            Underline,
        ],
        content: '',
        onSelectionUpdate: ({ editor }) => {
            const { from, to } = editor.state.selection;
            const hasSelection = from !== to;
            
            if (hasSelection) {
                const { top, left } = editor.view.coordsAtPos(from);
                setMenuPosition({ top, left });
                setMenuVisible(true);
            } else {
                setMenuVisible(false);
            }
          },
        });
    const dispatch = useDispatch();
    const [hoveredVolumeIndex, setHoveredVolumeIndex] = useState(null);
    const volumeLabelRef = useRef(null);

    const handleNodeFieldChange = (field, newValue) => {
        document.querySelectorAll('.node-card, .link-line').forEach(e => e.classList.remove('hide-blur'));
    
        const changes = [{ id: node.id, [field]: newValue }];
        
        if (field === 'status') {
          const targetNodes = getConnected(node.id, true, true, 0);
    
          targetNodes.forEach((targetNode) => {
            const childNodes = getConnected(targetNode.id, true, false, 0);
    
            const isBlocked = childNodes.some(child => child.id === node.id ? newValue !== 3 : child.status !== 3);
            if (isBlocked) blockAllTargets(targetNode.id);
            else if (targetNode.status !== 0) changes.push({ id: targetNode.id, status: 0 });
          });
    
        };
        dispatch(updateNodesRequest(changes));
      };

    const handleVolumeMouseMove = (e) => {
        const containerRect = e.currentTarget.getBoundingClientRect();
        const centerX = containerRect.width / 2;
        const centerY = containerRect.height / 2;

        const mouseX = e.clientX - containerRect.left - centerX;
        const mouseY = e.clientY - containerRect.top - centerY;

        // Визначення кута в радіанах
        let angle = Math.min(1.5, Math.max(0, Math.abs(Math.atan2(mouseY, mouseX) - Math.PI / 2)));
        // console.log(angle);
        angle = (angle +0.13)*0.9 ;
        
        // Перетворення кута на індекс (розподіл на 10 секцій)
        const index = Math.floor(angle / (Math.PI / 2) * 10) ; // Розподіл кута на чверті кола і на 10 секцій

        setHoveredVolumeIndex(index);  // Оновлюємо індекс рисочки

        if (volumeLabelRef.current) {
            volumeLabelRef.current.style.color = index < 5 ? '#11aa00' : index < 8 ? '#bb9a00' : '#bb0000';
            volumeLabelRef.current.style.opacity = '1';
            volumeLabelRef.current.textContent = `${index + 1}`;  // Оновлюємо текст
            // Позиціювання тексту можна налаштувати відповідно до обраної геометрії
            volumeLabelRef.current.style.top = `${90*Math.cos(-angle)}px`;
            volumeLabelRef.current.style.right = `${90*Math.sin(angle)}px`;
            volumeLabelRef.current.style.textShadow = `0 0 6px ${volumeLabelRef.current.style.color}`;
        }
    };

    const handleVolumeMouseLeave = () => {
        setHoveredVolumeIndex(null);  // Скидаємо індекс при знятті наведення
        if (volumeLabelRef.current) volumeLabelRef.current.style.opacity = '0';
    };

    useEffect(() => {
        if (node) {
            setHoveredVolumeIndex(-1);
            weightRef.current.innerText = 0;
            const animateVolume = (index, interval) => {
                if (index > node.volume) {
                    setHoveredVolumeIndex(null);
                    return;
                }
                volumeRef.current.innerText = index+1;
                setHoveredVolumeIndex(index);
                setTimeout(() => animateVolume(index + 1, interval + 100 / node.volume), interval);
            };
            const animateWeight = (current, interval, step) => {
                if (current > node.weight) return;
                weightRef.current.innerText = current;
                const slowdouwn = (current / node.weight) > 0.95;
                setTimeout(() => animateWeight(current + (slowdouwn ? 1 : Math.floor(Math.log(node.weight))), slowdouwn ? current*50 / node.weight : interval), interval);
            };
        
            // Запускаємо анімацію
            setTimeout(() => {
                animateVolume(0, 1);
                animateWeight(0, 1);
            }, 1000);
                
            // Перевіряємо, чи ініціалізований редактор
            if (!editor) return;

            editor.commands.setContent(node?.page_content);
            // Функція для обробки змін
            const handleEditorChange = (content, nodeId) => {
                const payload = {
                    id: nodeId,
                    content: content, // JSON-формат вмісту редактора
                };
                dispatch(updateNodePageContentRequest(payload)); // Замініть на відповідну Redux-дію
            };

            // Дебаунс функції
            const debouncedOnChange = debounce((content, nodeId) => {
                handleEditorChange(content, nodeId);
            }, 1000);

            // Підписка на подію 'update'
            const updateHandler = () => {
                const content = editor.getJSON();
                debouncedOnChange(content, node.id);
            };

            editor.on('update', updateHandler);

            // Очищення підписки при демонтажі компонента
            return () => {
                editor.off('update', updateHandler);
                debouncedOnChange.cancel(); // Очищення дебаунсу
            };
        }
    }, [editor, node]);

    return node && (
        <div
            className='card-page-layout'
            style={{
                '--card-top': `${rect?.top || 0}px`,
                '--card-left': `${rect?.left || 0}px`,
                '--card-width': `${rect?.width || 150}px`,
                '--card-height': `${rect?.height || 85}px`,
                "--neon-first-color": `${STATUS_COLORS[node.status].firstColor}`,
                "--neon-second-color": `${STATUS_COLORS[node.status].secondColor}`,
                "--neon-middle-first-color": `${STATUS_COLORS[node.status].firstColor}88`,
                "--neon-middle-second-color": `${STATUS_COLORS[node.status].secondColor}88`,
                "--neon-weak-first-color": `${STATUS_COLORS[node.status].firstColor}08`,
                "--neon-weak-second-color": `${STATUS_COLORS[node.status].secondColor}08`,
            }}
            onClick={() => {dispatch(setPageData(null))}}
        >            
            <button
                className="card-page-close-button"
                onClick={() => {dispatch(setPageData(null))}}
            >
                <i className="fa-solid fa-xmark"></i>
            </button>
            <div className="card-page-frame"></div>
            <div className="card-page"
                onClick={(e) => {e.stopPropagation()}}
            >
                <div
                    className='absolute rounded-full'
                    style={{
                        zIndex: -10,
                        transform: 'translate(calc(-50% + 7px), calc(-50% + 10px))',
                        width: `${Math.log(node.weight+10)*20}px`,
                        height: `${Math.log(node.weight+10)*20}px`,
                        background: `radial-gradient(circle, ${STATUS_COLORS[node.status].secondColor}66, ${STATUS_COLORS[node.status].firstColor}33)`,
                        // border: `1px solid ${STATUS_COLORS[node.status].firstColor}11`,
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                        boxShadow: `0px 0px 15px #000, inset 0px 0px ${Math.log(node.weight+10)*4}px #000`,
                        textShadow: '-1px -1px 1px #00000033, 1px 1px 1px #ffffff33',
                        opacity: 0,
                        animation: 'fadeIn 0.6s ease forwards 0.5s',
                    }}
                >
                </div>
                <div
                    ref={weightRef}
                    className='absolute'
                    style={{
                        top: `${Math.max(0.7,Math.log(node.weight+10)/10)}%`,
                        left: '1%',
                        fontSize: `${Math.max(16, Math.log(node.weight+10)*4)}px`,
                        fontWeight: 600,
                        color: `${STATUS_COLORS[node.status].firstColor}22`,
                        textShadow: '-1px 0px 1px #00000033, 0px 1px 1px #ffffff33',
                        pointerEvents: 'none',
                        opacity: 0,
                        animation: 'fadeIn 0.6s ease forwards 0.5s',
                    }}
                />

                {/* Контейнер об'єму чвертькруга */}
                <div ref={volumeLabelRef} className="absolute font-bold"></div>
                <div className="absolute volume-quarter-circle-wrapper top-0 right-0 rounded-full"
                    style={{
                        // border: '2px solid white',
                        width: '175px',
                        height: '175px',
                        transform: 'translate(48%, -48%)',
                        pointerEvents:'auto',
                        opacity: 0,
                        animation: 'fadeIn 0.6s ease forwards 0.5s',
                    }}
                    onMouseMove={handleVolumeMouseMove}
                    onMouseLeave={handleVolumeMouseLeave}
                    onClick={() => handleNodeFieldChange('volume', hoveredVolumeIndex)}
                >
                    <div className='relative rounded-full'
                        style={{
                            top: 0,
                            right: 0,
                            transform: 'translate(31px, 24px)',
                            width: '120px',
                            height: '120px',
                            opacity: 1,
                            boxShadow: 'inset 0px 0px 25px #000000, 0px 0px 10px #ffffff77',
                            // boxShadow: '0px 0px 5px ' + (node.volume < 5 ? '#55aa00' : node.volume < 8 ? '#bb9a00' : '#bb0000'),
                            border: `2px solid ${STATUS_COLORS[node.status].secondColor}44`,
                            background: `radial-gradient(circle, ${STATUS_COLORS[node.status].firstColor}11, ${STATUS_COLORS[node.status].secondColor}44)`,
                            // background: node.volume < 5 
                            // ? `radial-gradient(circle, #55aa00), ${STATUS_COLORS[node.status].secondColor}99` 
                            // : node.volume < 8 
                            //     ? `radial-gradient(circle, #bb9a00, ${STATUS_COLORS[node.status].secondColor}99)` 
                            //     : `radial-gradient(circle, #bb0000, ${STATUS_COLORS[node.status].secondColor}99)`,
                        }}
                    ></div>
                    <svg
                        viewBox="0 0 100 100"
                        style={{
                            position: 'absolute',
                            width: '170px',
                            height: '170px',
                            top: 0,
                            right: 0,
                            transform: 'rotate(90deg)', // обертання, щоб починати з кута
                            opacity: 0.8,
                        }}
                    >
                        <defs>
                            {/* Визначаємо фільтр для тіні */}
                            <filter id="greenglow" x="-50%" y="-50%" width="200%" height="200%">
                                <feDropShadow dx="0" dy="0" stdDeviation="1" floodColor='#55aa00'/>
                            </filter>
                            <filter id="yellowglow" x="-50%" y="-50%" width="200%" height="200%">
                                <feDropShadow dx="0" dy="0" stdDeviation="1" floodColor= '#bb9a00' />
                            </filter>
                            <filter id="redglow" x="-50%" y="-50%" width="200%" height="200%">
                                <feDropShadow dx="0" dy="0" stdDeviation="1" floodColor= '#bb0000'/>
                            </filter>
                        </defs>
                    {Array.from({ length: 10 }).map((_, index) => {
                        const angle = -(Math.PI / 2) * (index / 9); // розділення на 10 секцій (чверть кола)
                        const barLength = index === hoveredVolumeIndex ? 43 : hoveredVolumeIndex && hoveredVolumeIndex - index === 1 ? 42 : 41;

                        // const x1 = 50 + 35 * Math.cos(angle); // координати для дуги
                        // const y1 = 50 - 35 * Math.sin(angle);
                        // const x2 = 50 + barLength * Math.cos(angle); // координати для дуги
                        // const y2 = 50 - barLength * Math.sin(angle);
                        const startWidth = 0.08;
                        const endWidth = startWidth/Math.log(barLength)*3.7;

                        const startX1 = 50 + 35 * Math.cos(angle+startWidth);
                        const startY1 = 50 - 35 * Math.sin(angle+startWidth);
                        const startX2 = 50 + 35 * Math.cos(angle-startWidth);
                        const startY2 = 50 - 35 * Math.sin(angle-startWidth);

                        const endX1 = 50 + barLength * Math.cos(angle+endWidth);
                        const endY1 = 50 - barLength * Math.sin(angle+endWidth);
                        const endX2 = 50 + barLength * Math.cos(angle-endWidth);
                        const endY2 = 50 - barLength * Math.sin(angle-endWidth);

                        const roundX = 50 + barLength*1.002 * Math.cos(angle);
                        const roundY = 50 - barLength*1.002 * Math.sin(angle);
                        return (
                            <polygon
                                key={index}
                                points={`${startX1},${startY1} ${startX2},${startY2} ${endX2},${endY2} ${roundX},${roundY} ${endX1},${endY1}`}
                                fill={index < 5 ? '#11aa00' : index < 8 ? '#bb9a00' : '#bb0000'}
                                opacity={(hoveredVolumeIndex === null && index <= node.volume) || index <= hoveredVolumeIndex ? 1 : 0.3}
                                // fill={index < 5 ? 'linear-gradient(-155deg, #55aa00, #11aa00)' : index < 8 ? 'linear-gradient(-155deg, #bb9a00, #7a5e00)' : 'linear-gradient(-155deg, #bb0000, #660000)'}
                                filter={(hoveredVolumeIndex === null && index <= node.volume) || index <= hoveredVolumeIndex ? 
                                    (index < 5 ? "url(#greenglow)" : index < 8 ? 'url(#yellowglow)' : 'url(#redglow)')
                                    : ""}
                            />
                        )})}
                    </svg>
                </div>
                <span
                    ref={volumeRef}
                    className='absolute'
                    style={{
                        top: '0%',
                        right: node.volume === 9 ? '1%' : '2%',
                        fontWeight: 400,
                        fontSize: 30,
                        //color: node.volume < 5 ? '#337700' : node.volume < 8 ? '#997700' : '#9a0000',
                        color: '#ffffff99',
                        // textShadow: '0px -1px 1px #00000044, 0px 1px 1px #ffffff44',
                        textShadow: `0px 0px 6px ${STATUS_COLORS[node.status].secondColor}88`,
                        pointerEvents: 'none',
                        opacity: 0,
                        animation: 'fadeIn 0.6s ease forwards 0.6s',
                    }}
                />
                <div className="status-container"
                    style={{
                        display: 'flex',
                        justifyContent: 'center', // Центрування всіх статусів по горизонталі
                        alignItems: 'center', // Центрування по вертикалі
                        gap: '12px', // Відстань між статусами
                        width: '100%', // Можна встановити ширину на всю доступну область
                    }}
                >
                    {node.status === 2 ? 
                        <div
                        key={2}
                        style={{
                            top: '-2px',
                            color: STATUS_COLORS[2].secondColor,
                            background: `linear-gradient(15deg, ${STATUS_COLORS[2].firstColor}44, ${STATUS_COLORS[2].secondColor}44)`,
                            border: `2px solid ${STATUS_COLORS[2].firstColor}55`,
                            borderTop: 'none',
                            fontSize: 18, // Зменшення шрифту для компактності
                            padding: '6px 12px', // Додатковий відступ
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 'fit-content',
                            height: 'fit-content',
                            boxSizing: 'border-box',
                            borderRadius: '0 0 14px 14px',
                            cursor: 'default',
                            boxShadow: `0 0 20px 0px ${STATUS_COLORS[2].secondColor}55`, // Підсвічування активного статусу
                        }}
                    >
                        {STATUS_OPTIONS[2]}
                    </div>
                    :
                    Object.keys(STATUS_OPTIONS).filter(k => Number(k) !== 2).map(statusKey => (
                    <div
                        key={statusKey}
                        style={{
                            color: STATUS_COLORS[statusKey].secondColor,
                            background: node.status === statusKey 
                                ? `linear-gradient(15deg, ${STATUS_COLORS[statusKey].firstColor}55, ${STATUS_COLORS[statusKey].secondColor}55)` 
                                : `linear-gradient(15deg, ${STATUS_COLORS[statusKey].firstColor}22, ${STATUS_COLORS[statusKey].secondColor}22)`,
                            border: `2px solid ${STATUS_COLORS[statusKey].firstColor}55`,
                            borderTop: 'none',
                            fontSize: 18, // Зменшення шрифту для компактності
                            padding: '6px 12px', // Додатковий відступ
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 'fit-content',
                            height: 'fit-content',
                            boxSizing: 'border-box',
                            borderRadius: '0 0 14px 14px',
                            boxShadow: node.status === Number(statusKey) ? `0 0 20px 0px ${STATUS_COLORS[statusKey].secondColor}aa` : 'none', // Підсвічування активного статусу
                            opacity: node.status === Number(statusKey) ? 1 : 0.2, // Зменшення видимості для неактивних статусів
                            cursor: node.status === Number(statusKey) ? 'default' : 'pointer',
                            transition: 'opacity 0.2s ease',
                        }}
                        onMouseEnter={(e) => {
                            if (Number(statusKey) !== node.status) e.currentTarget.style.opacity = 1; // Повертає повну прозорість
                        }}
                        onMouseLeave={(e) => {
                            if (Number(statusKey) !== node.status) e.currentTarget.style.opacity = 0.2; // Повертає попередню прозорість
                        }}
                        onClick={() => {
                            // Додаткова логіка для зміни статусу при натисканні (за потреби)
                            if (node.status !== 2) handleNodeFieldChange('status', Number(statusKey));
                        }}
                    >
                        {STATUS_OPTIONS[statusKey]}
                    </div>)
                    )}
                    {/* Created time */}
                    <div className="absolute"
                    style={{
                        bottom: '15px',
                        left: '15px',
                        color: STATUS_COLORS[node.status].secondColor,
                        //background: `linear-gradient(35deg, ${STATUS_COLORS[node.status].firstColor}44, ${STATUS_COLORS[node.status].secondColor}44)`,
                        borderColor: `${STATUS_COLORS[node.status].firstColor}55`,
                        fontSize: 12,
                        opacity: 0.2,
                        textAlign: 'right',
                        }}
                    >
                        <strong>Edited:</strong> {new Date(node.last_edited_time).toLocaleDateString(navigator.language, DATE_OPTIONS).replaceAll('/', '.').replace(',', '')}<br/>
                        <strong>Created:</strong> {new Date(node.created_time).toLocaleDateString(navigator.language, DATE_OPTIONS).replaceAll('/', '.').replace(',', '')}
                    </div>
                </div>
                <h1
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                        e.preventDefault();  // Щоб уникнути перенесення рядка
                        e.target.blur();
                        //handleEnterPress(e.target.innerText.trim());  // Викликаємо функцію для обробки натискання Enter
                        }
                        e.stopPropagation();
                    }}  // Prevent keydown propagation
                    onKeyUp={(e) => {e.stopPropagation()}}      // Prevent keyup propagation
                    onBlur={(e) => {
                        e.target.textContent = e.target.textContent.replace(/\u200B/g, "");
                        if (!e.target.textContent.trim()) {
                        e.target.textContent = 'Untitled';  // Повертається псевдотекст, якщо поле залишилось порожнім
                        e.target.style.color = 'grey';
                        } else {
                        e.target.style.color = 'white';
                        handleNodeFieldChange('title', e.target.textContent.trim());  // Оновлює значення при втраті фокусу
                        }
                    }}
                    // onInput={e =>
                    //   console.log(e.target.textContent)
                    // }
                    onFocus={(e) => {
                        e.stopPropagation();
                        if (e.target.style.color === 'grey') {
                        e.target.textContent = "\u200B";
                        }
                        e.target.style.color = 'white';
                    }}
                    contentEditable
                    suppressContentEditableWarning
                    data-full-text={node.title}
                    className="font-bold text-left relative top-8 left-10"
                    style={{
                        position: 'relative',
                        fontSize: 36,
                        width: '90%',
                        cursor: 'text',
                        color: node.title ? 'white' : 'grey',
                        lineHeight: 1.2,
                        maxHeight: '3.6em', /* 3 рядки * lineHeight (1.2) */
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        outline: 'none',
                        marginBottom: '16px',
                    }}
                    >
                        {node.title ? node.title : "Untitled"}
                    </h1>
                    <div
                        className='properties-container relative top-8 left-10'
                        style={{

                        }}
                    >
                        Properties here
                    </div>

                    {editor && (
                    <>
                    <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
                        <button onClick={e => {e.stopPropagation(); editor.chain().focus().toggleBold().run()}}>
                            <svg viewBox="0 0 16 16"><g><path d="M5.067 13.014c-.772 0-1.223-.451-1.223-1.238V4.36c0-.786.45-1.237 1.223-1.237h3.5c1.949 0 3.131.943 3.131 2.488 0 1.1-.793 1.97-1.948 2.12v.061c1.401.068 2.4 1.046 2.4 2.386 0 1.716-1.347 2.837-3.466 2.837H5.067zm1.238-5.81h1.34c1.025 0 1.633-.459 1.633-1.225 0-.738-.553-1.168-1.483-1.168h-1.49v2.392zm0 4.142h1.6c1.148 0 1.742-.472 1.742-1.347 0-.854-.642-1.32-1.804-1.32H6.305v2.667z"></path></g></svg>
                        </button>
                        <button onClick={e => {e.stopPropagation(); editor.chain().focus().toggleItalic().run()}}>
                            <svg viewBox="0 0 16 16"><g><path d="M5.02 13.014c-.397 0-.67-.24-.67-.63 0-.382.266-.614.663-.614H6.42l1.593-7.404H6.612c-.396 0-.67-.239-.67-.629 0-.383.267-.615.663-.615h4.382c.397 0 .663.232.663.615 0 .39-.273.63-.67.63H9.586l-1.6 7.403h1.409c.396 0 .663.232.663.615 0 .39-.274.629-.67.629H5.02z"></path></g></svg>
                        </button>
                        <button onClick={e => {e.stopPropagation(); editor.chain().focus().toggleUnderline().run()}}>
                            <svg viewBox="0 0 16 16"><g><path d="M8 13.191c-.843 0-1.572-.152-2.188-.458a3.46 3.46 0 01-1.421-1.292c-.333-.551-.5-1.191-.5-1.92V3.724c0-.242.071-.431.213-.568.14-.141.33-.212.567-.212.246 0 .44.071.58.212.147.137.22.326.22.568v5.653c0 .488.098.914.294 1.278.2.36.49.64.868.841.378.2.834.3 1.367.3.798 0 1.417-.22 1.86-.662.446-.442.67-1.028.67-1.757V3.724c0-.242.07-.431.211-.568.142-.141.33-.212.568-.212.246 0 .44.071.58.212.147.137.22.326.22.568V9.52c0 .729-.167 1.369-.5 1.92-.332.552-.809.982-1.428 1.292-.616.306-1.342.458-2.181.458zm-3.623 2.174a.487.487 0 01-.349-.136.464.464 0 01-.136-.342c0-.137.045-.25.136-.342a.487.487 0 01.349-.137h7.24c.14 0 .257.046.348.137a.453.453 0 01.143.342c0 .136-.048.25-.143.341a.472.472 0 01-.349.137H4.377z"></path></g></svg>
                        </button>
                        <button onClick={e => {e.stopPropagation(); editor.chain().focus().toggleStrike().run()}}>
                            <svg viewBox="0 0 16 16"><g><path d="M7.973 13.191a5.695 5.695 0 01-1.75-.253 3.694 3.694 0 01-1.306-.71 2.376 2.376 0 01-.71-1.101 1.514 1.514 0 01-.062-.424c0-.205.061-.369.184-.492a.71.71 0 01.513-.185c.155 0 .28.034.376.103a.92.92 0 01.273.328c.128.296.31.554.547.772.237.215.52.379.848.493.328.114.69.17 1.087.17.45 0 .847-.066 1.19-.198.345-.136.614-.321.806-.553.196-.237.294-.506.294-.807 0-.383-.15-.688-.451-.916-.301-.232-.796-.43-1.484-.595L7.043 8.53c-.939-.21-1.636-.528-2.092-.957-.451-.433-.677-.998-.677-1.695 0-.574.158-1.082.472-1.524.32-.443.757-.787 1.313-1.033.556-.25 1.191-.376 1.907-.376.601 0 1.148.09 1.64.267.497.173.91.415 1.238.725.328.31.547.67.656 1.08a.82.82 0 01.034.177c.01.055.014.11.014.164a.625.625 0 01-.185.472c-.123.119-.29.178-.499.178-.287 0-.506-.132-.656-.397a2.244 2.244 0 00-.506-.745 1.98 1.98 0 00-.752-.458 2.897 2.897 0 00-.984-.157c-.406 0-.766.064-1.08.191a1.742 1.742 0 00-.745.527 1.233 1.233 0 00-.267.78c0 .36.148.648.444.867.301.214.784.399 1.45.554l1.169.267c1.016.227 1.756.553 2.221.977.47.42.704.998.704 1.736 0 .611-.162 1.147-.485 1.607-.32.456-.77.809-1.354 1.06-.578.25-1.262.375-2.05.375zM2.552 8.386a.363.363 0 01-.267-.11.378.378 0 01-.11-.266c0-.1.037-.187.11-.26a.352.352 0 01.267-.116h10.89c.104 0 .193.039.266.116.073.073.11.16.11.26s-.037.189-.11.266a.363.363 0 01-.267.11H2.551z"></path></g></svg>
                        </button>
                        <button onClick={e => {e.stopPropagation(); editor.chain().focus().toggleCode().run()}}>
                            <svg viewBox="0 0 16 16"><g><path d="M.056 8.122a.72.72 0 01.07-.306.91.91 0 01.204-.285l3.39-3.05a.745.745 0 01.542-.215.675.675 0 01.505.236c.14.158.202.337.188.537a.737.737 0 01-.264.51L1.855 8.122l2.836 2.573a.737.737 0 01.264.51.716.716 0 01-.188.537.694.694 0 01-.505.242.766.766 0 01-.543-.22L.33 8.708a.87.87 0 01-.204-.274.756.756 0 01-.07-.312zm6.43 5.21a.675.675 0 01-.446-.355.782.782 0 01-.027-.58l2.605-8.942c.068-.226.186-.384.354-.473a.714.714 0 01.559-.054.678.678 0 01.446.344.69.69 0 01.048.553l-2.621 8.98a.738.738 0 01-.35.463.711.711 0 01-.569.064zm9.453-5.21a.695.695 0 01-.065.312.869.869 0 01-.204.274l-3.39 3.056a.773.773 0 01-.547.22.677.677 0 01-.5-.242.707.707 0 01-.193-.537.738.738 0 01.263-.51l2.841-2.573-2.84-2.573a.738.738 0 01-.264-.51.7.7 0 01.193-.537.659.659 0 01.5-.236.75.75 0 01.548.214l3.389 3.051a.91.91 0 01.204.285c.047.1.068.202.064.306z"></path></g></svg>
                        </button>
                        <button onClick={e => {e.stopPropagation(); editor.chain().focus().setLink({ href: prompt('Enter URL') }).run();}}>
                            <svg viewBox="0 0 16 16" ><path d="M7.69922 10.8945L8.73828 9.84863C7.91797 9.77344 7.34375 9.51367 6.91992 9.08984C5.76465 7.93457 5.76465 6.29395 6.91309 5.14551L9.18262 2.87598C10.3379 1.7207 11.9717 1.7207 13.127 2.87598C14.2891 4.04492 14.2822 5.67188 13.1338 6.82031L11.958 7.99609C12.1768 8.49512 12.2451 9.10352 12.1289 9.62988L14.0908 7.6748C15.7725 6 15.7793 3.62109 14.084 1.92578C12.3887 0.223633 10.0098 0.237305 8.33496 1.91211L5.95605 4.29785C4.28125 5.97266 4.26758 8.35156 5.96289 10.0469C6.36621 10.4434 6.90625 10.7441 7.69922 10.8945ZM8.30078 5.13184L7.26855 6.17773C8.08203 6.25293 8.66309 6.51953 9.08008 6.93652C10.2422 8.09863 10.2422 9.73242 9.08691 10.8809L6.81738 13.1504C5.66211 14.3057 4.03516 14.3057 2.87305 13.1504C1.71094 11.9883 1.71777 10.3545 2.87305 9.20605L4.04199 8.03027C3.83008 7.53125 3.75488 6.92969 3.87109 6.39648L1.91602 8.35156C0.234375 10.0264 0.227539 12.4121 1.92285 14.1074C3.61816 15.8027 5.99707 15.7891 7.67188 14.1143L10.0439 11.7354C11.7256 10.0537 11.7324 7.6748 10.0371 5.98633C9.64062 5.58301 9.10059 5.28223 8.30078 5.13184Z"></path></svg>
                        </button>
                    </BubbleMenu>
                    <FloatingMenu className="floating-menu" tippyOptions={{ duration: 100 }} editor={editor}>
                        <button
                            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                        >
                            H1
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                        >
                            H2
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleBulletList().run()}
                            className={editor.isActive('bulletList') ? 'is-active' : ''}
                        >
                            Bullet list
                        </button>
                    </FloatingMenu>
                    <EditorContent editor={editor} className='relative top-20 left-10 max-w-[90%] max-h-[70%]'
                        onKeyUp={e => e.stopPropagation()}
                        onKeyDown={e => e.stopPropagation()}
                    />
                    </>
                )}
                    <div>
                </div>
            </div>
        </div>);
}

export default CardPage;