import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextureLoader } from 'three';

export const GLTF_LOADER = new GLTFLoader();
export const TEXTURE_LOADER = new TextureLoader();

export const STATUS_COLORS = {
    0: { firstColor: "#fcda00", secondColor: "#fcf400" }, // Може бути виконана
    1: { firstColor: "#00ff99", secondColor: "#00ffff" }, // Виконується
    2: { firstColor: "#ff0000", secondColor: "#ffa400" }, // Заблокована
    3: { firstColor: "#009dff", secondColor: "#9900ff" }, // Виконана
};

export const STATUS_OPTIONS = [
    "Available",
    "In Progress",
    "Blocked",
    "Completed",
];


export const DATE_OPTIONS = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };

export const IS_MOBILE = window.innerWidth < 768;
  
export const LINK_STATUS_COLORS = [
    "#cc9900", 
    "#009933", 
    "#ff6600", 
    "#6633ff",
    '#777777'
];
  
export const NODE_STATUS_COLORS = {
    0: "#996600fe", 
    1: "#009933fe", 
    2: "#991911fe", 
    3: "#4444bbfe", 
    null: '#808080',
};
  
export const FAR_STATUS_SECONDARY_COLOR = {
    0: "#968300f0", 
    1: "#00568ff0", 
    2: "#913a00f0", 
    3: "#008f87f0", 
    null: '#808080',
};

export const TYPE_TEXT = {
    0: "Dream",
    1: "Global Goal",
    2: "Process",
    3: "Task",
    4: "Skill",
    5: "Tool",
    6: "Technology",
    7: "Resource",
    8: "Resource Source",
    null: "Undefined",
}

export const NODE_PROPS_TO_UPDATE = [
    "volume",
];

export const LINK_PROPS_TO_UPDATE = ["type"];

export const BLOOM_SCENE = 1;

export const LINK_LENGTH = 80;

export const USE_AUDIO = false;
export const SHOW_STARS = true;

export const TITLE_MAX_CHARS = 60;

export const UNACTIVE_THRESHOLD = 300000;

export const [STARS_VERTEX_SHADER, STARS_FRAGMENT_SHADER] = await Promise.all([
    fetch('/shaders/stars.vert').then(res => res.text()),
    fetch('/shaders/stars.frag').then(res => res.text())
]);

// Raycaster IDs

// export const OBJ_MOD                        = 1;
export const RC_CLASS_MOD = 100;
export let RC_CLASS_COUNT = 0
export const RC_SUB_CLASS_MOD = 10;

export const RC_CORE = RC_CLASS_MOD * ++RC_CLASS_COUNT;
export const RC_CARD = RC_CLASS_MOD * ++RC_CLASS_COUNT; export const RC_NODE = RC_CARD;
let RC_CARD_SUBCLASS_COUNT = 0;

    export const RC_CARD_SETTINGS = RC_CARD + RC_SUB_CLASS_MOD * ++RC_CARD_SUBCLASS_COUNT;
    let RC_CARD_SETTINGS_TOOLS_COUNT = 0;

        export const RC_CARD_SETTINGS_TRASH = RC_CARD_SETTINGS + ++RC_CARD_SETTINGS_TOOLS_COUNT;
        export const RC_CARD_SETTINGS_SHARE = RC_CARD_SETTINGS + ++RC_CARD_SETTINGS_TOOLS_COUNT;
        export const RC_CARD_SETTINGS_COLLAPSE = RC_CARD_SETTINGS + ++RC_CARD_SETTINGS_TOOLS_COUNT;

    export const RC_CARD_VOLUME = RC_CARD + RC_SUB_CLASS_MOD * ++RC_CARD_SUBCLASS_COUNT;

    export const RC_CARD_STATUS = RC_CARD + RC_SUB_CLASS_MOD * ++RC_CARD_SUBCLASS_COUNT;
    //let RC_CARD_STATUS_BUTTONS_COUNT = STATUS_OPTIONS.length - 1;

    export const RC_CARD_SUBTASK_BUTTON = RC_CARD + RC_SUB_CLASS_MOD * ++RC_CARD_SUBCLASS_COUNT;

    export const RC_CARD_CORE = RC_CARD + RC_SUB_CLASS_MOD * ++RC_CARD_SUBCLASS_COUNT;
    let RC_CARD_CONNECTIONS_TOOLS_COUNT = 0;

        export const RC_CARD_CONNECTIONS_SUPER = RC_CARD_CORE + ++RC_CARD_CONNECTIONS_TOOLS_COUNT;
        export const RC_CARD_CONNECTIONS_SUB = RC_CARD_CORE + ++RC_CARD_CONNECTIONS_TOOLS_COUNT;

    export const RC_CARD_PIN = RC_CARD + RC_SUB_CLASS_MOD * ++RC_CARD_SUBCLASS_COUNT;


export const RC_LINK = RC_CLASS_MOD * ++RC_CLASS_COUNT;
let RC_LINK_SUBCLASS_COUNT = 0;

    export const RC_LINK_SETTINGS = RC_LINK + RC_SUB_CLASS_MOD * ++RC_LINK_SUBCLASS_COUNT;
    let RC_LINK_SETTINGS_TOOLS_COUNT = 0;

        export const RC_LINK_SETTINGS_SCISSORS = RC_LINK_SETTINGS + ++RC_LINK_SETTINGS_TOOLS_COUNT;
        export const RC_LINK_SETTINGS_CHANGE_TYPE_BUTTON = RC_LINK_SETTINGS + ++RC_LINK_SETTINGS_TOOLS_COUNT;
        export const RC_LINK_SETTINGS_REVERSE_BUTTON = RC_LINK_SETTINGS + ++RC_LINK_SETTINGS_TOOLS_COUNT;
        export const RC_LINK_SETTINGS_NEW_NODE_BUTTON = RC_LINK_SETTINGS + ++RC_LINK_SETTINGS_TOOLS_COUNT;


export const CARD_PROPERTIES = {
    width: 10,
    height: 3,
    depth: 0.7,
    borderRadius: 0.7,
    holeRadius: 1,
    x: -1.5,
    y: -1.5,
    padding: 0.2,
    titleX: 1.3,
    titleY: 1.3,
    titleFontSize: 0.5,
    titleColor: '#ffffff',
    untitledColor: '#303030',
    titleMaxWidth: 6,
    titleFont: 'fonts/Roboto-Bold.ttf',
    font: 'fonts/Roboto-Regular.ttf',
    textLineHeight: 1.2,

    }
    