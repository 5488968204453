import React, { useState } from 'react';
import Particles from '../animations/Particles';
import { v4 as uuidv4 } from 'uuid';
import '../../styles/initial_node.css';
import { useDispatch } from 'react-redux';
import { addNodeRequest } from '../../store/graphSlice';

function InitialNodeLayout({ setIsFirstNode }) {
  const dispatch = useDispatch()
  const [startDestroy, setStartDestroy] = useState(false);
  const [particlesState, setParticlesState] = useState({
    className: "particles",
    quantity: 180,
    staticity: 10,
    ease: 50,
    size: 1,
    refresh: false,
    color: "#ffffff",
    colorDesertion: 80,
    vx: 0.1,
    vy: -0.1,
    targetX: null, // New target positions
    targetY: null,
  });

  const handleClick = async () => {
    setParticlesState(prev => ({
      ...prev,
      animateBlow: true
    }));
    setTimeout(() => {
      setParticlesState(prev => ({
        ...prev,
        destroy: true,
        animateBlow: false,
      }));
    }, 1000);
    
    setTimeout(() => {
      setStartDestroy(true);
      setParticlesState(prev => ({
        ...prev,
        animateBlow: false,
      }));
    }, 1150);
    
    setTimeout(() => {
      setIsFirstNode(false);
      dispatch(addNodeRequest({ id: uuidv4(), volume: 5, status: 0 }));
    }, 2500);
  };

  return (
    <div className={`initial-node ${startDestroy ? 'start-destroy' : ''}`}>
      <Particles {...particlesState} />
      <div className='initial-node-center'>
        <p>Create your first Card</p>
        <button
          onClick={handleClick}
          className='initial-node-button'>
          <span>Boom!</span>
        </button>
      </div>
    </div>
  );
}

export default InitialNodeLayout;