import { eventChannel } from 'redux-saga';
import { call, put, take, fork, takeEvery } from 'redux-saga/effects';
import { io } from 'socket.io-client';
import { 
  setGraphData,
  nodeRemoteUpdate,
  linkRemoteUpdate,
  updateNodesRequest,
  updateLinkRequest,
  deleteRequest,
  enqueueChange,
  clearSyncQueue,
  setOffline,
  addNodeRequest,
  addLinkRequest,
  turnOnLoading,
  turnOffLoading,
  updateCoordinatesRequest,
  updateNodePageContentRequest,
} from '../store/graphSlice';

function createSocketChannel(socket) {
  return eventChannel(emit => {
    socket.emit('synaptask');
    
    socket.on('nodeUpdated', (updatedNode) => {
      emit(nodeRemoteUpdate(updatedNode));
    });

    socket.on('linkUpdated', (updatedLink) => {
      emit(linkRemoteUpdate(updatedLink));
    });

    socket.on('synaptask_data', (data) => {
      emit(setGraphData(data));
      setTimeout(() => {
        emit(turnOffLoading());
      }, 1);
    });

    return () => {
      socket.off('nodeUpdated');
      socket.off('linkUpdated');
      socket.off('synaptask_data');
    };
  });
}

function* syncQueueWithServer(socket) {
  const queue = JSON.parse(localStorage.getItem('syncQueue')) || [];

  for (const item of queue) {
    const { id, changes, type } = item;
    if (type === 'node') {
      socket.emit('updateNode', { id, ...changes });
    } else if (type === 'link') {
      socket.emit('updateLink', { id, ...changes });
    }
  }
  
  yield put(clearSyncQueue());  // Очищаємо чергу після синхронізації
}

function* addNode(socket, action) {
  if (navigator.onLine) {
    socket.emit('addNode', action.payload);
  } else {
    yield put(enqueueChange({type: 'add_node', changes: action.payload }));
  }
}

function* addLink(socket, action) {
  if (navigator.onLine) {
    socket.emit('addLink', action.payload);
  } else {
    yield put(enqueueChange({ type: 'add_link', changes: action.payload }));
  }
}

function* deleteData(socket, action) {
  if (navigator.onLine) {
    socket.emit('deleteData', action.payload);
  } else {
    yield put(enqueueChange({ type: 'delete_data', changes: action.payload }));
  }
}

function* updateCoordinates(socket, action) {
  const { nodes } = action.payload;
  
  if (navigator.onLine) {
    socket.emit('updateCoordinates', nodes);
  } else {
    yield put(enqueueChange({
      type: 'updateCoordinates',
      payload: { nodes },
    }));
  }
}

function* nodeUpdate(socket, action) {
  if (navigator.onLine) {
    socket.emit('updateNodes', action.payload);
  } else {
    yield put(enqueueChange({ type: 'node', ...action.payload }));
  }
}

function* linkUpdate(socket, action) {
  if (navigator.onLine) {
    socket.emit('updateLink', action.payload);
  } else {
    yield put(enqueueChange({ type: 'link', ...action.payload }));
  }
}

function* updateNodePageContent(socket, action) {
  if (navigator.onLine) {
    socket.emit('updateNodePageContent', action.payload);
  } else {
    yield put(enqueueChange({ type: 'node_page', ...action.payload }));
  }
}

function* handleReconnect(socket) {
  console.log("Відновлено з'єднання. Синхронізуємо дані...");
  yield call(syncQueueWithServer, socket);
}

function* watchGraphUpdates(socket) {
  yield takeEvery(updateNodesRequest.type, function* (action) {
    yield call(nodeUpdate, socket, action);
  });

  yield takeEvery(updateLinkRequest.type, function* (action) {
    yield call(linkUpdate, socket, action);
  });

  yield takeEvery(addNodeRequest.type, function* (action) {
    yield call(addNode, socket, action);
  });

  yield takeEvery(addLinkRequest.type, function* (action) {
    yield call(addLink, socket, action);
  });

  yield takeEvery(deleteRequest.type, function* (action) {
    yield call(deleteData, socket, action);
  });

  yield takeEvery(updateCoordinatesRequest.type, function* (action) {
    yield call(updateCoordinates, socket, action);
  });

  yield takeEvery(updateNodePageContentRequest.type, function* (action) {
    yield call(updateNodePageContent, socket, action);
  });

  window.addEventListener('online', function () {
    put(setOffline(false));  // Повернулися онлайн
    put(handleReconnect(socket));  // Синхронізуємо
  });

  window.addEventListener('offline', function () {
    put(setOffline(true));  // Перейшли в офлайн
  });
}



function* socketSaga() {
  if (process.env.REACT_APP_DEBUG === "true") {
    localStorage.setItem('debug', '*');
  }
  let socket;

  const token = localStorage.getItem('token');

  if (!token) {
    console.warn("Token is missing. WebSocket connection not established.");
    return;
  }

  yield put(turnOnLoading());

  // yield call(socketSaga);
  socket = io(process.env.REACT_APP_SOCKET_URL, {
    withCredentials: true,
    query: {
      Authorization: token,
    },
    transports: ['websocket', 'polling'],
  });

  const socketChannel = yield call(createSocketChannel, socket);

  yield fork(watchGraphUpdates, socket);
  // yield fork(watchCoordinateUpdates, socket);

  while (true) {
    const action = yield take(socketChannel);
    yield put(action); 
  }
}

export default socketSaga;
