import * as THREE from 'three';

export function SeededRandom(seed) {
    return Math.random();
}

export const isAuthenticated = () => {
    return !!localStorage.getItem('token');
};

export const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export function splitTitleToLines (text, maxCharsPerLine) {
    const words = text.split(' ');
    const textLines = [];
    let currentLine = '';

    words.forEach(word => {
        const testLine = currentLine + (currentLine ? ' ' : '') + word;
        if (testLine.length > maxCharsPerLine) {
        textLines.push(currentLine);
        currentLine = word;
        } else {
        currentLine = testLine;
        }
    });

    if (currentLine) {
        textLines.push(currentLine);
    }
    return textLines;
};

export function createGradientTexture(width, height) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Налаштування розмірів Canvas
    canvas.width = width;
    canvas.height = height;

    // Створюємо градієнт під кутом 135 градусів
    const gradient = context.createLinearGradient(0,  height*0.1, width/3, height*1.45);
    gradient.addColorStop(0, '#0a0e20'); // Перший колір
    gradient.addColorStop(0.5, '#1b141c'); // Середній колір
    gradient.addColorStop(1, '#081319'); // Останній колір
// 
    context.fillStyle = gradient;
    context.fillRect(0, 0, width, height);

    
    const texture = new THREE.CanvasTexture(canvas);
    texture.colorSpace = THREE.SRGBColorSpace;

    return texture;
  }