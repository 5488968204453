"use client";

import React, { useEffect, useRef, useState } from "react";
import { SeededRandom } from "../../utils/Helpers";

const randomRGBGenerator = (rgb, colorDesertion) => {
    return [
        rgb[0] - Math.floor(Math.random() * colorDesertion),
        rgb[1] - Math.floor(Math.random() * colorDesertion),
        rgb[2] - Math.floor(Math.random() * colorDesertion),
    ];
};

function MousePosition() {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (event) => {
            setMousePosition({ x: event.clientX, y: event.clientY });
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return mousePosition;
}

function hexToRgb(hex) {
    hex = hex.replace("#", "");

    if (hex.length === 3) {
        hex = hex
            .split("")
            .map((char) => char + char)
            .join("");
    }

    const hexInt = parseInt(hex, 16);
    const red = (hexInt >> 16) & 255;
    const green = (hexInt >> 8) & 255;
    const blue = hexInt & 255;
    return [red, green, blue];
}

const Particles = ({
    className = "",
    quantity = 1000,
    staticity = 150,
    ease = 50,
    size = 0.3,
    refresh = false,
    color = "#ffffff",
    colorDesertion = 50,
    vx = 0,
    vy = 0,
    destroy,
    animateBlow,
}) => {
    const canvasRef = useRef(null);
    const canvasContainerRef = useRef(null);
    const context = useRef(null);
    const circles = useRef([]);
    const mousePosition = MousePosition();
    const mouse = useRef({ x: 0, y: 0 });
    const canvasSize = useRef({ w: 0, h: 0 });
    const deviceOrientationRef = useRef({
        alpha: 0,
        beta: 0,
        gamma: 0,
        rotation: 0,
    });
    const initialOrientationRef = useRef(null);
    const dpr = typeof window !== "undefined" ? window.devicePixelRatio : 1;

    useEffect(() => {
        if (canvasRef.current) {
            context.current = canvasRef.current.getContext("2d");
        }
        initCanvas();
        animate();

        const handleDeviceOrientation = (event) => {
            let { alpha, beta, gamma } = event;
            alpha = (alpha > 180 ? alpha - 360 : alpha);
            beta = beta + 90;
            gamma = gamma + 90;

            // Визначаємо орієнтацію пристрою за допомогою window.orientation
            const orientation = window.orientation || 0; // 0, 90, -90, 180

            // Корекція значень в залежності від орієнтації
            if (orientation === 90) {
                // Якщо пристрій повернуто на 90 градусів (ліве обертання, альбомний режим)
                const temp = beta;
                beta = -gamma;
                gamma = temp;
            } else if (orientation === -90) {
                // Якщо пристрій повернуто на -90 градусів (праве обертання, альбомний режим)
                const temp = beta;
                beta = gamma;
                gamma = -temp;
            } else if (orientation === 180) {
                // Якщо пристрій повернуто на 180 градусів (перевернутий портретний режим)
                beta = -beta;
                gamma = -gamma;
            }

            if (!initialOrientationRef.current) {
                initialOrientationRef.current = { alpha, beta, gamma };
            }

            deviceOrientationRef.current = {
                alpha: alpha - initialOrientationRef.current.alpha,
                beta: beta - initialOrientationRef.current.beta,
                gamma: gamma - initialOrientationRef.current.gamma,
            };

        };
        window.addEventListener("resize", initCanvas);
        window.addEventListener("deviceorientation", handleDeviceOrientation);

        return () => {
            window.removeEventListener("resize", initCanvas);
            window.removeEventListener("deviceorientation", handleDeviceOrientation);
        };
    }, []);

    useEffect(() => {
        animate();
    }, [animateBlow]);


    useEffect(() => {
        onMouseMove();
    }, [mousePosition.x, mousePosition.y]);

    useEffect(() => {
        initCanvas();
    }, [refresh]);

    const initCanvas = () => {
        resizeCanvas();
        drawParticles();
    };

    const onMouseMove = () => {
        if (canvasRef.current) {
            const rect = canvasRef.current.getBoundingClientRect();
            const { w, h } = canvasSize.current;
            const x = mousePosition.x - rect.left - w / 2;
            const y = mousePosition.y - rect.top - h / 2;
            const inside = x < w / 2 && x > -w / 2 && y < h / 2 && y > -h / 2;
            if (inside) {
                mouse.current.x = x;
                mouse.current.y = y;
            }
        }
    };

    const resizeCanvas = () => {
        if (canvasContainerRef.current && canvasRef.current && context.current) {
            circles.current.length = 0;
            canvasSize.current.w = canvasContainerRef.current.offsetWidth;
            canvasSize.current.h = canvasContainerRef.current.offsetHeight;
            canvasRef.current.width = canvasSize.current.w * dpr;
            canvasRef.current.height = canvasSize.current.h * dpr;
            canvasRef.current.style.width = `${canvasSize.current.w}px`;
            canvasRef.current.style.height = `${canvasSize.current.h}px`;
            context.current.scale(dpr, dpr);
        }
    };

    const circleParams = () => {
        const x = Math.floor(Math.random() * canvasSize.current.w);
        const y = Math.floor(Math.random() * canvasSize.current.h);
        const translateX = 0;
        const translateY = 0;
        const pSize = Math.floor(Math.random() * 2) + size;
        const alpha = 0;
        const targetAlpha = parseFloat((Math.random() * 0.6 + 0.1).toFixed(1));
        const dx = (Math.random() - 0.5) * 0.1;
        const dy = (Math.random() - 0.5) * 0.1;
        const magnetism = 0.1 + Math.random() * 4;
        const genColor = randomRGBGenerator(hexToRgb(color), colorDesertion);
        const destroyX = 0;
        const destroyY = 0;
        return {
            x,
            y,
            translateX,
            translateY,
            size: pSize,
            alpha,
            targetAlpha,
            dx,
            dy,
            magnetism,
            genColor,
            destroyX,
            destroyY,
        };
    };


    const drawCircle = (circle, update = false) => {
        if (context.current) {
            const { x, y, translateX, translateY, size, alpha } = circle;
            context.current.translate(translateX, translateY);
            context.current.beginPath();
            context.current.arc(x, y, size, 0, 2 * Math.PI);

            context.current.fillStyle = `rgba(${circle.genColor.join(', ')}, ${alpha})`;
            context.current.fill();
            context.current.setTransform(dpr, 0, 0, dpr, 0, 0);

            if (!update) {
                circles.current.push(circle);
            }
        }
    };

    const clearContext = () => {
        if (context.current) {
            context.current.clearRect(0, 0, canvasSize.current.w, canvasSize.current.h);
        }
    };

    const drawParticles = () => {
        clearContext();
        const particleCount = quantity;
        for (let i = 0; i < particleCount; i++) {
            const circle = circleParams();
            drawCircle(circle);
        }
    };

    const remapValue = (value, start1, end1, start2, end2) => {
        const remapped = ((value - start1) * (end2 - start2)) / (end1 - start1) + start2;
        return remapped > 0 ? remapped : 0;
    };

    const animate = () => {
        clearContext();
        const centerX = canvasSize.current.w / 2;
        const centerY = canvasSize.current.h / 2;

        circles.current.forEach((circle, i) => {
            // Handle the alpha value
            const edge = [
                circle.x + circle.translateX - circle.size, // distance from left edge
                canvasSize.current.w - circle.x - circle.translateX - circle.size, // distance from right edge
                circle.y + circle.translateY - circle.size, // distance from top edge
                canvasSize.current.h - circle.y - circle.translateY - circle.size, // distance from bottom edge
            ];
            const closestEdge = edge.reduce((a, b) => Math.min(a, b));
            const remapClosestEdge = parseFloat(remapValue(closestEdge, 0, 20, 0, 1).toFixed(2));
            if (remapClosestEdge > 1) {
                circle.alpha += 0.02;
                if (circle.alpha > circle.targetAlpha) {
                    circle.alpha = circle.targetAlpha;
                }
            } else {
                circle.alpha = circle.targetAlpha * remapClosestEdge;
            }
            if (destroy) {
                const blowAcceleration = 0.1;
                const side = Math.floor(Math.random() * 4);  // 0 - верх, 1 - низ, 2 - ліво, 3 - право
                if (circle.destroyX === 0 || circle.destroyY === 0) {
                    switch (side) {
                        case 0: // Верхня частина
                            circle.destroyX = Math.random() * canvasSize.current.w;  // Рандомна позиція по осі X на ширині екрану
                            circle.destroyY = -Math.random() * 100 / blowAcceleration;         // Трохи вище верхньої межі екрану
                            break;
                        case 1: // Нижня частина
                            circle.destroyX = Math.random() * canvasSize.current.w;  // Рандомна позиція по осі X на ширині екрану
                            circle.destroyY = canvasSize.current.h + Math.random() * 100 / blowAcceleration;  // Трохи нижче нижньої межі екрану
                            break;
                        case 2: // Ліва частинаd
                            circle.destroyX = -Math.random() * 100 / blowAcceleration;         // Трохи лівіше лівої межі екрану
                            circle.destroyY = Math.random() * canvasSize.current.h; // Рандомна позиція по осі Y на висоті екрану
                            break;
                        case 3: // Права частина
                            circle.destroyX = canvasSize.current.w + Math.random() * 100 / blowAcceleration;  // Трохи правіше правої межі екрану
                            circle.destroyY = Math.random() * canvasSize.current.h;       // Рандомна позиція по осі Y на висоті екрану
                            break;
                        default:
                            circle.destroyX = -1;
                            circle.destroyY = -1;
                            break;
                    }
                }
                circle.x += (circle.destroyX - circle.x) * blowAcceleration; // Move towards the target X
                circle.y += (circle.destroyY - circle.y) * blowAcceleration; // Move towards the target Y
            } else if (animateBlow) {
                circle.x += (centerX - circle.x) * 0.05; // Move towards the target X
                circle.y += (centerY - circle.y) * 0.05; // Move towards the target Y
            } else {
                const weightAlpha = Math.cos(deviceOrientationRef.current.beta * (Math.PI / 180));
                const weightGamma = Math.sin(deviceOrientationRef.current.beta * (Math.PI / 180));

                const rotationAngle = deviceOrientationRef.current.alpha * -weightAlpha + deviceOrientationRef.current.gamma * weightGamma;

                const rotationX = circle.x - centerX;
                const rotationY = circle.y - centerY;

                const rotatedX = rotationX * Math.cos(rotationAngle * (Math.PI / -180)) - rotationY * Math.sin(rotationAngle * (Math.PI / -180));
                const rotatedY = rotationX * Math.sin(rotationAngle * (Math.PI / -180)) + rotationY * Math.cos(rotationAngle * (Math.PI / -180));

                const normalX = rotatedX + centerX;
                const normalY = rotatedY + centerY;

                circle.translateX = normalX - circle.x;
                circle.translateY = normalY - circle.y;

                circle.translateX += deviceOrientationRef.current.gamma * 0.2;
                circle.translateY += deviceOrientationRef.current.beta * 0.2;
                circle.x += circle.dx + vx;
                circle.y += circle.dy + vy;
                circle.translateX += (mouse.current.x / (staticity / circle.magnetism) - circle.translateX) / ease;
                circle.translateY += (mouse.current.y / (staticity / circle.magnetism) - circle.translateY) / ease;
            }
            drawCircle(circle, true);

            // circle gets out of the canvas
            if (
                circle.x < -circle.size ||
                circle.x > canvasSize.current.w + circle.size ||
                circle.y < -circle.size ||
                circle.y > canvasSize.current.h + circle.size
            ) {
                // remove the circle from the array
                circles.current.splice(i, 1);
                // create a new circle
                const newCircle = circleParams();
                drawCircle(newCircle);
                // update the circle position
            }

        });
        window.requestAnimationFrame(animate);
    };

    return (
        <div className={className} ref={canvasContainerRef} aria-hidden="true">
            <canvas ref={canvasRef} className="size-full" />
        </div>
    );
};

export default Particles;
